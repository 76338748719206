import { makeStyles } from '@material-ui/core/styles';
import {
  grayBG,
  red,
  textNoWrap,
} from '../../constants';

export default makeStyles({
  textNoWrap,
  infoToggleBtn: {
    minWidth: 'unset',
  },
  infoToggleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  infoToggleWrapperShowing: {
    backgroundColor: grayBG,
    alignItems: 'flex-start',
    position: 'absolute',
    inset: 0,
    zIndex: 1,
    '&::test': {
      content: '\'\'',
      position: 'absolute',
      inset: '17px 19px',
      border: `${red} 1px solid`,
      pointerEvents: 'none',
    },
  },
  description: {
    margin: '0',
    '& br': {
      display: 'none',
    },
  },
  descriptionShowing: {
    padding: '25px',
    position: 'fixed',
    top: '16%',
    paddingTop: '50px',
    left: '10%',
    right: '10%',
    bottom: '16%',
    backgroundColor: 'white',
    overflow: 'auto',
    animation: 'zoomInAnimation 0.5s',
    '& br': {
      display: 'initial',
    },
  },
  closeButtonOpen: {
    position: 'fixed',
    top: '16%',
    right: '10%',
    zIndex: 2000,
  },
  closeButton: {
  },
});
