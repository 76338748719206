import { makeStyles } from '@material-ui/core/styles';
import { orange, orangeHovered, backgroundColor } from '../../constants';

export default makeStyles(
  {
    backButton: {
      backgroundColor: orange,
      color: backgroundColor,

      '&:hover': {
        backgroundColor: orangeHovered,
      },
    },
  },
  { index: 1 },
);
