import React from 'react';
import Gallery from 'sharedComponents/Gallery/Gallery';
import { Box, Typography } from '@material-ui/core';
import CollapseBox from 'components/CollapseComponent';
import { getGiftsImageLink } from 'helpers';
import { Lang } from 'interfaces';
import { usePromotionAndGifts } from './usePromotionAndGifts';

import useStyles from '../styles';

export type Item = {
  header: string;
  text: string;
  photo: number;
};

type Props = {
  userLanguage: Lang;
};

const PromotionAndGiftsComponent: React.FC<Props> = ({
  userLanguage,
}) => {
  const classes = useStyles();
  const { data } = usePromotionAndGifts();

  // use iframe for the HTML content
  return (
    <iframe
      style={{ border: 'none' }}
      height="100%"
      width="100%"
      src={`/api/website/${userLanguage}/3802`}
    />
  );
};

export default PromotionAndGiftsComponent;
