import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from 'react-router';
import { toast } from 'react-toastify';
import PreorderPaymentModal, { TPreorderModal } from 'sharedComponents/PreorderPaymentModal';
import { useAuth } from 'services/auth';
import { useCreateProject } from 'services/customHooks';
import socket from 'socket';
import { sendAbuse } from 'services/commonService';
import {
  getCurrencyByCountry,
  getTranslateFunction,
  percentFromNumber,
  checkVendorComformCriteria,
} from 'helpers';
import {
  BonusPreorderType, IOnChange, OrderType, Preorder,
} from 'interfaces';
import Icons from 'components/Icons';
import { usePayments } from 'hooks/payment';
import { useLayout } from 'context/layout';
import CreateProjectsComponent from '../components/CreateProjectsComponent';
import { DEFAULT_COMMISSION, TYPES_WITH_POINTS } from '../../../constants';

const {
  bonusSvg,
} = Icons;

const CreateProjects: React.FC = () => {
  const t = getTranslateFunction();
  const param = useParams<{ [prop: string]: string }>();
  const location = useLocation();
  const history = useHistory();
  const { vendor, setIsLoading, user } = useAuth();

  if (!vendor) {
    return <Redirect to="/" />;
  }

  if (!user?.isUserVerified) {
    return <Redirect to="/unverified" />;
  }

  const currentType = param.id as OrderType;
  const query = location.search;
  const {
    preorders,
    distances,
    addPreorder,
  } = useCreateProject(query, currentType);
  const { balance, allBonuses, commissions } = usePayments();

  const getCurrentBonusCount = useCallback((currency) => {
    const filteredBonuses = allBonuses.filter((bonus) => {
      const isAbsolute = bonus.bonusInfluenceType === 'absolute';
      const isThisCurrency = bonus.bonusCurrency === currency;
      const types: BonusPreorderType[] = ['all', currentType];
      const isThisType = types.some((type: BonusPreorderType) => (
        bonus.bonusPreorderTypes.includes(type)
      ));

      return isThisType && isAbsolute && isThisCurrency;
    });

    const count = filteredBonuses.reduce((sum, value) => sum + value.bonusValue, 0);
    return count;
  }, [allBonuses, currentType]);

  const [canceledPreorders, setCanceledPreorders] = useState<number[]>([]);
  const [paymentModal, setPaymentModal] = useState<TPreorderModal | null>(null);
  const [together, setTogether] = useState(false);
  const { searchInput: search, setShouldShowSearchInput, setSearchInput } = useLayout();
  const currency = useMemo(() => getCurrencyByCountry(user?.country ?? ''), [user]);

  const onCloseInsufficientModal = () => setPaymentModal(null);

  const withTogether = useMemo(() => (currentType === 'alongTheWay'), [currentType]);
  const singleType = useMemo(() => {
    const types: string[] = ['helpOnRoad', 'buyThings', 'rent', 'services'];

    return types.some((type: string) => type === currentType);
  }, [currentType]);

  useEffect(() => {
    const services = ['rent', 'services'];

    const isSearchable = services.some((type) => type === currentType);

    setShouldShowSearchInput(isSearchable);

    return () => {
      setShouldShowSearchInput(false);
    };
  }, [currentType]);

  useEffect(() => () => {
    setSearchInput(null);
  }, []);

  const handleGoPayments = useCallback(() => {
    history.push('/payments');
  }, []);

  const handleSendAbuse = async (data: {
    preorderId: number;
    abuseReason: string;
  }) => {
    setIsLoading(true);
    try {
      const [response] = await sendAbuse(data);
      if (response) {
        toast.success(t('create-projects.complaint-sent'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const currCommision = useMemo(() => {
    const findedCommision = commissions.find((item) => (item.projectType === currentType));

    return findedCommision ? findedCommision.commissionValue : DEFAULT_COMMISSION;
  }, [commissions, currentType]);

  const handleOpenPreorder = (data: {
    to: string,
    state: [Preorder];
  }) => {
    const { to, state } = data;
    const [preorder] = state;
    const isPointType = TYPES_WITH_POINTS.includes(currentType);

    const currBalance = balance[preorder.currency] ?? 0;
    const preorderCommision = percentFromNumber(preorder.price, currCommision);
    const currBonus = getCurrentBonusCount(preorder.currency);
    const fundsExist = currBalance + currBonus;
    const isFundsInsufficient = preorderCommision > fundsExist;

    if (!isPointType || !isFundsInsufficient) {
      history.push(to, state);
    }

    setPaymentModal({
      type: 'insufficient',
      bodyText: `${preorderCommision - fundsExist} ${preorder.currency}`,
      price: currBonus,
      onClick: handleGoPayments,
    });
  };

  const handleChange = ({
    action,
    data,
  }: IOnChange) => {
    switch (action) {
      case 'openPreorder': {
        handleOpenPreorder(data);
        break;
      }
      case 'historyPush': {
        history.push(data);
        break;
      }
      case 'addedCanceledPreorder': {
        setCanceledPreorders((prev) => [...prev, data]);
        break;
      }
      case 'sendAbuse': {
        handleSendAbuse(data);
        break;
      }
      default: break;
    }
  };

  useEffect(() => {
    socket.emit('joinToRoom', 'vendors');
    socket.on('preorderCreated', (data: Preorder) => {
      const innerType = data.type === currentType;
      if (!innerType) return;

      const isNeedComfortVehicle = data.carClass === 'comfort';

      if (isNeedComfortVehicle) {
        const isApproved = checkVendorComformCriteria(vendor);

        if (!isApproved) return;
      }

      setCanceledPreorders((prev) => prev.filter((id) => id !== data.preorderId));
      addPreorder(data);
    });
    socket.on('preorderRemoved', ([preorder]: Preorder[]) => {
      const innerType = preorder.type === currentType;
      if (!innerType) return;

      setCanceledPreorders((prev) => (
        prev.includes(preorder.preorderId) ? prev : [...prev, preorder.preorderId]
      ));
    });

    return () => {
      socket.off('preorderRemoved');
      socket.off('preorderCreated');
      socket.off('connect');
      socket.off('vendors');
    };
  }, [addPreorder, vendor]);

  return (
    <>
      <CreateProjectsComponent
        preorders={preorders}
        singleType={singleType}
        withTogether={withTogether}
        currentType={currentType}
        together={together}
        setTogether={setTogether}
        distances={distances}
        onChange={handleChange}
        canceledPreorders={canceledPreorders}
        searchInputValue={search}
      />

      {paymentModal && (
        <PreorderPaymentModal
          onClose={onCloseInsufficientModal}
          icon={bonusSvg}
          value={paymentModal}
          balance={balance[currency]}
          currency={currency}
        />
      )}
    </>
  );
};

export default CreateProjects;
