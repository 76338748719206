import { makeStyles } from '@material-ui/core/styles';
import {
  backgroundColor,
  grayBG,
  green,
  red,
} from '../../constants';

export default makeStyles({
  optionsBtn: {
    width: '45%',
    marginBottom: '10px',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'relative',
    backgroundColor,
  },
  optionsBtnChecked: {},
  optionsBtnCheckedIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    border: `1px ${red} solid`,
    borderRadius: '50%',
    fill: red,
    width: '20px',
    height: 'auto',
  },
  transparent: {
    opacity: '50%',
  },
  contentIcon: {
  },
  pageTitle: {
    backgroundColor: grayBG,
    margin: '15px 0',
    padding: '15px',
  },
  text: {
    textAlign: 'center',
    fontSize: '12px',
    backgroundColor: grayBG,
    marginTop: '10px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: '0 10px',
  },
  mainBtnContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px',
  },
  mainBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0',
    padding: '10px',
    fontWeight: 'bold',
    backgroundColor: grayBG,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  optionsBox: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    maxHeight: 0,
    transition: 'max-height .5s ease-out',
    overflow: 'auto',
    '&.open': {
      maxHeight: 'none',
      transition: 'max-height .5s ease-in',
    },
  },
  arrow: {
    color: green,
    lineHeight: '0',
    transition: '.5s',
    '&.open': {
      transform: 'rotateX(180deg)',
    },
  },
  svgIcon: {
    width: '30px',
    height: 'auto',
    marginRight: '20px',
  },
  dropdownBox: {
    marginTop: '15px',
    marginBottom: '50px',
  },
  confirmBtn: {
    width: '200px',
    margin: '0 auto 70px',
    textAlign: 'center',
    border: `2px solid ${green}`,
    color: green,
    borderRadius: '21px',
    padding: ' 10px 20px',
  },
}, { index: 1 });
