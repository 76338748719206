import { makeStyles } from '@material-ui/core/styles';
import {
  grayBG,
} from '../../constants';

export default makeStyles((theme) => ({
  titleName: {
    margin: '0 20px',
  },
  optionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 10px',
    justifyContent: 'space-between',
    height: '100%',
    alignContent: 'space-between',
    minHeight: '450px',
  },
  optionLinkContainer: {
    padding: '5px',
    boxSizing: 'border-box',
    width: '50%',
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '120px',
    flex: '1 0 auto',
    marginBottom: '10px',
    '@media (min-height: 600px)': {
      height: 'calc(33.33% - 10px)',
    },
    [theme.breakpoints.up('sm')]: {
      width: '33.33%',
      minHeight: '150px',
      padding: '8px',
    },
    [theme.breakpoints.up('md')]: {
      width: '25%',
      minHeight: '180px',
      padding: '10px',
    },

    [theme.breakpoints.up('lg')]: {
      width: '16.66%',
      minHeight: '200px',
      padding: '12px',
    },
  },
  optionLink: {
    backgroundColor: grayBG,
    textAlign: 'center',
    padding: '5px 10px',
    fontWeight: 'bold',
    textDecoration: 'none',
    marginTop: '10px',
    height: 'auto',
    minHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1rem',
      minHeight: '40px',
      padding: '8px 15px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.2rem',
      minHeight: '45px',
      padding: '10px 15px',
    },
  },
}), { index: 1 });
