import { useEffect } from 'react';
import { Container, Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { ModelItem } from 'components/ConstructorForm/items/CarCombobox/useBrands';
import { getTranslateFunction } from 'helpers';
import { IOnChange, VendorToCreate } from 'interfaces';
import { useLayout } from 'context/layout';
import { checkVendorAvailability } from 'services/commonService';
import Step3 from '../SignUpPage/Step3';
import useStyles from './CreateVendorStyle';

interface Props {
  onChange: (data: IOnChange) => void;
  brands: string[];
  models: ModelItem[];
}

const CreateVendorPage = ({ onChange, brands, models }: Props) => {
  const t = getTranslateFunction();
  const { control, setValue, errors, handleSubmit, clearErrors, setError } = useForm();

  const classes = useStyles();
  const { setTitle } = useLayout();

  const onSubmit = async (data: VendorToCreate) => {
    const vehicleNumberCheck = await checkVendorAvailability('vehicleNumber', data.vehicleNumber!);
    if (!vehicleNumberCheck.result) {
      setError('vehicleNumber', {
        type: 'manual',
        message: t('error.validation.vehicleNumberUnavailable'),
      });
      return;
    }

    onChange({
      action: 'createVendor',
      data: {
        ...data,
        createVehicle: true,
      },
    });
  };

  useEffect(() => {
    setTitle(t('createVendorPage.pageName'));

    return () => {
      setTitle('');
    };
  }, []);

  return (
    <Container className={classes.mainContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Step3
          brands={brands}
          models={models}
          onChangeProp={onChange}
          setValue={setValue}
          control={control}
          errors={errors}
          clearErrors={clearErrors}
        />

        <Button type="submit" className={classes.submitBtn} variant="contained" color="primary">
          {t('createVendorPage.button.submitLabel')}
        </Button>
      </form>
    </Container>
  );
};

export default CreateVendorPage;
