import { useCallback, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import CollapseBox from 'components/CollapseComponent';
import PasswordsBlock from 'pages/SignUpPage/components/PasswordsBlock';
import { useForm } from 'react-hook-form';
import ConfirmCode from 'components/ConfirmCode';
import Modal from 'sharedComponents/Modal';
import { getTranslateFunction } from 'helpers';
import { useAuth } from 'services/auth';
import i18n from 'i18n';
import { useModal, useRequests } from 'services/customHooks';
import { updateUserPassword } from 'services/commonService';
import useStyles from '../SettingsPageStyle';
import { useTimestamp } from '../hooks/useTimestamp';

const defaultValues = {
  password: '',
  passwordConfirm: '',
};

const ResetPassword = () => {
  const t = getTranslateFunction();
  const classes = useStyles();

  const [currentStage, setCurrentStage] = useState<'init' | 'confirm' | 'update'>('init');
  const [codeCreationTime, setCodeCreationTime] = useState(0);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const { errors, handleSubmit, register, getValues, clearErrors } = useForm({
    defaultValues,
    shouldFocusError: false,
  });

  const { sendRequest, sendCode, attempts, isAttemptsForCodeLeft } = useRequests();
  const { isModalVisible, showModal, hideModal } = useModal();
  const { user } = useAuth();
  const passwordToUpdate = `update-user-password-${user?.id}`;
  const generateType = 'password';
  const { timeRemaining, isButtonDisabled, updateTimestamp } = useTimestamp({
    userId: user?.id,
    type: generateType,
  });

  const initPasswordUpdate = async () => {
    try {
      setCurrentStage('confirm');
      setFormErrorMessage('');
      const data = { email: user?.email, lang: i18n.language, userId: user?.id, generateType, passwordToUpdate };
      await sendRequest('/users/create-profile-validation', data);
      setCodeCreationTime(new Date().getTime());
    } catch (error) {
      const e = error as Error;
      setFormErrorMessage(e.message);
    }
  };

  const updatePassword = useCallback((data: { password: string; passwordConfirm: string }) => {
    updateUserPassword({ id: user?.id, password: data.password });
    showModal();
    setCurrentStage('init');
    updateTimestamp();
  }, []);

  const confirmVerificationCode = async (emailCode: string) => {
    const data = {
      email: user?.email,
      emailCode: Number(emailCode),
      generateType,
      passwordToUpdate,
    };

    const res = await sendCode('/users/check-profile-validation', data);

    if (!res) {
      return;
    }

    setCurrentStage('update');
  };

  const resetToInitialStage = () => {
    setCurrentStage('init');
  };

  return (
    <CollapseBox title={t('settings.changePassword.title')} withoutMargins>
      {currentStage === 'init' && (
        <Box className={classes.updateProfileSubmitButtonWrapper}>
          <Button
            className={classes.updateProfileSubmitButton}
            onClick={initPasswordUpdate}
            disabled={isButtonDisabled}
          >
            {t('settings.profile.update')}
          </Button>

          {timeRemaining && (
            <Typography>
              {t('settings.nextChangeAllowed')}: {timeRemaining}
            </Typography>
          )}
        </Box>
      )}

      {currentStage === 'confirm' && (
        <>
          <ConfirmCode
            createdCodeTime={codeCreationTime}
            isAttemptsForCodeLeft={isAttemptsForCodeLeft}
            onSendCode={confirmVerificationCode}
            resendRequest={initPasswordUpdate}
            attempts={attempts}
            errorMessage={formErrorMessage}
          />

          <Button className={classes.updateProfileCancelButton} onClick={resetToInitialStage}>
            {t('settings.profile.cancel')}
          </Button>
        </>
      )}

      {currentStage === 'update' && (
        <form onSubmit={handleSubmit(updatePassword)}>
          <PasswordsBlock getValues={getValues} errors={errors} register={register} clearErrors={clearErrors} />

          <Box className={classes.btnContainer}>
            <Button className={classes.updateProfileCancelButton} onClick={resetToInitialStage}>
              {t('settings.profile.cancel')}
            </Button>

            <Button className={classes.submitButton} type="submit">
              {t('settings.resetPassword.submit')}
            </Button>
          </Box>
        </form>
      )}

      <Modal withoutBorder isOpen={isModalVisible} onClose={hideModal}>
        <div className={classes.updateProfileModal}>{t('settings.updateProfile.ok')}</div>
      </Modal>
    </CollapseBox>
  );
};

export default ResetPassword;
