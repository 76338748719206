import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import classNames from 'classnames';
import VerificationInputStatus from 'pages/ProfilePage/components/VerificationInputStatus';
import { ReactComponent as DownloadSvg } from 'images/icons/download.svg';
import useStyles from './CustomFileInputStyle';

interface Props {
  multiple?: boolean;
  preview?: string;
  text?: string;
  onChange?: (val: FileList | null) => void;
  id?: string;
  children?: React.ReactNode;
  value?: FileList;
  disabled?: boolean;
  withVerificationStatus?: boolean;
  isVerified?: boolean;
}

const ACCEPTABLE_EXTENSIONS = ['jpeg', 'png', 'jpg'];
const INPUT_FILE_ACCEPTABLE_EXTENSIONS = ACCEPTABLE_EXTENSIONS.map((ext) => `.${ext}`).join(',');

const CustomFileInput: React.FC<Props> = ({
  multiple = false,
  preview = '',
  text,
  onChange,
  id,
  children,
  value,
  disabled = false,
  withVerificationStatus = false,
  isVerified = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [previewImg, setPreviewImg] = useState<string>(preview);
  const classes = useStyles(!!id);
  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      setPreviewImg(preview);
    }

    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      setPreviewImg(event.target?.result as string);
    };

    if (e.target.files && e.target.files.length) {
      reader.readAsDataURL(e.target.files[0]);
    }

    onChange?.(e.target.files);
  };

  const onValueChange = () => {
    if (!value?.length) return;

    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      setPreviewImg(event.target?.result as string);
    };
    reader.readAsDataURL(value[0]);
  };

  useEffect(() => {
    onValueChange();
  }, [value]);

  return (
    <Box className={classes.inputContainer} onClick={handleClick}>
      <input
        accept={INPUT_FILE_ACCEPTABLE_EXTENSIONS}
        ref={inputRef}
        type="file"
        hidden
        onChange={handleFileChange}
        multiple={multiple}
        disabled={disabled}
      />

      <Box>{previewImg && <img className={classes.img} src={previewImg} alt="Preview" />}</Box>

      <Box className={classes.btns}>
        {text && <Typography className={classes.text}>{text}</Typography>}

        {withVerificationStatus ? (
          <VerificationInputStatus isVerified={isVerified}>
            <div
              className={classNames(classes.fileInputIconWrapper, {
                [classes.hideIcon]: disabled,
              })}
            >
              {children}
              {!disabled && <DownloadSvg className={classes.fileInputSvg} />}
            </div>
          </VerificationInputStatus>
        ) : (
          <div>
            {children}
            {!disabled && <DownloadSvg className={classes.fileInputSvg} />}
          </div>
        )}
      </Box>
    </Box>
  );
};

export default CustomFileInput;

CustomFileInput.defaultProps = {
  multiple: false,
  preview: '',
  text: '',
  onChange: () => {},
  id: undefined,
  children: undefined,
  value: undefined,
};
