import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import HomePageComponent from './components/HomePageComponent';
import { useHomePage } from './hooks';

const HomePage: React.FC = () => {
  const history = useHistory();
  const {
    incomeSumm,
    pendingTransactions,
    rating,
    user,
    ordersNumber,
    balance,
    isVendor,
    bonuses,
    unreadChats,
  } = useHomePage();

  const onClick = useCallback(() => {
    const route = isVendor ? '/vendor-home' : '/projects';

    history.push(route);
  }, [isVendor]);

  return (
    <HomePageComponent
      incomeSumm={incomeSumm}
      pendingTransactions={pendingTransactions}
      rating={rating}
      user={user}
      ordersNumber={ordersNumber}
      unreadChats={unreadChats}
      balance={balance}
      isVendor={isVendor}
      bonuses={bonuses}
      onClick={onClick}
    />
  );
};

export default HomePage;
