import Button from 'sharedComponents/Button';
import { toast } from 'react-toastify';
import { getTranslateFunction } from 'helpers';
import useStyles from '../styles';

type Props = {
  locale: string;
  referralId: string;
};

const getReferralMessage = (locale: string, referralId: string): string => {
  const messages: { [key: string]: string } = {
    rus: `Проходи регистрацию, 
  в строке "Кто вас пригласил"  
  укажи мой ID: ${referralId}`,
    ukr: `Проходь реєстрацію,
  у рядку "Хто вас запросив"
  вкажи мій ID: ${referralId}`,
    aze: `Qeydiyyatdan keç,
  "Sizi kim dəvət edib?" sətirində
  ID-mi yaz: ${referralId}`,
    eng: `When registering in the 
  "Who invited you" section 
  indicate my ID: ${referralId}`,
    ita: `Al momento della registrazione 
  nella sezione "Chi ti ha invitato" 
  indica il mio ID: ${referralId}`,
    deu: `Gehen Sie die durch Registrierung,
  in der Zeile „Wer hat Sie eingeladen?“
  Geben Sie meinen ID: ${referralId}`,
    fre: `Lors de l'inscription
  dans la ligne "Qui vous a invité"
  indiquer ma ID: ${referralId}`,
    tur: `Kayıt işlemini gerçekleştir, 
  "Sizi kim davet etti?" satırında 
  ID-mi yaz: ${referralId}`,
  };

  return messages[locale] || messages.eng;
};

const ShareButton = ({ locale, referralId }: Props) => {
  const t = getTranslateFunction();
  const classes = useStyles();

  const message = getReferralMessage(locale, referralId);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'PPWW',
          text: message,
          url: 'https://app.ppww.eu/',
        });
      } catch (err) {
        toast.error(t('share.error'));
      }
    } else {
      // postMessageToParent('share', { message });
      // window.open(`https://wa.me/?text=${encodeURIComponent(message)}`);
      window.postMessage('share');
      // toast.error(t('share.is.not.supported'));
    }
  };

  return (
    <Button onClick={handleShare} color="primary" variant="contained" className={classes.shareBtn}>
      {t('sharePage.shareBtn')}
    </Button>
  );
};

export default ShareButton;
