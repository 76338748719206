import { useForm } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { IUser, UserFormData } from 'interfaces';
import { getTranslateFunction } from 'helpers';
import ContactInputs from './ContactInputs';
import NameInputs from './NameInputs';
import PersonalInfoInputs from './PersonalInfoInputs';
import DocumentInputs from './DocumentInputs';
import { GetIsVerifiedInput, HandleUpdateUserFile } from '../containers/UserInfo';
import useStyles from '../ProfilePageStyle';

interface Props {
  defaultValues: IUser;
  onSubmit: (data: UserFormData) => void;
  passportFirstPagePhotoSrc: string;
  passportAndFacePhotoSrc: string;
  handleUpdateUserFile: HandleUpdateUserFile;
  getIsVerifiedInput: GetIsVerifiedInput;
  isUserVerified: boolean;
}

const ProfileForm = ({
  defaultValues,
  onSubmit,
  passportFirstPagePhotoSrc,
  passportAndFacePhotoSrc,
  handleUpdateUserFile,
  getIsVerifiedInput,
  isUserVerified,
}: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  const {
    handleSubmit, control, errors, clearErrors, register, setValue, formState: { isValid, isDirty },
  } = useForm({ defaultValues, shouldFocusError: false, mode: 'onChange' });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.profileForm} autoComplete='off'>
      <ContactInputs
        control={control}
        errors={errors}
        clearErrors={clearErrors}
        getIsVerifiedInput={getIsVerifiedInput}
      />

      <NameInputs
        register={register}
        errors={errors}
        clearErrors={clearErrors}
        getIsVerifiedInput={getIsVerifiedInput}
      />

      <PersonalInfoInputs
        control={control}
        register={register}
        setValue={setValue}
        errors={errors}
        clearErrors={clearErrors}
        defaultValues={defaultValues}
        getIsVerifiedInput={getIsVerifiedInput}
      />

      <DocumentInputs
        getIsVerifiedInput={getIsVerifiedInput}
        passportFirstPagePhotoSrc={passportFirstPagePhotoSrc}
        passportAndFacePhotoSrc={passportAndFacePhotoSrc}
        handleUpdateUserFile={handleUpdateUserFile}
      />

      {isUserVerified ? (
        <Button
          component={Link}
          to={'/chat/support'}
          color='primary'
          variant='contained'>
          {t('profilePage.submitForm')}
        </Button>
      ) : (
          <Button
            type='submit'
            color='primary'
            variant='contained'
            disabled={!isValid || !isDirty}>
          {t('profilePage.submitForm')}
        </Button>
      )}
    </form>
  );
};

export default ProfileForm;
