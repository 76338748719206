import archiveTsx from 'images/archive';
import securityPayTsx from 'images/securityPay';
import PhoneTsx from 'images/phone';
import InfoTsx from 'images/info';
import ChatTsx from 'images/chat';
import CommentTsx from 'images/comment';
import StarTsx from 'images/star';
import IncomeTsx from 'images/income';
import topUpWalletTsx from 'images/topUpWallet';
import sendFundsTsx from 'images/sendFunds';
import withdrawFundsTsx from 'images/withdrawFunds';
import inCityTsx from 'images/inCity';
import betweenCityTsx from 'images/betweenCity';
import internationalTsx from 'images/international';
import ExitTsx from 'images/exit';
import LockTsx from 'images/lock';
import DeleteTsx from 'images/delete';
import ComplaintTsx from 'images/complaint';
import MessageTsx from 'images/message';
import individualSvg from 'images/icons/user.svg';
import togetherSvg from 'images/icons/groupPeople.svg';
import economySvg from 'images/icons/economy.svg';
import comfortSvg from 'images/icons/carRight.svg';
import motorcycleSvg from 'images/icons/sportBike.svg';
import microBusSvg from 'images/icons/bus.svg';
import fromSvg from 'images/icons/from.svg';
import toSvg from 'images/icons/to.svg';
import toTimeSvg from 'images/icons/dateAndTime.svg';
import toCardSvg from 'images/icons/card.svg';
import cashSvg from 'images/icons/cash.svg';
import internalTransferSvg from 'images/icons/internalTransfer.svg';
import securityPaymentSvg from 'images/icons/securityPayment.svg';
import onFootSvg from 'images/icons/onFoot.svg';
import makePriceSvg from 'images/icons/makePrice.svg';
import searchSvg from 'images/icons/search.svg';
import baggageSvg from 'images/icons/baggage.svg';
import nowSvg from 'images/icons/time.svg';
import balanceSvg from 'images/icons/balance.svg';
import bonusSvg from 'images/icons/bonus.svg';
import bonusPlusSvg from 'images/icons/bonusPlus.svg';
import fundsInProcessingSvg from 'images/icons/fundsInProcessing.svg';
import walletSvg from 'images/icons/wallet.svg';
import comisionSvg from 'images/icons/comision.svg';
import hasBabyChairSvg from 'images/icons/babyChair.svg';
import hashtagSvg from 'images/icons/hashtag.svg';
import numberSvg from 'images/icons/number.svg';
import downloadSvg from 'images/icons/download.svg';
import fileUpload from 'images/icons/fileUpload.svg';

import inCity from 'images/icons/inCity.svg';
import betweenCity from 'images/icons/betweenCity.svg';
import international from 'images/icons/international.svg';

import alongTheWay from 'images/icons/alongTheWay/alongTheWay.svg';
import animalTransport from 'images/icons/alongTheWay/animalTransport.svg';
import anotherTransport from 'images/icons/alongTheWay/anotherTransport.svg';
import freightTransport from 'images/icons/alongTheWay/freightTransport.svg';
import temporaryDriver from 'images/icons/alongTheWay/temporaryDriver.svg';
import womenDriver from 'images/icons/alongTheWay/womenDriver.svg';

import flowersDelivery from 'images/icons/courier/flowersDelivery.svg';
import foodDelivery from 'images/icons/courier/foodDelivery.svg';
import medicinesDelivery from 'images/icons/courier/medicinesDelivery.svg';
import anotherDelivery from 'images/icons/courier/anotherDelivery.svg';
import productsDelivery from 'images/icons/courier/productsDelivery.svg';
import documentsDelivery from 'images/icons/courier/documentsDelivery.svg';

import anotherHelp from 'images/icons/helpOnRoad/another.svg';
import engineStartHelp from 'images/icons/helpOnRoad/engineStart.svg';
import fuelDeliveryHelp from 'images/icons/helpOnRoad/fuelDelivery.svg';
import onSiteRepair from 'images/icons/helpOnRoad/onSiteRepair.svg';
import towingHelp from 'images/icons/helpOnRoad/towing.svg';
import towTruckHelp from 'images/icons/helpOnRoad/towTrack.svg';

import anotherBuy from 'images/icons/buy/another.svg';
import appliancesBuy from 'images/icons/buy/appliances.svg';
import clothingBuy from 'images/icons/buy/clothing.svg';
import furnitureBuy from 'images/icons/buy/furniture.svg';
import medicinesBuy from 'images/icons/buy/medicines.svg';
import propertyBuy from 'images/icons/buy/property.svg';
import transportBuy from 'images/icons/buy/transport.svg';
import sportGoodsBuy from 'images/icons/buy/sportGoods.svg';
import sparePartsBuy from 'images/icons/buy/spareParts.svg';
import electricalEngineering from 'images/icons/buy/electricalEngineering.svg';

import carRental from 'images/icons/rental/car.svg';
import clothingRental from 'images/icons/rental/clothing.svg';
import otherRental from 'images/icons/rental/others.svg';
import propertyRental from 'images/icons/rental/property.svg';
import sportGoodsRental from 'images/icons/rental/sportGoods.svg';
import electricalEngineeringRent from 'images/icons/rental/electricalEngineering.svg';

import appliancesService from 'images/icons/services/appliances.svg';
import carRepairService from 'images/icons/services/carRepair.svg';
import cleaningService from 'images/icons/services/cleaningService.svg';
import constructionAndRepairService from 'images/icons/services/constructionAndRepair.svg';
import electricianService from 'images/icons/services/electrician.svg';
import handymenService from 'images/icons/services/handymen.svg';
import laptopAndComputersService from 'images/icons/services/laptopAndComputers.svg';
import medicinesService from 'images/icons/services/medicines.svg';
import otherService from 'images/icons/services/other.svg';
import phonesAndTablesService from 'images/icons/services/phonesAndTables.svg';
import photoVideoTechnicsService from 'images/icons/services/photoVideoTechnics.svg';
import plumberService from 'images/icons/services/plumber.svg';
import seamstressService from 'images/icons/services/seamstress.svg';
import webServicesService from 'images/icons/services/webServices.svg';
import woodWorkerService from 'images/icons/services/woodWorker.svg';
import dateBirthdaySvg from 'images/icons/dateBirthday.svg';
import markerSvg from 'images/icons/marker.svg';
import documentSvg from 'images/icons/document.svg';
import profileSvg from 'images/icons/photoProfile.svg';
import photoWithDocsSvg from 'images/icons/photoWithDocs.svg';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const icons: any = {
  individualSvg,
  togetherSvg,
  economySvg,
  comfortSvg,
  motorcycleSvg,
  microBusSvg,
  fromSvg,
  toSvg,
  toTimeSvg,
  toCardSvg,
  cashSvg,
  internalTransferSvg,
  securityPaymentSvg,
  makePriceSvg,
  baggageSvg,
  nowSvg,
  onFootSvg,
  withCarSvg: economySvg,
  hasBabyChairSvg,
  searchSvg,
  comisionSvg,
  balanceSvg,
  downloadSvg,
  bonusSvg,
  bonusPlusSvg,
  fundsInProcessingSvg,
  walletSvg,
  archiveTsx,
  securityPayTsx,
  PhoneTsx,
  StarTsx,
  CommentTsx,
  topUpWalletTsx,
  IncomeTsx,
  sendFundsTsx,
  withdrawFundsTsx,
  inCityTsx,
  betweenCityTsx,
  internationalTsx,
  ExitTsx,
  MessageTsx,
  ChatTsx,
  alongTheWay,
  animalTransport,
  anotherTransport,
  freightTransport,
  temporaryDriver,
  womenDriver,
  flowersDelivery,
  foodDelivery,
  medicinesDelivery,
  anotherDelivery,
  productsDelivery,
  documentsDelivery,
  propertyBuy,
  transportBuy,
  fileUpload,
  inCity,
  betweenCity,
  international,
  appliancesService,
  carRepairService,
  cleaningService,
  constructionAndRepairService,
  electricianService,
  handymenService,
  laptopAndComputersService,
  medicinesService,
  otherService,
  phonesAndTablesService,
  photoVideoTechnicsService,
  plumberService,
  seamstressService,
  webServicesService,
  woodWorkerService,
  carRental,
  clothingRental,
  otherRental,
  propertyRental,
  sportGoodsRental,
  electricalEngineeringRent,
  anotherHelp,
  engineStartHelp,
  fuelDeliveryHelp,
  onSiteRepair,
  towingHelp,
  towTruckHelp,
  anotherBuy,
  appliancesBuy,
  clothingBuy,
  furnitureBuy,
  medicinesBuy,
  sportGoodsBuy,
  electricalEngineering,
  sparePartsBuy,
  dateBirthdaySvg,
  markerSvg,
  documentSvg,
  profileSvg,
  photoWithDocsSvg,
  LockTsx,
  DeleteTsx,
  ComplaintTsx,
  hashtagSvg,
  numberSvg,
  InfoTsx,
};

export default icons;
