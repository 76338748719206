import {
  ConfiguredFile,
  configureFilesObject,
  confugureFilesBeforeSend,
  FileObject,
  hashFiles,
} from 'helpers';
import { hyphensToCamelCase } from 'utils/formatters';
import {
  VendorFileProp, FileResponse, HashedFile, VendorToCreate, VendorVehicleProp,
  type Vendor,
  type DefaultVendorToCreate,
} from 'interfaces';
import { createVendor, sendFile } from 'services/commonService';
import { buttons } from 'utils/optionsConfig';

export type PhotosArray = FileObject[];

export const vendorFileProps: VendorFileProp[] = [
  'driverLicenseFront',
  'driverLicenseBack',
  'technicalPassportFront',
  'technicalPassportBack',
  'vehiclePhotoFront',
  'vehiclePhotoSideLeft',
];

const vendorVehicleProps: VendorVehicleProp[] = [
  'vehicleYear',
  'vehicleBrand',
  'vehicleColor',
  'vehicleDoorsCount',
  'vehicleModel',
  'vehicleSeatsCount',
];

function isVendorToCreate(vendor: VendorToCreate | DefaultVendorToCreate): vendor is VendorToCreate {
  return (vendor as VendorToCreate).vehicleYear !== undefined;
}

export const toDTOVendor = async (
  vendor: VendorToCreate | DefaultVendorToCreate,
  photos: PhotosArray,
  hashesOfFiles: HashedFile[],
): Promise <VendorToCreate | DefaultVendorToCreate> => {
  const configuredFiles = hashesOfFiles.reduce((acc, fileHash: HashedFile, index: number) => {
    const { field } = photos[index];
    return {
      ...acc,
      [field]: { ...fileHash, permissions: 'private' },
    };
  }, {});

  const formattedVendorVehicleProps = isVendorToCreate(vendor)
    ? vendorVehicleProps.reduce((acc, prop: VendorVehicleProp) => ({
      ...acc,
      [prop]: vendor[prop]?.id.toString(),
    }), {})
    : null;

  const result = {
    ...vendor,
    ...configuredFiles,
    ...formattedVendorVehicleProps,
  };

  return result;
};

export const configureVendor = async (data: VendorToCreate | DefaultVendorToCreate): Promise<{
  photos: FileObject[];
  hashesOfFiles: HashedFile[];
  formattedVendor: VendorToCreate | DefaultVendorToCreate;
}> => {
  const photos = await configureFilesObject(vendorFileProps, data);

  const hashesOfFiles = await hashFiles(photos);
  const formattedVendor = await toDTOVendor(data, photos, hashesOfFiles);

  return {
    photos,
    hashesOfFiles,
    formattedVendor,
  };
};

interface SubmitterResponse {
  results: boolean;
  vendor: Vendor;
}

export const submitter = async (data: VendorToCreate | DefaultVendorToCreate): Promise<SubmitterResponse> => {
  const {
    photos,
    formattedVendor,
    hashesOfFiles,
  } = await configureVendor(data);

  const createdVendor = await createVendor(formattedVendor);

  const formattedFiles = confugureFilesBeforeSend(photos, hashesOfFiles, createdVendor);

  const promises = formattedFiles.map(({ data: formData, id }: ConfiguredFile) => (
    sendFile(formData, 'vendors', id)
  ));
  const results = await Promise.all(promises) as FileResponse[];

  return { results: results.every(({ result }) => result), vendor: createdVendor };
};
