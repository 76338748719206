import { makeStyles } from '@material-ui/core';
import {
  backgroundColor,
  grayBG,
  green,
  red,
} from '../../constants';

export default makeStyles({
  page: {
    padding: '15px',
  },
  addVendorBtn: {
    width: '100%',
    marginBottom: 10,
  },
  controlVendorBtn: {
    width: '50%',
  },
  pageTitle: {
    backgroundColor: grayBG,
    margin: '15px 0',
    padding: '15px',
  },
  listItem: {
    position: 'relative',
    width: '100%',
    height: '70px',
    backgroundColor: grayBG,
    margin: '10px 0 0',
    padding: '10px',
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '20px',
  },
  subtitle: {
    fontSize: '12px',
  },
  activate: {
    color: backgroundColor,
    backgroundColor: green,
    border: `2px ${green} solid`,
    '&.Mui-disabled': {
      backgroundColor: 'rgb(217, 239, 220)',
      borderColor: 'rgb(217, 239, 220)',
    },
  },
  deactivate: {
    border: `2px ${red} solid`,
    color: backgroundColor,
    backgroundColor: red,
    '&.Mui-disabled': {
      backgroundColor: 'rgb(255, 223, 224)',
      borderColor: 'rgb(255, 223, 224)',
    },
  },
  vendorStatus: {
    position: 'absolute',
    width: 15,
    height: 15,
    top: 5,
    right: 15,
  },
  activeVendor: {
    backgroundColor: green,
  },
  inactiveVendor: {
    backgroundColor: red,
  },
  listItemContainer: {
    position: 'relative',
  },
  pendingOverlay: {
    position: 'absolute',
    inset: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  pendingText: {
    fontWeight: 'bold',
  },
  successfulText: {
    color: green,
    textAlign: 'center',
    marginTop: 10,
  },
  failedText: {
    color: red,
    textAlign: 'center',
    marginTop: 10,
  },
}, { index: 1 });
