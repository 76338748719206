import React, { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Container } from '@material-ui/core';
import classNames from 'classnames';
import { ModelItem } from 'components/ConstructorForm/items/CarCombobox/useBrands';
import {
  IOnChangeHandler, VendorToCreate, SaveDataHandler, VendorFormDataSignUpDefaultValues,
} from 'interfaces';
import FooterButton from '../components/FooterButton';
import Step3 from '../Step3';
import Header from '../components/Header';
import { indexStyles } from '../material';

export interface VendorFormStepProps {
  brands: string[];
  models: ModelItem[];
  onChange: IOnChangeHandler;
  defaultValues: VendorFormDataSignUpDefaultValues;
  onSaveData: SaveDataHandler;
  isFirstRender: boolean;
  filesDescription: string;
}

const VendorFormStep: React.FC<VendorFormStepProps> = ({
  brands,
  models,
  onChange,
  defaultValues,
  onSaveData,
  isFirstRender,
  filesDescription,
}) => {
  const classes = indexStyles();

  const {
    setValue,
    handleSubmit,
    clearErrors,
    control,
    trigger,
    formState,
    errors,
  } = useForm({ defaultValues, shouldFocusError: false });

  const formValues = useWatch({ control });

  useEffect(() => {
    if (!isFirstRender) return;

    const handleSubmitForm = async (): Promise<void> => {
      await trigger();
      const isHaveErrors = Boolean(Object.keys(formState.errors).length);
      if (isHaveErrors) {
        clearErrors();
      } else {
        onChange({ action: 'submitStep', data: formValues });
      }
    };

    handleSubmitForm();
  }, [isFirstRender]);

  useEffect(() => {
    onSaveData('vendor', formValues);
  }, [formValues]);

  const onCancel = () => {
    onChange({ action: 'stepBack' });
  };

  const onSubmit = (data: VendorToCreate) => {
    onChange({ action: 'submitStep', data });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.signupStepContainer} autoComplete='off'>
      <Header />

      <div className={classNames(classes.formContent, classes.clientInfoFormContent)}>
        <Container>
          <Step3
            onChangeProp={onChange}
            brands={brands}
            models={models}
            setValue={setValue}
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            filesDescription={filesDescription}
          />
        </Container>
      </div>

      <FooterButton
        submitTitle="signupPage.footerButtonContainer.joinToTeam"
        onCancel={onCancel}
      />
    </form>

  );
};

export default VendorFormStep;
