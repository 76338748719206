import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from 'sharedComponents/Table/Table';
import moment from 'moment';
import { getTranslateFunction } from 'helpers';
import { IOrder, Preorder } from 'interfaces';
import { formatPrice } from 'utils/formatters';
import useStyles from '../styles';

interface Props {
  order: IOrder | Preorder;
  goToOrder: () => void;
}

const OrderListItem: React.FC<Props> = ({
  order,
  goToOrder,
}) => {
  const t = getTranslateFunction();
  const classes = useStyles();
  const {
    inCity,
    betweenCity,
    international,
    helpOnRoad,
    buyThings,
    rent,
    services,
    tripType,
    type,
    subType,
    createdAt,
    price,
    currency,
    preorderCurrency,
  } = order;

  const [rubric] = [
    inCity,
    betweenCity,
    international,
    helpOnRoad,
    buyThings,
    rent,
    services,
  ].flat().filter(Boolean);

  const itemCurrency = preorderCurrency || currency;

  const tableConfig = {
    'activeOrdersPage.date': moment(createdAt).format('DD.MM.YYYY / HH:mm'),
    'activeOrdersPage.project': `${t(tripType || type)}, ${t(subType)}`,
    'activeOrdersPage.rubric': t(rubric),
    'activeOrdersPage.summ': {
      text: `${formatPrice(price)} ${itemCurrency}`,
      className: classes.orderTableCellGreenText,
    },
  };

  return (
    <>
      <Box className={classes.line} />
      <Box
        className={classes.orderContainer}
      >
        <Table>
          <TableBody>
            {Object.entries(tableConfig).map(([key, value]) => (
              <TableRow key={key} className={classes.orderTableRow}>
                <TableCell className={classes.orderTableCell}>
                  <Typography>
                    {t(key)}
                  </Typography>
                </TableCell>
                <TableCell className={classes.orderTableCell}>
                  {typeof value === 'string' ? (
                    <Typography>
                      {value}
                    </Typography>
                  ) : (
                    <Typography className={value.className}>
                      {value.text}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button
          className={classes.orderButton}
          onClick={goToOrder}
          variant="contained"
          color="primary"
        >
          {t('activeOrdersPage.moreDetails')}
        </Button>
      </Box>
    </>
  );
};

export default OrderListItem;
