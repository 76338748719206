import {
  FormControl, Box, FormHelperText, Button,
} from '@material-ui/core';
import classNames from 'classnames';
import CustomFileInput from 'components/CustomFileInput/CustomFileInput';
import React, { useCallback, useState } from 'react';
import { Controller, RegisterOptions, UseFormMethods } from 'react-hook-form';
import { Close } from '@material-ui/icons';
import { getTranslateFunction } from 'helpers';
import { indexStyles } from '../material';

export interface IDocumentItem {
  key: string;
  id: string;
  preview: string;
  defaultPreview: string;
  text: string;
  rules?: RegisterOptions;
  isVerified?: boolean;
  withVerificationStatus?: boolean,
}

interface Props extends Pick<UseFormMethods, 'errors' | 'control'> {
  item: IDocumentItem;
  description?: string;
}

const DocumentItem: React.FC<Props> = ({
  item,
  control,
  errors,
  description,
}) => {
  const t = getTranslateFunction();
  const classes = indexStyles();
  const [collapsedDescription, setCollapsedDescription] = useState(false);
  const {
    key,
    id,
    preview,
    defaultPreview,
    rules,
    text,
    isVerified,
    withVerificationStatus,
  } = item;

  const toggleCollapsedDescription = useCallback(() => {
    setCollapsedDescription((prev) => !prev);
  }, []);

  return (
    <Controller
      control={control}
      name={key}
      render={({ value, onChange }) => (
        <>
          <FormControl
            className={classNames(classes.formControl, {
              required: rules?.required,
            })}
            error={!!errors[key]}
          >
            <Box className={classes.uploadContainer}>
            <CustomFileInput
                id={id}
                preview={preview}
                defaultPreview={defaultPreview}
                text={t(text)}
                onChange={onChange}
                value={value}
                isVerified={isVerified}
                withVerificationStatus={withVerificationStatus}
              >
                <FormHelperText component='span' id='component-error-text'>
                  {t(errors[key]?.message)}
                </FormHelperText>
              </CustomFileInput>
            </Box>
          </FormControl>
          {!rules?.required && description && (
            <div
              className={classNames(
                classes.documentItemDescriptionWrapper,
                { [classes.documentItemDescriptionWrapperCollapsed]: collapsedDescription },
              )}
            >
              <FormHelperText
                className={classNames(
                  classes.documentDecs,
                  'MuiFormHelperText-root',
                  'Mui-error',
                  {
                    [classes.textNoWrap]: !collapsedDescription,
                    [classes.documentDecsFull]: collapsedDescription,
                  },
                )}
              >
                {description}
              </FormHelperText>
              {collapsedDescription ? (
                <Button
                  onClick={toggleCollapsedDescription}
                  color='secondary'
                  variant='text'
                  className={classes.documentItemDescButton}
                >
                  <Close />
                </Button>
              ) : (
                <Button
                  onClick={toggleCollapsedDescription}
                  color='primary'
                  variant='contained'
                  className={classes.documentItemDescButton}
                >
                 <span className={classes.textNoWrap}>{t('signup.documentItem.showAll')}</span>
                </Button>
              )}
            </div>
          )}
        </>
      )}
      rules={rules}
    />
  );
};

export default DocumentItem;

DocumentItem.defaultProps = {
  description: '',
};
