import { Buffer } from 'buffer';
import _ from 'lodash';
import { createHash } from 'crypto-browserify';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { vendorDefaultValues } from 'pages/SignUpPage/components/SignUpComponent';
import maleProfile from 'images/icons/maleProfile.svg';
import femaleProfile from 'images/icons/femaleProfile.svg';
import {
  AppInfoTextOnlyItem,
  HashedFile,
  ICountry,
  IOrder,
  IRating,
  Lang,
  LangText,
  IChat,
  OrderType,
  Preorder,
  PreorderFilterData,
  TCurrency,
  UserGender,
  Vendor,
  type VendorDefaultValuesKeys,
} from 'interfaces';
import { buttons } from 'utils/optionsConfig';
import countriesList from 'services/countriesList';
import { deleteSubscribeNotification, getVapidKey, sendSubscribeNotification } from 'services/commonService';
import {
  IMAGE_USER_SRC,
  IMAGE_ORDER_SRC,
  IMAGE_VENDOR_SRC,
  IMAGE_GIFTS_SRC,
  IMAGE_APP_INFO_SRC,
  USDcurrency,
  CHAT_LIST_SORT_INDEX,
} from './constants';

const SINGLE_TYPES = ['buyThings', 'rent', 'services', 'helpOnRoad'];

export const getEnv = (): {
  [key: string]: string | undefined;
} => process.env;

export const getMonths = (locale: string): string[] => {
  const result = [];
  for (let i = 0; i < 12; i += 1) {
    result.push(new Date(2010, i).toLocaleString(locale, { month: 'long' }));
  }
  return result;
};

export const getUserDocumentLink = (id: number | undefined, fallbackImg: string): string => {
  const result = id
    ? `${IMAGE_USER_SRC}${id}`
    : fallbackImg;

  return result;
};

export const getUserImageLink = (id?: number | number[], gender?: UserGender): string => {
  const genderPhoto = gender === 'male' ? maleProfile : femaleProfile;
  const isImageListArray = (Array.isArray(id));

  if (!id || (isImageListArray && !id.length)) {
    return genderPhoto;
  }

  const userId = isImageListArray ? id.at(-1) : id;

  return `${IMAGE_USER_SRC}${userId}`;
};

export const getVendorImageLink = (id: number | undefined): string => {
  if (!id) return '';

  return (
    `${IMAGE_VENDOR_SRC}${id}`
  );
};

export const getGiftsImageLink = (id: number | undefined): string => {
  if (!id) return '';

  return (
    `${IMAGE_GIFTS_SRC}${id}`
  );
};

export const getAppInfoImageLink = (id: number | undefined): string => {
  if (!id) return '';

  return (
    `${IMAGE_APP_INFO_SRC}${id}`
  );
};

export const isSingleType = (type: string): boolean => (
  SINGLE_TYPES.some((innerType: string) => innerType === type)
);

export const configureOSRMLink = (query: string): string => {
  const { REACT_APP_OSRM_HOST } = getEnv();

  return (
    `${REACT_APP_OSRM_HOST}/route/v1/driving/${query}?geometries=geojson&overview=full&continue_straight=false`
  );
};

export const getCurrencyByCountry = (country?: string): TCurrency => {
  const findedCountry = countriesList.find(({ label }) => label === country) as ICountry;

  return findedCountry?.currency ?? USDcurrency;
};

export const getOrderImageLink = (id: number): string => `${IMAGE_ORDER_SRC}${id}`;

export const getBase64FromFile = (file: File): Promise<string> => (
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { result }: any = reader;

      resolve(result);
    };
    reader.onerror = (error) => reject(error);
  })
);

export const getTranslateFunction = (): (
(a: string) => string) => {
  const { t } = useTranslation('general');
  const translateWrapper: TFunction = (key: string): string => t(key) || key;

  return translateWrapper;
};

type AnyType = number | string | boolean | null;
type AnyObject = {
  [key: string]:AnyType;
};

export const checkDifferents = (firstObject: AnyObject, secondObject: AnyObject): boolean => {
  const diff = Object.entries(firstObject).map(([key, value]: [string, AnyType]) => (
    secondObject[key] !== value
  ));

  return diff.some(Boolean);
};

export type FileObject = {
  base64: string;
  field: string;
  file: File,
};

export const configureFilesObject = async (properties: string[], object: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}): Promise<FileObject[]> => {
  const photos = properties
    .filter((prop) => object[prop] instanceof FileList)
    .map(async (field) => {
      const [file] = object[field];

      const base64 = await getBase64FromFile(file);

      return ({
        field,
        base64,
        file,
      });
    });

  return Promise.all(photos);
};

export const dataURLtoFile = (dataurl: string, filename: string): File => {
  const arr = dataurl.split(',');
  const [firstArr] = arr;

  const [, mime] = firstArr.match(/:(.*?);/) || [];
  const [, ext] = mime.split('/');
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${filename}.${ext}`, { type: mime });
};

const getFileType = (file: File) => {
  if (file.type) {
    return file.type;
  }

  const extensionToMimeType: { [key: string]: string } = {
    '.heic': 'image/heic',
    '.heif': 'image/heif',
  };

  const fileName = file.name.toLowerCase();
  const extension = Object.keys(extensionToMimeType).find((ext) => fileName.endsWith(ext));
  return extension ? extensionToMimeType[extension] : 'application/octet-stream';
};

export const filesHash = (file: File): Promise<HashedFile> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reader.onload = function onloadCallback(evt: any) {
    const binary = evt.currentTarget.result;
    const b = Buffer.from(binary);
    const has = createHash('md5').update(b);
    const hash = has.digest('hex');
    const fileType = getFileType(file);

    const obj = {
      isFile: true,
      fileProperties: {
        fileType,
        fileSize: file.size,
        md5Hash: hash,
      },
    };
    resolve(obj);
  };
  reader.onerror = () => {
    const { error } = reader;
    reject(error);
  };
  reader.readAsArrayBuffer(file);
});

export const hashFiles = async (photosArray: FileObject[]): Promise<HashedFile[]> => {
  const promises = photosArray.map(({ file }) => filesHash(file)) as Promise<HashedFile>[];
  const hashes = await Promise.all(promises);

  return hashes;
};

export type ConfiguredFile = {
  data: FormData;
  id: number;
};

export const confugureFilesBeforeSend = (
  files: FileObject[],
  hashes: HashedFile[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  idsItem: any,
): ConfiguredFile[] => {
  const formDataArray = files.map(({ file, field }: FileObject, index: number) => {
    const { md5Hash } = hashes[index].fileProperties;
    const id = idsItem[field];
    const formData = new FormData();
    formData.append(md5Hash, file);

    return {
      id,
      data: formData,
    };
  });

  return formDataArray;
};

export const formatVendorServicesToFormValues = (vendor: Vendor): Vendor => {
  if (!vendor) return vendor;

  const properties = [
    'alongTheWay',
    'courier',
    'helpOnRoad',
    'buy',
    'rent',
    'services',
  ] as OrderType[];

  const result = properties.reduce((acc: Vendor, prop: OrderType): Vendor => {
    const { [prop]: propValue, ...otherVendorValues } = acc;
    if (!propValue) return acc;

    const types = Object.values(propValue).flat();
    const typeValues = buttons.filter(({ contentKey }) => types.includes(contentKey));

    const { authOptions } = acc;
    const authOptionsValue = authOptions
      ? [...authOptions, ...typeValues]
      : typeValues;

    return {
      ...otherVendorValues,
      authOptions: authOptionsValue,
    };
  }, vendor);

  return result;
};

export const hideValue = (str: string, count: number): string => {
  const hiddenPart = Array(count).fill('*').join('');

  return `${str.slice(0, -count)}${hiddenPart}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatLangItems = (item: any, lang: Lang): {
  [key: string]: string | number;
} => (
  Object.entries(item).reduce((acc, [key, value]) => {
    if (!key.includes(lang)) return acc;
    const newKey: string = key.replace(lang, '').toLowerCase();

    return {
      ...acc,
      [newKey]: value,
    };
  }, {})
);

export const formatAppInfo = (items: AppInfoTextOnlyItem[], lang: Lang, properties: string[]): {
  [key: string]: string;
} => {
  const registerItems = items.filter((item: AppInfoTextOnlyItem) => (
    properties.includes(item.position)
  ));

  const result = registerItems.reduce(
    (acc, item) => {
      const key = `${lang}Text` as LangText;
      const { [key]: value } = item;

      return {
        ...acc,
        [item.position]: value,
      };
    },
    {},
  );

  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onlyUniquePrimitives = (value: any, index: number, self: any[]): boolean => (
  self.indexOf(value) === index
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUniqueItemsPrimitives = (items: any[]): any[] => {
  const uniqueItems = items.filter(onlyUniquePrimitives);
  return uniqueItems;
};

export const getUniqueItemsObjects = <T extends Record<string, unknown>>(
  items: T[],
  key: keyof T,
): T[] => {
  const uniqueItems = items.filter((item, index, arr) => {
    const findedIndex = arr.findIndex((i) => item[key] === i[key]);

    return index === findedIndex;
  });
  return uniqueItems;
};

export const getErrorMessage = (error: Error): string => {
  const stringifiedMessage = String(error.message);
  return stringifiedMessage.replace(/^[\w ]+: /g, '');
};

export const booleanFromString = (string: string) => {
  switch (string) {
    case 'true':
    case '1':
      return true;
    case 'false':
    case '0':
      return false;

    default: return false;
  }
};

export const booleanOrNullFromString = (value: string): boolean => {
  switch (value) {
    case 'true':
    case '1':
      return true;
    case 'false':
    case '0':
    case null:
      return false;
    default:
      return false;
  }
};

export const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const percentFromNumber = (total: number, percent: number): number => (
  Number(((total / 100) * percent).toFixed(2))
);

// export const subscribeNotification = async (): Promise<boolean> => {
//   const registration = await navigator.serviceWorker?.getRegistration();
//   if (!registration) return false;

//   const { key } = await getVapidKey();

//   const connection = await registration.pushManager.subscribe({
//     userVisibleOnly: true,
//     applicationServerKey: key,
//   });

//   const response = await sendSubscribeNotification(connection);
//   return response.result;
// };

export const subscribeNotification = async (): Promise<boolean> => {
  if (!('serviceWorker' in navigator) || !('Notification' in window)) {
    return false;
  }

  let { permission } = Notification;

  if (permission === 'default') {
    permission = await Notification.requestPermission();
  }

  if (permission !== 'granted') {
    return false;
  }

  const registration = await navigator.serviceWorker.getRegistration();
  if (!registration) {
    return false;
  }

  const { key } = await getVapidKey();

  const connection = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: key,
  });

  const response = await sendSubscribeNotification(connection);
  return response.result;
};

export const unsubscribeNotification = async (): Promise<boolean> => {
  const registration = await navigator.serviceWorker?.getRegistration();
  if (!registration) return true;
  const subscription = await registration.pushManager.getSubscription();

  if (!subscription?.endpoint) return true;

  await registration?.unregister();
  await deleteSubscribeNotification(subscription.endpoint);

  return true;
};

export const sortArrayBySortIndex = <T>(
  array: Array<{ sortIndex: number } & T>,
  desc?: boolean,
): Array<T> => (
    array.sort((a, b) => (
      desc
        ? b.sortIndex - a.sortIndex
        : a.sortIndex - b.sortIndex
    ))
  );

export const generateStringQuery = (data: Record<string, string | number>): string => (
  Object.entries(data).reduce((acc, [key, value]) => `${acc}${key}=${value}&`, '?')
);

export const getPreorderURLByOrder = (order: IOrder): string => {
  const {
    inCity, betweenCity, international, helpOnRoad, buyThings, rent, services,
  } = order;

  const props = {
    inCity, betweenCity, international, helpOnRoad, buyThings, rent, services,
  };

  const findedProp = Object.entries(props).find(([, value]) => value) ?? [];
  const [key, value] = findedProp;

  return `/preorder/${order.tripType}?${key}=[${value}]`;
};

export const calculateIndex = (chat: IChat, supportUuid: string): number => {
  const { lastMessage, user: { uuid }, orderIds } = chat;

  if (orderIds.length) return CHAT_LIST_SORT_INDEX.ORDER_CHAT;
  if (!lastMessage) return CHAT_LIST_SORT_INDEX.DEFAULT;

  if (uuid === supportUuid) return Infinity;
  const index = Number(lastMessage.fromUser === uuid && !lastMessage.isRead);
  return index;
};

export const filterPreorders = (preorder: Preorder, filterData: PreorderFilterData): boolean => {
  if (preorder.subType === 'international') return true;
  if (preorder.subType === 'betweenCity') return preorder.country === filterData.country;

  return preorder.buyerCity === filterData.city && preorder.country === filterData.country;
};

export const calculateAverageRating = (rating: IRating | null): number => {
  if (!rating) return 0;

  const values = Object.entries(rating)
    .filter(([key]) => key.toLowerCase().includes('avg'))
    .map(([, value]) => Number(value));

  const sum = values.reduce((a, b) => a + b, 0);
  const avg = (sum / values.length) || 0;

  return Number(avg.toFixed(1));
};

export const checkIsStringIncludes = (
  stringTemplates: string[],
  stringToCheck: string,
): boolean => {
  const stringToCheckLowerCase = stringToCheck.toLowerCase();
  const isIncludes = stringTemplates.some((string) => (
    string.toLowerCase().includes(stringToCheckLowerCase)
  ));

  return isIncludes;
};

export const checkIsInsideIframe = (): boolean => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const checkVendorComformCriteria = (vendor: Vendor): boolean => {
  const {
    vehicleConditioner,
    antiLockBrakingSystem,
    vehicleRecordPlayer,
    airBag,
    babyChair,
    bspSystem,
  } = vendor;

  const vendorServices = [
    vehicleConditioner,
    antiLockBrakingSystem,
    vehicleRecordPlayer,
    airBag,
    babyChair,
    bspSystem,
  ].filter(Boolean);

  return vendorServices.length >= 4;
};

// TODO add types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeObjDiff = (a: any, b: any) => _.fromPairs(_.differenceWith(_.toPairs(a), _.toPairs(b), _.isEqual));

export const setTimestampToHtml = () => {
  const timestamp = Date.now();
  const day = new Date(timestamp).getDate();
  const month = new Date(timestamp).getMonth() + 1;
  document.documentElement.setAttribute('ver', `${timestamp}-${day}${month}`);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GetSucceedObjFields = <T extends { [key: string]: any }>(obj: T, fields: string[]) => string[];

export const getSucceedObjFields: GetSucceedObjFields = (obj, fields = []) => {
  return fields.reduce((acc: string[], field: string) => {
    const isSucceedField = booleanOrNullFromString(obj[field]);

    if (isSucceedField) {
      acc.push(field);
    }

    return acc;
  }, []);
};

export const formatTimeRemaining = (milliseconds: number): string => {
  const minutes = Math.floor(milliseconds / 60000);
  const seconds = Math.floor((milliseconds % 60000) / 1000);
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

const DEFAULT_VENDOR_PROPERTIES = Object.keys(vendorDefaultValues) as VendorDefaultValuesKeys[];

export const hasDefaultProperties = (vendor: Vendor) => {
  return DEFAULT_VENDOR_PROPERTIES.every((property) => {
    return vendor[property] === 'sedan' || vendor[property] === null;
  });
};
