import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getTranslateFunction } from 'helpers';
import useStyles from './styles';

const BackButton = () => {
  const history = useHistory();
  const t = getTranslateFunction();
  const classes = useStyles();

  return (
    <Button
      className={classes.backButton}
      startIcon={<ArrowBackIcon />}
      onClick={() => history.goBack()}
    >
      {t('common.backButton')}
    </Button>
  );
};

export default BackButton;
