import { useHistory } from 'react-router';
import { Box, Typography, Badge } from '@material-ui/core';
import InfoToggle from 'components/InfoToggle/InfoToggle';
import ModalMessage from 'sharedComponents/ModalMessage';
import buyIcon from 'images/icons/buy.svg';
import alongTheWayIcon from 'images/icons/alongTheWay.svg';
import courierIcon from 'images/icons/courier.svg';
import helpOnRoadIcon from 'images/icons/helpOnRoad.svg';
import rentalIcon from 'images/icons/rental.svg';
import servicesIcon from 'images/icons/services.svg';
import { getTranslateFunction } from 'helpers';
import { groupByService, services } from 'utils/optionsConfig';
import { useModal } from 'services/customHooks';
import { useAuth } from 'services/auth';
import { Vendor } from 'interfaces';
import useStyles from './styles';

const icons: {
  [key: string]: string;
} = {
  buyIcon,
  alongTheWayIcon,
  courierIcon,
  helpOnRoadIcon,
  rentalIcon,
  servicesIcon,
};

const countSelectedCategories = (vendor: Vendor | null, projectType: string): number => {
  if (!vendor || !vendor[projectType]) return 0;

  const projectData = vendor[projectType];
  if (!projectData) return 0;

  return Object.values(projectData).reduce((total, categories) => {
    return total + (Array.isArray(categories) ? categories.length : 0);
  }, 0);
};

const ProjectSubscribePage = () => {
  const history = useHistory();
  const { isModalVisible, showModal, hideModal } = useModal({ isVisibleByDefault: true });
  const { vendor } = useAuth();

  const handleOptions = (key: string) => {
    const to = `/projects/${key}`;

    history.push(to);
  };

  const t = getTranslateFunction();
  const classes = useStyles();
  const listData = groupByService(services, 'type');

  return (
    <>
      <Box className={classes.infoToggleContainer}>
        <InfoToggle
          description={t('vendorProjects.infoToggle.description')}
          btnLabel={t('common.details')}
          onToggle={showModal}
        />
      </Box>

      <Box className={classes.optionsContainer}>
        {Object.entries(listData).map(([key, item]) => {
          const [{ icon }] = item;
          const selectedCount = countSelectedCategories(vendor, key);

          return (
            <Box
              data-home-options-btn={key}
              className={classes.optionLinkContainer}
              key={key}
              onClick={() => handleOptions(key)}
            >
              <img src={icons[icon]} alt={key} />
              <Box className={classes.optionLink}>
                <Typography variant="body2">{t(key)}</Typography>
                {selectedCount > 0 && (
                  <Badge
                    badgeContent={selectedCount}
                    color="primary"
                    classes={{
                      badge: classes.badge,
                    }}
                  />
                )}
              </Box>
            </Box>
          );
        })}
      </Box>

      <ModalMessage
        isVisible={isModalVisible}
        onClose={hideModal}
        message={t('vendorProjects.infoToggle.description')}
        variant="info"
      />
    </>
  );
};

export default ProjectSubscribePage;
