import { Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import LeafletMapWrapper from 'components/LeafletMapWrapper';
import { Map } from 'leaflet';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router';
import { getTranslateFunction } from 'helpers';
import { Suggestion } from 'interfaces';
import { useAuth } from 'services/auth';
import { reverseGeocode } from 'services/geo';
import { parseQueryString, stringifyQueryObject } from 'utils/formatters';
import useStyles from './styles';

interface SelectGeolocationMapProps {
  onChange: (item: Suggestion) => void;
  onCancel: () => void;
}

const SelectGeolocationMap: React.FC<SelectGeolocationMapProps> = ({
  onChange,
  onCancel,
}) => {
  const { currentIp } = useAuth();
  const t = getTranslateFunction();
  const [map, setMap] = useState<Map>();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const defaultMapCenter = useMemo(() => {
    const params = parseQueryString(location.search);
    if (params.lat && params.lng) {
      const lat = parseFloat(params.lat);
      const lng = parseFloat(params.lng);
      if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
        return [lat, lng] as [number, number];
      }
    }
    return currentIp?.ll;
  }, [location.search, currentIp?.ll]);

  const updateUrlPosition = useCallback((lat: number, lng: number) => {
    const params = parseQueryString(location.search);
    const newQueryParams = stringifyQueryObject({
      ...params,
      lat: lat.toFixed(6),
      lng: lng.toFixed(6),
    });
    history.replace(newQueryParams);
  }, [location.search, history]);

  const handleMapMove = useCallback(() => {
    if (!map) return;
    const center = map.getCenter();
    updateUrlPosition(center.lat, center.lng);
  }, [map, updateUrlPosition]);

  useEffect(() => {
    if (!map) return;

    map.on('moveend', handleMapMove);
    return () => {
      map.off('moveend', handleMapMove);
    };
  }, [map, handleMapMove]);

  const onSubmit = useCallback(async () => {
    if (!map) return;

    const centerMap = map.getCenter();
    const response = await reverseGeocode([centerMap.lat, centerMap.lng]);

    const params = parseQueryString(location.search);
    const { showMap, ...restParams } = params;
    const newQueryParams = stringifyQueryObject({
      ...restParams,
      lat: centerMap.lat.toFixed(6),
      lng: centerMap.lng.toFixed(6),
    });
    history.replace(newQueryParams);

    onChange(response);
  }, [map, onChange, location.search, history]);

  return (
    <div className={classNames(classes.mapWrapper)}>
      <LeafletMapWrapper
        onCreated={setMap}
        showCenteredPin
        defaultMapCenter={defaultMapCenter}
      >
        <div className={classes.boxBtnSelect}>
          <Button
            onClick={onCancel}
            color="primary"
            className={classes.buttonReturn}
            variant="contained"
          >
            <ArrowBack />
          </Button>
          <Button
            onClick={onSubmit}
            color="primary"
            variant="contained"
            className={classes.buttonSelectLocation}
          >
            {t('preorderPage.button.confirmLocationSelect')}
          </Button>
        </div>
      </LeafletMapWrapper>
    </div>
  );
};

export default SelectGeolocationMap;
