import { makeStyles } from '@material-ui/core/styles';
import { grayBG, green, red } from '../../constants';

export default makeStyles(
  {
    withBorder: {
      border: `4px ${green} solid`,
      boxShadow:
        '0px 11px 29px -7px rgb(0 0 0 / 30%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
    },
    modal: {
      padding: '10px',
    },
    withoutPadding: {
      padding: 0,
    },
    styledModal: {
      position: 'relative',
      inset: 0,
      zIndex: 1,

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      width: '250px',
      minHeight: '100px',

      fontSize: '14px',
      padding: '25px',
      backgroundColor: grayBG,

      wordWrap: 'break-word',
      overflowWrap: 'break-word',

      '&::after': {
        content: '\'\'',
        position: 'absolute',
        inset: '17px 19px',
        border: `${red} 1px solid`,
        pointerEvents: 'none',
      },
    },
  },
  { index: 1 },
);
