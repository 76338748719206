import { useEffect, useState } from 'react';
import { formatTimeRemaining } from 'helpers';
import { getTimestamp, setTimestamp, type TimestampTypes } from 'services/commonService';

const ONE_HOUR = 1000 * 60 * 60;

interface UseTimestamp {
  userId: number | undefined;
  type: TimestampTypes;
}

export const useTimestamp = ({ userId, type }: UseTimestamp) => {
  const [lastChangeTime, setLastChangeTime] = useState<number | null>(null);
  const [timeRemaining, setTimeRemaining] = useState<string>('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const getIsButtonDisabled = async () => {
    const response = await getTimestamp({ id: userId, type });
    const lastUpdateTime = response?.timestamp;
    if (!lastUpdateTime) {
      return false;
    }

    const timeSinceLastChange = Date.now() - lastUpdateTime;
    return timeSinceLastChange < ONE_HOUR;
  };

  useEffect(() => {
    const fetchLastChangeTime = async () => {
      const response = await getTimestamp({ id: userId, type });
      if (response?.timestamp) {
        setLastChangeTime(response.timestamp);
      }
    };

    if (userId) {
      fetchLastChangeTime();
    }
  }, [userId]);

  useEffect(() => {
    const updateTimeRemaining = () => {
      if (lastChangeTime) {
        const timeSinceLastChange = Date.now() - lastChangeTime;
        if (timeSinceLastChange < ONE_HOUR) {
          const remainingTime = ONE_HOUR - timeSinceLastChange;
          setTimeRemaining(formatTimeRemaining(remainingTime));
        } else {
          setTimeRemaining('');
        }
      }
    };

    updateTimeRemaining();
    const timer = setInterval(updateTimeRemaining, 1000);

    return () => clearInterval(timer);
  }, [lastChangeTime]);

  useEffect(() => {
    const checkIsButtonDisabled = async () => {
      const disabled = await getIsButtonDisabled();
      setIsButtonDisabled(disabled);
    };

    checkIsButtonDisabled();
  }, []);

  const updateTimestamp = async () => {
    const currentTime = Date.now();
    setLastChangeTime(currentTime);

    await setTimestamp({ id: userId, type });
    setIsButtonDisabled(true);
  };

  return { timeRemaining, isButtonDisabled, updateTimestamp };
};
