import { useEffect, useState } from "react";

import { getHTMLContent } from "./helpers";
import { requestWebsitePage } from "services/commonService";

    // getHTMLContent('https://ppww.eu/ua/3802')

export const usePromotionAndGifts = () => {
  const [ data, setData ] = useState<string | null>(null);

  useEffect(() => {
    requestWebsitePage({ url: '3802', lang: 'ua' })
      .then((res) => {
        setData(res);
      });
  }, []);


  return { data };
}
