import React from 'react';
import { useForm } from 'react-hook-form';
import {
  FormHelperText,
  TextField,
  Button,
  Container,
  FormControl,
  Box,
  Typography,
} from '@material-ui/core';
import LanguageSelect from 'components/LanguageSelect/LanguageSelect';
import MessageTsx from 'images/message';
import { useHistory } from 'react-router';
import StyledRouteLink from 'components/StyledRouteLink';
import PasswordField from 'components/PasswordField/PasswordField';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ExternalLink from 'sharedComponents/ExternalLink/ExternalLink';
import { useAuth, useIsLoggedIn } from 'services/auth';
import logo from 'components/common/WebwayLogo.png';
import logoHourseWithBG from 'components/common/logoHourseWithBG.png';
import { getUserSettings } from 'services/commonService';
import { getTranslateFunction, subscribeNotification } from 'helpers';
import useStyles from './SignInStyles';
import { darkIcon } from '../../constants';

interface FormValues {
  login: string;
  password: string;
}

const defaultValues: FormValues = {
  login: '',
  password: '',
};

const SignInPage: React.FC = () => {
  const {
    register, handleSubmit, errors, setError, clearErrors,
  } = useForm({ defaultValues, mode: 'onTouched' });
  const { signin, setIsLoading } = useAuth();
  const history = useHistory();
  const t = getTranslateFunction();
  const { i18n } = useTranslation();

  useIsLoggedIn();

  const onSubmit = async (data: FormValues) => {
    setIsLoading(true);
    try {
      const user = await signin(data);
      const [settings] = await getUserSettings();

      if (settings.userLanguage) {
        i18n.changeLanguage(settings.userLanguage);
      }

      if (!user) return;
      subscribeNotification();

      const defaultPath = user.isUserVerified ? '/' : '/profile';
      history.push(defaultPath);
    } catch (error) {
      setError('login', { message: 'signInPage.input.error' });
      setError('password', { message: 'signInPage.input.error' });
    } finally {
      setIsLoading(false);
    }
  };

  const classes = useStyles();

  return (
    <Container className={classes.mainContainer}>
      <LanguageSelect />
      <Container>
        <div className={classes.logoContainer}>
          <img className={classes.logoStyle} alt={t('webway.logo')} src={logo} />
        </div>

        <Typography className={classes.siteLinkWrapper}>
          <ExternalLink to={t('signInPage.siteLink.link')} >
            {t('signInPage.siteLink.label')}
          </ExternalLink>
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          <FormControl className={classes.formControl} error={!!errors.login}>
            <TextField
              name="login"
              className={classNames(classes.TextField, { error: !!errors.login })}
              placeholder={t('signInPage.loginLabel')}
              inputRef={register}
              onFocus={() => clearErrors('login')}
              InputProps={{
                autoComplete: 'new-password',
                disableUnderline: true,
                startAdornment: (
                  <Box className={classes.adornment}>
                    <MessageTsx color={darkIcon} />
                  </Box>
                ),
              }}
            />
            {errors.login?.message && (
              <FormHelperText
                className={classes.errorMessage}
              >
                {t(errors.login.message)}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl className={classes.formControl} error={!!errors.password}>
            <PasswordField
              styles={classNames(classes.TextField, { error: !!errors.password })}
              inputRef={register}
              onFocus={() => clearErrors('password')}
              name="password"
              label={t('signInPage.passwordLabel')}
              iconColor={darkIcon}
              withIcon
            />
            {errors.password?.message && (
              <FormHelperText
                className={classes.errorMessage}
              >
                {t(errors.password.message)}
              </FormHelperText>
            )}
          </FormControl>

          <Button
            variant="contained"
            type="submit"
            className={classes.button}
          >
            {t('signInPage.button.login')}
          </Button>

          <Container className={classes.buttonsContainer}>
            <StyledRouteLink to="/reset-password">
              {t('signInPage.resetPassword')}
            </StyledRouteLink>

            <StyledRouteLink to="/signup">
              {t('signInPage.link.register')}
            </StyledRouteLink>
          </Container>
        </form>
      </Container>
      <Box className={classes.gifContainer}>
        <img className={classes.logoGif} src={logoHourseWithBG} alt="loading..." />
      </Box>
    </Container>
  );
};

export default SignInPage;
