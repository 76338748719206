import { makeStyles } from '@material-ui/core/styles';
import {
  grayBG,
  red,
  textColor,
} from '../../constants';

export const indexStyles = makeStyles({
  serviceContainer: {
    width: '100%',
    padding: '10px',
    textAlign: 'center',
    marginBottom: '50px',
    boxSizing: 'border-box',
  },
  vendorModal: {
    position: 'relative',
    inset: 0,
    zIndex: 1,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: '250px',
    minHeight: '100px',

    fontSize: '14px',
    padding: '25px',
    backgroundColor: grayBG,

    wordWrap: 'break-word',
    overflowWrap: 'break-word',

    '&::after': {
      content: '\'\'',
      position: 'absolute',
      inset: '17px 19px',
      border: `${red} 1px solid`,
      pointerEvents: 'none',
    },
  },
}, { index: 1 });

export const listItemStyles = makeStyles({
  listContainer: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 0 20px 0',
    backgroundColor: grayBG,
  },
  serviceItem: {
    display: 'flex',
    alignItems: 'center',
  },
  serviceName: {
    fontSize: '15px',
    color: textColor,
  },
  serviceCount: {
    fontSize: '20px',
    letterSpacing: '0.2rem',
    color: textColor,
  },
  svgIcon: {
    width: '70px',
    height: 'auto',
    marginRight: '10px',
  },
}, { index: 1 });
