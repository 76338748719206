import { makeStyles } from '@material-ui/core/styles';
import {
  backgroundColor,
  cyan,
  grayBG,
  green,
  orange,
  red,
} from '../../constants';

export default makeStyles({
  button: {
    position: 'absolute',
    left: '25px',
    bottom: '100px',
    zIndex: 2000,
    backgroundColor: green,
    color: backgroundColor,
  },
  clientOrderPage: {
    height: '100%',
  },
  openMap: {
    overflow: 'hidden',
  },
  time: {
    fontSize: '13px',
    color: orange,
    fontWeight: 'bold',
  },
  mapContainer: {
    height: '100%',
  },
  modalContainer: {
    width: '90%',
    textAlign: 'center',
    padding: 0,
  },
  modalHeader: {
    textAlign: 'center',
    padding: '5px',
  },
  modalContent: {
    textAlign: 'center',
    padding: '0 10px',
  },
  clientOrderContainer: {
    height: (height) => (`calc(${height}px - 140px)`),
    display: 'flex',
    flexDirection: 'column',
  },
  orderInfo: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10px',
    alignItems: 'center',
    '& > *': {
      width: '100%',
      textAlign: 'center',
      fontSize: '16px',
      marginTop: '5px',
    },
  },
  orderInfoBtn: {
    backgroundColor: green,
    boxSizing: 'border-box',
    color: backgroundColor,
    padding: '5px 10px',
  },
  svg: {
    width: '20px',
    height: 'auto',
    margin: '5px',
    '&.star': {
      color: orange,
    },
  },
  textField: {
    '& .MuiInputBase-input': {
      fontSize: '13px',
      padding: '5px',
    },
    '& .MuiInputBase-multiline': {
      padding: '0',
    },
  },
  endAdornment: {
    marginLeft: '5px',
    padding: '5px',
    fontSize: '13px',
  },
  carPlate: {
    backgroundColor: cyan,
    color: backgroundColor,
    padding: '2px 5px',
  },
  phoneBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    padding: '5px 3px',
    border: '2px solid green',
    borderRadius: '50%',
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: green,
  },
  orderContainer: {
    padding: '5px 10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconBtn: {
    width: '30px',
    height: '50px',
    padding: '0',
    '& svg': {
      width: '30px',
      height: 'auto',
    },
    '& img': {
      width: '30px',
      height: 'auto',
    },
    '&.phone': {
      marginTop: '8px',
    },
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  option: {
    padding: '3px',
    fontWeight: 'bold',
    margin: '3px 0',
  },
  address: {
    fontWeight: 'bold',
  },
  btnContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around ',
    margin: '10px 0 0 0',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
  statusLabel: {
    color: backgroundColor,
    width: '100%',
    backgroundColor: green,
    textAlign: 'center',
    margin: '5px 0',
  },
  clientBtn: {
    padding: '5px',
    fontSize: '13px',
    fontWeight: 'bold',
    height: 'fit-content',
    textTransform: 'none',
    textAlign: 'center',
    margin: '2px 0',
    color: backgroundColor,
    '&.red': {
      backgroundColor: red,
    },
    '&.orange': {
      backgroundColor: orange,
    },
    '&.cyan': {
      backgroundColor: cyan,
    },
    '&.sos': {
      backgroundColor: red,
      width: 'fit-content',
    },
  },
  form: {
    width: '100%',
  },
  ratingBox: {
    height: (height) => (`calc(${height}px - 120px)`),
    display: 'flex',
    padding: '0 10px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  ratingItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
    '& .MuiTypography-root': {
      fontSize: '15px',
    },
  },
  ratingSendBtn: {
    color: backgroundColor,
    backgroundColor: green,
    width: '100%',
    marginTop: '10px',
  },
  ratingTitle: {
    backgroundColor: green,
    color: backgroundColor,
    width: '100%',
    textAlign: 'center',
    marginTop: '5px',
    '&.cyan': {
      backgroundColor: cyan,
      cursor: 'pointer',
    },
  },
  numberBox: {
    backgroundColor: grayBG,
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
    '& > *': {
      marginLeft: '5px',
      padding: '5px',
    },
  },
  reportMessage: {
    width: '100%',
    backgroundColor: grayBG,
    marginTop: '10px',
    padding: '5px',
  },
  maxLabel: {
    color: green,
    fontWeight: 'bold',
  },
  orderMapContainer: {
    width: '100%',
    height: (height) => (`calc(${height}px - 140px)`),
  },
  modalComment: {
    '& .MuiPaper-root': {
      margin: '0 10px',
      width: '100%',
    },
  },
  modalCommentContent: {
    padding: '0',
  },
  statsList: {
    display: 'flex',
    '& .MuiTypography-root': {
      fontSize: '13px',
    },
    '& > *': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  titleCommentsBox: {
    display: 'flex',
    alignItems: 'center',
  },
  titleComments: {
    marginLeft: '15px',
  },
  withBorder: {
    '& .MuiPaper-root': {
      border: `4px ${green} solid`,
      // eslint-disable-next-line max-len
      boxShadow: '0px 11px 29px -7px rgb(0 0 0 / 30%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
    },
  },
}, { index: 1 });
