import React from 'react';
import Modal from 'sharedComponents/Modal';
import { getTranslateFunction } from 'helpers';
import useStyles from './modalStyles';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  message: string;
}

const ModalMessage = ({ isVisible, onClose, message }: Props) => {
  const t = getTranslateFunction();
  const classes = useStyles();

  return (
    <Modal withoutBorder isOpen={isVisible} onClose={onClose}>
      <div className={classes.message}>{t(message)}</div>
    </Modal>
  );
};

export default ModalMessage;
