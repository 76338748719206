import { Box, Button, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import UserInfoContainer from 'components/UserInfoContainer';
import { getTranslateFunction } from 'helpers';
import { formatPrice } from 'utils/formatters';
import {
  ExtentedCurrencyPreorder,
  RatingType,
  User,
} from 'interfaces';
import useWindowSize from 'services/useWindowSize';
import VendorMap from '../VendorMap';
import useStyles from '../VendorOfferStyle';
import { minutes } from '../../../constants';

interface Props {
  vendorLocation: [number, number] | null;
  isSingleTypePreorder: boolean;
  handleSetOnChange: (a: string, b?: any) => void;
  preorder: ExtentedCurrencyPreorder;
  user: User;
  offerPrice: number;
  userRating: RatingType;
  time: number;
  preorderServiceId: number;
  secondOffer: boolean;
  vendorId: number | null;
  vendorUuid: string | null;
  submitBtnText: string;
  isMyOrder: boolean;
}

const VendorOfferMainForm: React.FC<Props> = ({
  vendorLocation,
  isSingleTypePreorder,
  handleSetOnChange,
  preorder,
  user,
  userRating,
  offerPrice,
  time,
  preorderServiceId,
  secondOffer,
  vendorId,
  vendorUuid,
  submitBtnText,
  isMyOrder,
}: Props) => {
  const { height } = useWindowSize();
  const classes = useStyles(height);
  const t = getTranslateFunction();

  const isDisabled = useMemo(() => {
    if (offerPrice <= 0) return true;
    if (time <= 0) return true;
    if (preorderServiceId) return true;
    if (secondOffer) return true;

    return false;
  }, [offerPrice, time, preorderServiceId, secondOffer]);

  return (
    <Box className={classes.offerLayout}>
      <Box className={classes.mapContainer}>
        <VendorMap locate={vendorLocation} items={[preorder]} />
      </Box>

      <UserInfoContainer
        offer={preorder}
        userRating={userRating}
        user={user}
        isSingleTypePreorder={isSingleTypePreorder}
      />

      <Box className={classes.settingsContainer}>
        {!isMyOrder && (
        <>
          <Box className={classes.priceBox}>
            <Box>
              <Typography>{t('offer.ConfirmFor')}</Typography>

              <Box
                className={
                      classNames(
                        classes.button,
                        classes.price,
                        {active: offerPrice === preorder?.price},
                      )
                    }
                onClick={() => handleSetOnChange('handlePrice', preorder.price)}
              >
                {formatPrice(preorder.price)}
              </Box>
            </Box>

            <Box>
              <Typography>{t('offer.propouseYourPrice')}</Typography>

              <Box
                className={classNames(
                  classes.button,
                  classes.price,
                  {active: offerPrice !== preorder.price},
                )}
                onClick={() => handleSetOnChange('handlePrice', '+')}
              >
                {`${formatPrice(offerPrice)} ${preorder.currency} +`}
              </Box>
            </Box>
          </Box>

          <Box className={classes.timeBox}>
            <Typography>{t('offer.specifyArrivalTime')}</Typography>

            <Box className={classes.timeBtns}>
              <Box
                onClick={() => handleSetOnChange('handleTime', 2)}
                className={classNames(classes.button, 'cyan', {active: time === 2})}
              >
                {`2 ${t(minutes)}`}
              </Box>
              <Box
                onClick={() => handleSetOnChange('handleTime', 5)}
                className={classNames(classes.button, 'cyan', {active: time === 5})}
              >
                {`5 ${t(minutes)}`}
              </Box>
              <Box
                onClick={() => handleSetOnChange('handleTime', '+')}
                className={classNames(classes.button, 'cyan', {active: time !== 2 && time !== 5})}
              >
                {`${time} ${t(minutes)} +`}
              </Box>
            </Box>
          </Box>
        </>
        )}

        <Box className={classes.mainBtns}>
          <Button onClick={() => handleSetOnChange('handleGoBack')} className={`${classes.button} green`}>
            {t('offer.goBack')}
          </Button>

          {!isMyOrder && (
            <Button
              onClick={() => (
                handleSetOnChange('handleConfirm', {
                  initialCurrency: preorder.initialCurrency,
                  offerPrice,
                  vendorLocation,
                  time,
                  vendorId,
                  vendorUuid,
                })
              )}
              className={classNames(classes.button, 'orange', {isDisabled})}
            >
              {t(submitBtnText)}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default VendorOfferMainForm;
