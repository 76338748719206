import { useCallback, useEffect, useMemo } from 'react';
import { Button, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router';
import classNames from 'classnames';
import { getTranslateFunction } from 'helpers';
import { Suggestion } from 'interfaces';
import { useSuggestions } from 'pages/PreorderPage/useSuggestions';
import { parseQueryString, stringifyQueryObject } from 'utils/formatters';
import { formatAddress } from 'services/geo';
import SelectGeolocationMap from './SelectGeolocationMap';
import useStyles from './styles';

const listItems = [
  { label: 'input.selectLocation.geoLabel', action: 'geo', key: 'geo' },
  { label: 'input.selectLocation.mapLabel', action: 'map', key: 'map' },
];

type Variant = 'profile';
interface SelectGeolocationProps {
  onChange: (value: Suggestion) => void;
  onCancel: () => void;
  defaultValue?: string;
  title?: string;
  className?: string;
  variant?: Variant;
}

const SelectGeolocation = ({
  onChange,
  onCancel,
  className = '',
  title = '',
  defaultValue = '',
  variant,
}: SelectGeolocationProps) => {
  const t = getTranslateFunction();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{ showMap?: boolean }>();

  const isShowingMap = useMemo(() => {
    const params = parseQueryString(history.location.search);
    return Boolean(params.showMap);
  }, [location]);

  const { suggestions, getSuggestions, getFromCoordinates, setSuggestions } = useSuggestions();

  useEffect(() => {
    if (!defaultValue) {
      setSuggestions(null);
      return;
    }

    getSuggestions(defaultValue.trim());
  }, [defaultValue]);

  const onCancelMap = useCallback(() => {
    const params = parseQueryString(location.search);
    const { showMap, ...restParams } = params;
    const newQueryParams = stringifyQueryObject(restParams);
    history.replace(newQueryParams);
  }, [history]);

  const handleChange = useCallback((item: Suggestion) => {
    const [label, cityLabel] = formatAddress(item.address);
    const params = parseQueryString(location.search);

    const { showMap, ...restParams } = params;
    const newQueryParams = stringifyQueryObject({
      ...restParams,
      lat: item.lat,
      lng: item.lon,
    });
    history.replace(newQueryParams);

    onChange({ ...item, cityLabel, label });
  }, [location.search, history]);

  const handleChangeFromMap = useCallback((item: Suggestion) => {
    handleChange(item);
  }, [handleChange]);

  const handleGetMyPosition = useCallback(() => {
    navigator.geolocation.getCurrentPosition(async ({ coords }) => {
      const res = await getFromCoordinates([coords.latitude, coords.longitude]);

      handleChange(res);
    });
  }, []);

  const handleMapSelectClick = useCallback(() => {
    const params = parseQueryString(location.search);

    if (params.lat && params.lng) {
      const newQueryParams = stringifyQueryObject({
        ...params,
        showMap: true,
      });
      history.push(newQueryParams);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const newQueryParams = stringifyQueryObject({
          ...params,
          showMap: true,
          lat: latitude.toFixed(6),
          lng: longitude.toFixed(6),
        });
        history.push(newQueryParams);
      },
      () => {
        const newQueryParams = stringifyQueryObject({
          ...params,
          showMap: true,
        });
        history.push(newQueryParams);
      },
    );
  }, [location.search, history]);

  const handleSuggestionClick = (item: Suggestion) => {
    switch (item.action) {
      case 'geo':
        handleGetMyPosition();
        break;
      case 'map':
        handleMapSelectClick();
        break;
      default:
        onChange(item);
    }
  };

  return (
    <div
      className={classNames(classes.selectGeolocationWrapper, className, {
        [classes.profile]: variant === 'profile',
      })}
    >
      <Button onClick={onCancel}>
        <ArrowBackIos />
        <Typography>{title}</Typography>
      </Button>

      <List className={classes.selectList}>
        {listItems.map((item) => {
          const currentItem = item as Suggestion;
          const cityLabel = currentItem ? currentItem.cityLabel : '';

          return (
            <ListItem
              data-map-btn={item.key}
              key={item.key}
              button
              onClick={() => handleSuggestionClick(item as Suggestion)}
            >
              <ListItemText data-map-btn='propose-location' primary={t(item.label)} secondary={cityLabel} />
            </ListItem>
          );
        })}

        {Array.isArray(suggestions) && Boolean(suggestions.length) && (
          <>
            <hr />
            {suggestions.map((suggestion) => (
              <ListItem
                data-map-btn={suggestion.osm_id}
                key={suggestion.osm_id}
                button
                onClick={() => handleSuggestionClick(suggestion)}
              >
                <ListItemText
                  data-map-btn='propose-location'
                  primary={t(suggestion.label)}
                  secondary={suggestion.cityLabel}
                />
              </ListItem>
            ))}
          </>
        )}
      </List>

      {isShowingMap && <SelectGeolocationMap onCancel={onCancelMap} onChange={handleChangeFromMap} />}
    </div>
  );
};

export default SelectGeolocation;
