import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  inputContainer: {
    height: 'auto',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  img: {
    float: 'none',
    verticalAlign: 'middle',
    height: 'auto',
    width: '150px',
  },
  button: {
    display: 'block',
    whitespace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 'bold',
    fontStyle: 'inherit',
    textOverflow: 'ellipsis',
    backgroundColor: 'white',
    padding: '10px',
    boxSizing: 'border-box',
  },
  svg: {
    width: '20px',
    height: 'auto',
    margin: '0 10px',
  },
  btns: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  text: {
    padding: '3px',
    margin: '5px',
    fontSize: '14px',
    textAlign: 'end',
  },
  fileInputIconWrapper: {
    justifySelf: 'end',
  },
  fileInputSvg: {
    width: '20px',
    height: 'auto',
    margin: '0 10px',
    color: 'black',
  },
  hideIcon: {
    opacity: 0,
  },
}, { index: 1 });
