import { Box, Avatar } from '@material-ui/core';
import Preloader from 'components/Preloader/Preloader';
import { useMemo, useState } from 'react';
import Modal from 'sharedComponents/Modal';
import { getTranslateFunction, getUserImageLink } from 'helpers';
import type { User } from 'interfaces';
import useStyles from './styles';

type Props = {
  user: User,
  isVerified: boolean,
};

export const BasicAvatar = ({
  user,
  isVerified,
}: Props) => {
  const avatarSrc = useMemo(() => getUserImageLink(user?.userPhoto, user?.gender), [user]);
  const classes = useStyles();
  const t = getTranslateFunction();
  const { userPhoto, userPhotoUpdate, firstName, lastName } = user || {};
  const [isAvatarOpen, setIsAvatarOpen] = useState(false);

  const isUserPhotoExist = Boolean(userPhoto);
  const avatarId = userPhotoUpdate || userPhoto;
  const avatarAlt = `${firstName} ${lastName}`;

  const handleOpenAvatar = () => {
    if (isUserPhotoExist) {
      setIsAvatarOpen(true);
    }
  };

  const shouldShowPreloader = !isVerified && avatarId !== null;

  return (
    <Box>
      <Box className={classes.avatarBox}>
        <div style={{ position: 'relative' }}>
          <Avatar
            className={classes.avatar}
            variant='square'
            src={avatarSrc}
            alt={avatarAlt}
            onClick={handleOpenAvatar}
          />
          {shouldShowPreloader && <Preloader alwaysOnTop={false} />}
        </div>
      </Box>

      <Modal withoutBorder isOpen={isAvatarOpen} onClose={() => setIsAvatarOpen(false)}>
        <Avatar className={classes.avatarFull} variant='square' src={avatarSrc} alt={avatarAlt} />
      </Modal>
    </Box>
  );
};
