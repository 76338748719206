import { makeStyles } from '@material-ui/core/styles';
import {
  backgroundColor,
  grayBG,
  green,
  greenSecondary,
  red,
} from '../../constants';

export default makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  contentWrapper: {
    padding: '0 20px',
  },
  title: {
    margin: '20px 0 ',
    backgroundColor: grayBG,
    padding: '10px 20px',
  },
  warningTitle: {
    color: red,
  },
  sosRouteItem: {
    width: '100%',
    margin: '15px 0',
    display: 'flex',
  },
  sosRouteConfirmed: {
    backgroundColor: green,
    color: green,
    display: 'flex',
    margin: '15px 0',
    width: '100%',
  },
  itemNumber: {
    backgroundColor: grayBG,
    padding: '5px 10px',
    marginRight: '5px',
  },
  textField: {
    backgroundColor: grayBG,
    border: 'none',
    fontSize: '1rem',
    outline: 'none',
    padding: '0 10px',
    width: '100%',
  },
  addBtn: {
    width: '25px',
    height: '25px',
    padding: 0,
    minWidth: '25px',
    marginBottom: '15px',
    backgroundColor: green,
    color: backgroundColor,
    '&:hover': {
      backgroundColor: `${green}60`,
    },
    '& .MuiButton-label': {
      display: 'flex',
    },
  },
  submitBtn: {
    color: green,
    marginBottom: '20px',
    width: '100%',
  },
  removeBtn: {
    backgroundColor: grayBG,
    color: green,
    width: '40px',
  },
  sosIcon: {
    display: 'block',
    width: '4rem',
  },
  sosText: {
    // display: 'block',
    // textAlign: 'center',
    // color: green,
    fontWeight: 'bold',
    lineHeight: '1',
    fontSize: '1.2rem',
    marginTop: '0.5rem'
  },
  sosButton: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'none',
    border: 'none',
    padding: '1.4rem 0 1rem',
    fontSize: '1.6rem',
    width: '100%',
    "&:hover": {
      background: 'none !important',
    },
  },
  sosButtonWrapper: {
    backgroundColor,
    width: '100%',
    margin: '0',
    padding: '10px 0 0',
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    bottom: 0,
  },
  sosLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sosExample: {
    backgroundColor: grayBG,
    margin: '0 20px',
    padding: '.2rem .5rem',
    textAlign: 'center',
    fontSize: '0.7rem',
    lineHeight: '1.2em',
  },
  addIdBtn: {
    backgroundColor: green,
    color: backgroundColor,
    width: '100%',
    marginBottom: '20px',

    '&:hover': {
      backgroundColor: greenSecondary,
    },
  },
  addIdBtnIcon: {
    position: 'absolute',
    right: '0px',
    transform: 'scaleX(1.5)',
    width: '28px',
  },
  becameVolenteer: {
    backgroundColor: green,
    color: backgroundColor,
    width: '100%',
    marginBottom: '20px',
  },
  becameVolenteerGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  becameVolenteerButton: {
    backgroundColor: green,
    color: backgroundColor,
    marginBottom: '0px',
    width: '100%',

    '&:hover': {
      backgroundColor: greenSecondary,
    },
  },
  becameVolenteerCheckbox: {
    marginLeft: '10px',
  },
}, { index: 1 });
