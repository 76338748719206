import { makeStyles } from '@material-ui/core/styles';
import {
  backgroundColor,
  orange,
  green,
  textColor,
  cyan,
  grayBG,
} from '../../constants';

export default makeStyles({
  preorderForm: {
    padding: '0px 5px',
    position: 'relative',
  },
  button: {
    color: 'white',
    backgroundColor: green,
    fontWeight: 'bold',
    padding: '5px 15px',
    width: '100%',
    margin: '5px 0',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonAddPoint: {
    padding: '0',
    margin: '3px 0',
    color: cyan,
    boxShadow: 'none',
    backgroundColor,
    fontWeight: 'bold',
    width: '100%',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor,
    },
  },
  buttonBack: {
    color: 'white',
    backgroundColor: green,
    position: 'absolute',
    bottom: '25px',
    left: '10px',
    zIndex: 1000,
  },
  mainContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  preorderContainer: {
    height: '100%',
  },
  contentContainer: {
    padding: '0',
    width: '100%',
    left: '0',
    top: '65px',
    backgroundColor,
  },
  nav: {
    borderBottomWidth: '0',
    backgroundColor,
    padding: '5px',
    boxShadow: 'none',
    '& .MuiToolbar-gutters': {
      padding: '0',
      minHeight: '0',
    },
  },
  navTitle: {
    flexGrow: 1,
  },
  radioGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  titleRadio: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
  },
  radio: {
    width: '50%',
    margin: '0',
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '10px',
    boxSizing: 'border-box',
    '&.fullWidth': {
      width: '100%',
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: orange,
    },
    '& .MuiRadio-root': {
      padding: '3px',
    },
    '& .Mui-checked': {
      color: orange,
    },
  },
  svg: {
    width: '20px',
    height: 'auto',
    marginRight: '5px',
  },
  input: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '5px 0',
    border: 'none',
  },
  checkboxLabel: {
    margin: '0',
    width: '50%',
    display: 'flex',
    justifyContent: 'space-between',
    '& .Mui-checked': {
      color: orange,
    },
  },
  passengers: {
    display: 'flex',
    alignItems: 'center',
    color: green,
    '& .MuiIconButton-root': {
      color: green,
      padding: '5px',
    },
  },
  passengersText: {
    fontWeight: 'bold',
    backgroundColor: `${textColor}20`,
    padding: '0 5px',
    color: green,
  },
  paymentType: {
    marginLeft: '25px',
    fontSize: '13px',
  },
  checkbox: {
    padding: '3px',
  },
  textfieldLabel: {
    margin: '0',
  },
  textField: {
    width: '120px',
  },
  inputContainer: {
    borderTop: `1px ${textColor} solid`,
    padding: '5px',
    margin: '0',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '&.withoutBorder': {
      border: 'none',
    },
    '& .MuiInputBase-input': {
      padding: '0',
    },
    '&:first-child': {
      border: 'none',
    },
  },
  inputLabel: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '0',
  },
  mapOptionContainer: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    display: ({ selectOnMap }: any) => (selectOnMap ? 'none' : 'block'),
    boxSizing: 'border-box',
    backgroundColor: 'white',
  },
  endAdornPrice: {
    padding: '0 5px',
    color: green,
    backgroundColor: grayBG,
  },
  datePicker: {
    width: '100%',
    fontSize: '13px',
  },
  fullWidth: {
    width: '100%',
  },
  comboboxContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: '5px',
    padding: '5px',
    boxSizing: 'border-box',
  },
  inputFormControl: {
    margin: '5px 0',
    width: '100%',
    '& .MuiFilledInput-root': {
      padding: '5px',
    },
  },
  comboBoxItem: {
    width: '45%',
  },
  autoComplete: {
    '& .MuiFilledInput-root': {
      borderRadius: '0',
      height: '25px',
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
      marginTop: '-2px',
      padding: '0',
    },
    '& .MuiInputLabel-formControl': {
      transform: 'none',
      top: '8px',
      left: '5px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      direction: 'ltr',
      maxWidth: '120px',
      whiteSpace: 'nowrap',
      fontSize: '13px',
    },
  },
}, { index: 1 });
