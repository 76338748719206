import Modal from 'sharedComponents/Modal';
import { Box, Button } from '@material-ui/core';
import classNames from 'classnames';
import { getTranslateFunction } from 'helpers';
import useStyles from './modalStyles';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  message: string;
  withButtons?: boolean;
  variant?: 'default' | 'info';
}

const ModalMessage = ({ isVisible, onConfirm, onClose, message, withButtons, variant = 'default' }: Props) => {
  const t = getTranslateFunction();
  const classes = useStyles();

  return (
    <Modal
      withoutBorder
      withoutPadding={variant === 'info'}
      isOpen={isVisible}
      onClose={onClose}
    >
      <div
        className={classNames({
          [classes.message]: variant === 'default',
          [classes.messageInfo]: variant === 'info',
        })}
      >
        {t(message)}
      </div>

      {withButtons && (
        <Box className={classes.buttonsContainer}>
          <Button color="default" onClick={onConfirm} className={classes.button}>
            {t('modal.button.leave')}
          </Button>
          <Button color="default" onClick={onClose} className={classNames(classes.button, 'green')}>
            {t('modal.button.stay')}
          </Button>
        </Box>
      )}
    </Modal>
  );
};

export default ModalMessage;
