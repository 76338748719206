import { Box, Button, FormHelperText } from '@material-ui/core';
import classNames from 'classnames';
import { Close } from '@material-ui/icons';
import { useCallback, useState } from 'react';
import { getTranslateFunction } from 'helpers';
import useStyles from './styles';

interface Props {
  description: string;
  btnLabel: string;
  isOpen?: boolean;
}

const InfoToggle = ({ description, btnLabel, isOpen = false }: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  const [isShowingDescription, setIsShowingDescription] = useState(() => isOpen);

  const toggleShowingDescription = useCallback(() => {
    setIsShowingDescription((prev) => !prev);
  }, []);

  return (
    <Box
      className={classNames(classes.infoToggleWrapper, {
        [classes.infoToggleWrapperShowing]: isShowingDescription,
      })}
    >
      <FormHelperText
        className={classNames(classes.description, 'MuiFormHelperText-root', 'Mui-error', {
          [classes.textNoWrap]: !isShowingDescription,
          [classes.descriptionShowing]: isShowingDescription,
        })}
      >
        {description}
      </FormHelperText>

      <Button
        onClick={toggleShowingDescription}
        color={isShowingDescription ? 'secondary' : 'primary'}
        variant={isShowingDescription ? 'text' : 'contained'}
        className={classes.infoToggleBtn}
      >
        {isShowingDescription
          ? <Close />
          : <span className={classes.textNoWrap}>{t(btnLabel)}</span>}
      </Button>
    </Box>
  );
};

export default InfoToggle;
