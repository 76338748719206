import { Box, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import Gallery from 'sharedComponents/Gallery/Gallery';
import LocationList from 'components/LocationList';
import { getTranslateFunction } from 'helpers';
import Icons from 'components/Icons';
import { IOrder, Preorder } from 'interfaces';
import { dateFormatter, formatPrice, imagesSrcFormatter } from 'utils/formatters';
import useStyles from './style';
import { cyan } from '../../constants';

type VehicleKeys = 'vehicleBrandOfBuy' |
'vehicleModelOfBuy' |
'vehicleColorOfBuy' |
'releaseDateFromOfBuy' |
'releaseDateToOfBuy' |
'transmissionOfBuy';

interface Props {
  preorder: Preorder | IOrder;
  isBuyMode?: boolean;
}

const {
  CommentTsx,
  InfoTsx,
  fromSvg,
  nowSvg,
  toTimeSvg,
  PhoneTsx,
  ChatTsx,
} = Icons;

const SINGLE_TYPES = ['buyThings', 'rent', 'services'];
const VEHICLE_KEYS: VehicleKeys[] = [
  'vehicleBrandOfBuy',
  'vehicleModelOfBuy',
  'vehicleColorOfBuy',
  'releaseDateFromOfBuy',
  'releaseDateToOfBuy',
  'transmissionOfBuy',
];

const PreorderInfo: React.FC<Props> = ({
  preorder,
  isBuyMode,
}: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  const photoArray = useMemo(() => {
    const { preorderPhotos } = preorder;

    return imagesSrcFormatter(preorderPhotos);
  }, [preorder]);

  const isSingleType = SINGLE_TYPES.some((type) => (
    preorder.tripType === type || preorder.type === type
  ));

  const tripTimeIcon = preorder.tripTime === 'now' ? nowSvg : toTimeSvg;
  const tripTimeText = preorder.tripTime === 'now' ? t('preorder.modal.nowLabel') : dateFormatter(Number(new Date(preorder.tripTime)));
  const withoutAddress = isBuyMode || isSingleType;

  return (
    <Box className={classes.preorderInfo}>
      <Box className={classes.infoAboutCarPreorder}>
        {VEHICLE_KEYS.map((key: VehicleKeys) => {
          const label = preorder[key]?.label;
          if (!label) return null;

          return (
            <Typography key={key}>{label}</Typography>
          );
        }).filter(Boolean)}
      </Box>
      {preorder.tripRequirements && (
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <img
            className={classes.svg}
            src={fromSvg}
            alt={preorder.together}
          />
          <Typography className={classes.listItemLabel}>{preorder.tripRequirements}</Typography>
        </Box>
      </Box>
      )}
      {preorder.preorderType && (
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <Box className={classes.svg} />
          <Typography className={classes.listItemLabel}>{t(`preorder.modal.${preorder.preorderType}`)}</Typography>
        </Box>
      </Box>
      )}
      {preorder.preorderName && (
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <img
            className={classes.svg}
            src={fromSvg}
            alt={preorder.together}
          />
          <Typography className={classes.listItemLabel}>{preorder.preorderName}</Typography>
        </Box>
      </Box>
      )}
      {preorder.comment && (
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <Box
            className={classes.svg}
          >
            <CommentTsx color={cyan} />
          </Box>
          <Typography className={classes.listItemLabel}>{preorder.comment}</Typography>
        </Box>
      </Box>
      )}
      {Boolean(photoArray.length) && (
        <Box className={classes.preorderGalleryBox}>
          <Gallery
            images={photoArray}
          />
        </Box>
      )}
      {preorder.findInAllCities && (
      <>
        <Box className={classes.preorderOption}>
          <Box className={classes.listItem}>
            <Box className={classes.svg} />
            <Typography
              variant="caption"
              color="primary"
            >
              {t('preorderForm.whereAreYouLooking')}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.preorderOption}>
          <Box className={classes.listItem}>
            <Box className={classes.svg} />
            <Typography className={classes.listItemLabel}>{t('preorder.modal.findInAllCities')}</Typography>
          </Box>
        </Box>
      </>
      )}
      {preorder.showPhone && (
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <Box
            className={classes.svg}
          >
            <PhoneTsx color={cyan} />
          </Box>
          <Typography className={classes.listItemLabel}>{t('preorder.modal.showPhone')}</Typography>
        </Box>
      </Box>
      )}
      {preorder.withChat && (
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <Box
            className={classes.svg}
          >
            <ChatTsx color={cyan} />
          </Box>
          <Typography className={classes.listItemLabel}>{t('preorder.modal.withChat')}</Typography>
        </Box>
      </Box>
      )}
      {preorder.together && (
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <img
            className={classes.svg}
            src={Icons[`${preorder.together}Svg`]}
            alt={preorder.together}
          />
          <Typography className={classes.listItemLabel}>{t(`preorder.modal.${preorder.together}Label`)}</Typography>
        </Box>
      </Box>
      )}
      {preorder.carClass && (
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <img
            className={classes.svg}
            src={Icons[`${preorder.carClass}Svg`]}
            alt={preorder.together}
          />
          <Typography className={classes.listItemLabel}>{t(`preorder.modal.${preorder.carClass}`)}</Typography>
        </Box>
      </Box>
      )}
      {preorder.passengers && (
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <img
            className={classes.svg}
            src={Icons.individualSvg}
            alt="together"
          />
          <Typography className={classes.listItemLabel}>{t('preorder.modal.passengers')}</Typography>
          <Typography className={classes.endAdornment}>{preorder.passengers}</Typography>
        </Box>
      </Box>
      )}
      {preorder.baggage && (
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <img
            className={classes.svg}
            src={Icons.baggageSvg}
            alt="passengers"
          />
          <Typography className={classes.listItemLabel}>{t('preorder.modal.baggage')}</Typography>
        </Box>
      </Box>
      )}
      {preorder.hasBabyChair && (
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <img
            className={classes.svg}
            src={Icons.hasBabyChairSvg}
            alt={t('preorder.modal.babyChair')}
          />
          <Typography className={classes.listItemLabel}>{t('preorder.modal.babyChair')}</Typography>
        </Box>
      </Box>
      )}
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <img
            className={classes.svg}
            src={tripTimeIcon}
            alt="tripTime"
          />
          <Typography className={classes.listItemLabel}>{tripTimeText}</Typography>
        </Box>
      </Box>
      {preorder.buyerCity && (
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <Box className={classes.svg}>
            <InfoTsx color={cyan} width={20} height={20} />
          </Box>
          <Typography className={classes.listItemLabel}>{preorder.buyerCity}</Typography>
        </Box>
      </Box>
      )}
      {!withoutAddress && (
        <LocationList
          dots={preorder.dots}
          classes={{
            dotBox: classes.listItemDotsAddress,
            svg: classes.svg,
          }}
          withIcon
        />
      )}
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <img
            className={classes.svg}
            src={Icons[`${preorder.paymentType}Svg`]}
            alt="paymentType"
          />
          <Typography className={classes.listItemLabel}>{t(`preorder.modal.${preorder.paymentType}`)}</Typography>
          <Typography className={classes.endAdornment}>{formatPrice(preorder.price)}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PreorderInfo;

PreorderInfo.defaultProps = {
  isBuyMode: false,
};
