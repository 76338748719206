import { makeStyles } from '@material-ui/core/styles';
import {
  backgroundColor,
  grayBG,
  green,
  greenSecondary,
  orange,
  textColor,
} from '../../constants';

export default makeStyles({
  createProjectsContainer: {
    marginBottom: '50px',
    padding: '0 10px',
  },
  container: {
    padding: '10px 0',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  headerContainer: {
    width: '100%',
    padding: '5px',
    backgroundColor: greenSecondary,
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: '0.5s',
    '&.open': {
      backgroundColor: green,
    },
  },
  endAdornment: {
    display: 'flex',
    marginLeft: '5px',
    padding: '5px',
  },
  arrow: {
    color: backgroundColor,
    lineHeight: '0',
    transition: '.5s',
    '&.open': {
      transform: 'rotateX(180deg)',
    },
  },
  boxContent: {
    maxHeight: 0,
    transition: 'max-height .5s ease-out',
    overflow: 'hidden',
    '&.open': {
      maxHeight: 'none',
      transition: 'max-height .5s ease-in',
    },
  },
  preorderCount: {
    color: 'white',
    float: 'right',
  },
  preorderContainer: {
    display: 'flex',
    position: 'relative',
    backgroundColor: grayBG,
    marginTop: '10px',
  },
  offerInfo: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
  },
  userContainer: {
    width: '30%',
    height: '100%',
    padding: '5px 0',
    boxSizing: 'border-box',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRight: `2px ${textColor} solid`,
    '& .MuiTypography-root': {
      fontSize: '13px',
    },
  },
  preorderTitle: {
    color: backgroundColor,
  },
  textField: {
    width: '100%',
    '& .MuiInputBase-input': {
      fontSize: '13px',
      padding: '5px',
    },
    '& .MuiInputBase-multiline': {
      padding: '0',
    },
  },
  stats: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
  svg: {
    width: '20px',
    height: 'auto',
    margin: '5px',
    '&.star': {
      color: orange,
    },
  },
  userImg: {
    minWidth: '50px',
    minHeight: '50px',
  },
  proposal: {
    maxHeight: '100%',
    maxWidth: '60%',
    padding: '5px 10px',
    fontSize: '13px',
  },
  address: {
    marginBottom: '4px',
  },
  preorderOption: {
    color: textColor,
    '& .MuiTypography-body2': {
      fontWeight: 'bold',
      fontSize: '13px',
    },
  },
  typeContainer: {
    backgroundColor: grayBG,
    padding: '10px',
    height: '70px',
    display: 'flex',
    margin: '10px 0',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    '& .MuiTypography-root': {
      fontSize: '16px',
    },
    '&:nth-child(2n+1)': {
      backgroundColor: grayBG,
    },
  },
  typeContainerTitle: {
    justifyContent: 'center',
  },
  transparent: {
    opacity: '50%',
  },
  arrowBack: {
    position: 'absolute',
    left: '10px',
  },
  typeSvg: {
    width: '80px',
    height: '100%',
  },
  title: {
    textAlign: 'center',
    backgroundColor: grayBG,
    fontWeight: 'bold',
    margin: '10px 0',
  },
  preorderContainerBox: {
    marginTop: '10px',
    backgroundColor: grayBG,
    position: 'relative',
    marginBottom: '20px',
    borderBottom: `2px ${orange} solid`,
    borderTop: `2px ${orange} solid`,
  },
}, { index: 1 });
