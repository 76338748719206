import { makeStyles } from '@material-ui/core/styles';
import { backgroundColor, red } from '../../constants';

export default makeStyles({
  removeBtn: {
    color: backgroundColor,
    fontWeight: 'bold',
    padding: '0 15px',
    margin: '5px',
  },
  inputGeo: {
    width: '100%',
    marginTop: '5px',
    backgroundColor,
    '& .MuiFilledInput-input': {
      padding: '5px',
    },
    '& .MuiFilledInput-root': {
      borderRadius: '0',
    },
    '& .MuiFilledInput-input.MuiInputBase-input': {
      padding: '12px 5px',
    },
  },
  svg: {
    width: '20px',
    height: '20px ',
    marginRight: '5px',
  },
  selectList: {
    padding: '0',
    backgroundColor,
  },
  input: {
    width: '100%',
  },
  activeInput: {
    paddingTop: '10px',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    backgroundColor,
    zIndex: 1,
    boxSizing: 'border-box',
  },
  addressUnavaiableBox: {
    paddingLeft: '16px',
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
    color: red,
  },
  addressUnavaiableText: {
    marginLeft: '5px',
    color: red,
  },
}, { index: 1 });
