import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  avatarBox: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
  },
  avatar: {
    width: '150px',
    height: '150px',
  },
  avatarFull: {
    width: '100%',
    height: '100%',
  },
}), { index: 1 });
