import {
  ConfiguredFile,
  configureFilesObject,
  confugureFilesBeforeSend,
  dataURLtoFile,
  FileObject,
  hashFiles,
} from 'helpers';
import {
  FileResponse,
  HashedFile,
  UserToCreate,
  User,
  UserFormData,
  VendorFormData,
  ClientFormDataSignUpDefaultValues,
  VendorFormDataSignUpDefaultValues,
} from 'interfaces';
import { sendFile } from 'services/commonService';

export const FILES_PROPERTIES = [
  'userPhoto',
  'passportFirstPagePhoto',
  'passportAndFacePhoto',
];

export const toDTO = (
  data: UserFormData,
  files: FileObject[],
  hashes: HashedFile[],
): UserToCreate => {
  const res = hashes.reduce((
    acc: Record<string, HashedFile>,
    response: HashedFile,
    index: number,
  ) => {
    const { field } = files[index];
    return {
      ...acc,
      [field]: response,
    };
  }, {});

  const send = {
    ...data,
    ...res,
    city: data.city.label,
    country: data.country.label,
    address: data.placeOfResidence,
  };

  return send;
};

export const configureUser = async (data: UserFormData)
: Promise<{
  formattedUser: UserToCreate;
  files: FileObject[];
  hashedFiles: HashedFile[];
}> => {
  const files = await configureFilesObject(FILES_PROPERTIES, data);
  const hashedFiles = await hashFiles(files);

  const formattedUser = toDTO(data, files, hashedFiles);

  return {
    formattedUser,
    files,
    hashedFiles,
  };
};

export const sendFiles = async (
  files: FileObject[],
  hashedFiles: HashedFile[],
  data: User,
  endpoint: string,
): Promise<FileResponse[]> => {
  const formattedFiles = confugureFilesBeforeSend(files, hashedFiles, data);

  const promises = formattedFiles.map(({ data: formData, id }: ConfiguredFile) => (
    sendFile(formData, endpoint, id)
  ));

  return Promise.all(promises);
};

export const submitter = async (
  data: UserFormData,
  signup: (a: UserToCreate) => Promise<User>,
): Promise<User> => {
  const {
    formattedUser,
    files,
    hashedFiles,
  } = await configureUser(data);

  const createdUser = await signup(formattedUser);

  await sendFiles(files, hashedFiles, createdUser, 'users');
  return createdUser;
};

export const formatIndexedDbData = <T extends UserFormData | VendorFormData>(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: [keyof T, any],
  initialData: ClientFormDataSignUpDefaultValues | VendorFormDataSignUpDefaultValues,
): T => {
  const result = data.reduce((acc, [key, value]) => {
    if (!value) return acc;

    if (typeof value === 'object' && value.type === 'file') {
      const file = dataURLtoFile(value.base64, key);

      const dt = new DataTransfer();
      dt.items.add(file);
      const fileList = dt.files;

      acc[key] = fileList;
    } else {
      acc[key] = value;
    }

    return acc;
  }, initialData);

  return result;
};
