import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Box, Typography } from '@material-ui/core';
import { ArrowDownward } from '@material-ui/icons';
import classNames from 'classnames';
import Modal from 'sharedComponents/Modal';
import { checkIsStringIncludes, getTranslateFunction, getUniqueItemsPrimitives } from 'helpers';
import {
  Preorder,
  IOnChange,
  IDistance,
  PreorderSubType,
} from 'interfaces';
import Icons from 'components/Icons';
import { buttons } from 'utils/optionsConfig';
import { useAuth } from 'services/auth';
import { useModal } from 'services/customHooks';
import modalStyles from 'sharedComponents/Modal/modalStyles';
import PreorderListItem from '../components/PreorderListItem';
import useStyles from '../styles';

const icons: {
  [key: string]: string;
} = {
  'along-the-way': Icons.alongTheWay,
  'women-driver': Icons.womenDriver,
  'temporary-driver': Icons.temporaryDriver,
  'animal-transport': Icons.animalTransport,
  'another-transport': Icons.anotherTransport,
  'freight-transport': Icons.freightTransport,

  'food-delivery': Icons.foodDelivery,
  'products-delivery': Icons.productsDelivery,
  'documents-delivery': Icons.documentsDelivery,
  'flowers-delivery': Icons.flowersDelivery,
  'medicines-delivery': Icons.medicinesDelivery,
  another: Icons.anotherDelivery,

  'buy-property': Icons.propertyBuy,
  'buy-transport': Icons.transportBuy,
};

interface IAllPreorder {
  [key: string]: Preorder[];
}

type Props = {
  initialPreorders: Preorder[];
  canceledPreorders: number[];
  searchInputValue: null | string;
  together: boolean;
  distances: IDistance[];
  currentType: string;
  currentChildType: string;
  preorderType: PreorderSubType;
  onChange: (a: IOnChange) => void;
  setCurrentChildType: Dispatch<SetStateAction<string>>;
};

const PreordersList: React.FC<Props> = ({
  initialPreorders,
  canceledPreorders,
  searchInputValue,
  together,
  distances,
  currentType,
  preorderType,
  currentChildType,
  onChange,
  setCurrentChildType,
}: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();
  const { isModalVisible, showModal, hideModal } = useModal();
  const modalClasses = modalStyles();

  const countPreorders = useMemo(() => (
    initialPreorders.filter((preorder) => (
      !canceledPreorders.includes(preorder.preorderId)
    )).length
  ), [initialPreorders.length, canceledPreorders]);

  const types: IAllPreorder = initialPreorders.reduce((
    acc: IAllPreorder,
    preorder: Preorder,
    _: number,
    array: Preorder[],
  ) => {
    const key = preorder[preorderType];
    const newValue = array.filter((item) => item[preorderType].includes(key));

    acc[key] = newValue;
    return acc;
  }, {});

  const preorders = useMemo(() => {
    if (!currentChildType || !types[currentChildType]) return [];
    return types[currentChildType];
  }, [types, currentChildType]);

  const handleBox = () => setIsOpen((prev) => {
    if (prev) {
      setCurrentChildType('');
    }
    return !prev;
  });

  const handleClickTypePreorders = useCallback((array, type) => {
    setCurrentChildType(type);
  }, []);

  const IconSvg = Icons[`${preorderType}Tsx`];

  const allSubServicesByType = useMemo(
    () => getUniqueItemsPrimitives(
      buttons
        .filter(
          ({ type, service }) => {
            return type === currentType && preorderType === service;
          },
        )
        .map(({ contentKey }) => contentKey),
    ),
    [currentType, preorderType],
  );

  return (
    <Box className={classes.container} key={preorderType}>
      <Box
        className={classNames(classes.headerContainer, { open: isOpen })}
        onClick={handleBox}
      >
        <Box className={classes.svg}>{IconSvg && <IconSvg color="#fff" />}</Box>
        <Typography className={classes.preorderTitle}>
          {t(preorderType)}
        </Typography>
        <Box className={classes.endAdornment}>
          <Typography className={classes.preorderCount}>
            {Math.max(countPreorders, 0)}
          </Typography>
          <Box className={classNames(classes.arrow, { open: isOpen })}>
            <ArrowDownward />
          </Box>
        </Box>
      </Box>

      <Box className={classNames(classes.boxContent, { open: isOpen })}>
        {preorders.length ? (
          <Box>
            {preorders.map((item: Preorder) => {
              const isCanceled = canceledPreorders.includes(item.preorderId);
              const isTogether = together && item.together === 'individual';
              const isSearchApplied = searchInputValue
                ? checkIsStringIncludes([item.preorderName, item.comment], searchInputValue) : true;

              if (isCanceled || isTogether || !isSearchApplied) return null;
              const distanceTo = distances.find(({ id }: IDistance) => item.preorderId === id);
              const distance = Math.floor(item.distance) / 1000;
              const distanceToYou = distanceTo ? Math.floor(distanceTo.distance) / 1000 : null;

              return (
                <PreorderListItem
                  key={item.preorderId}
                  onChange={onChange}
                  distance={distance}
                  distanceToYou={distanceToYou}
                  item={item}
                />
              );
            })}
          </Box>
        ) : (
          <Box>
            {allSubServicesByType.map((key) => {
              const typePreorders = types[key] ?? [];
              const formattedKey = preorderType.replace(
                /[A-Z]/g,
                (letter: string) => `-${letter.toLowerCase()}`,
              );

              const numberPreorders = typePreorders
                .filter((preorder: Preorder) => !canceledPreorders.includes(preorder.preorderId))
                .length;
              const newTitle = key.replace(`${formattedKey}-`, '');

              const isGenderMale = user?.gender === 'male' && key.includes('women');

              return (
                <Box
                  key={key}
                  className={classNames(classes.typeContainer, { [classes.transparent]: isGenderMale })}
                  onClick={isGenderMale ? () => showModal() : () => handleClickTypePreorders(typePreorders, key)}
                >
                  <img
                    className={classes.typeSvg}
                    src={icons[newTitle]}
                    alt={key}
                  />
                  <Typography>{t(key)}</Typography>
                  <Typography>{numberPreorders}</Typography>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>

      <Modal withoutBorder withoutPadding isOpen={isModalVisible} onClose={hideModal}>
        <div className={modalClasses.styledModal}>{t('for.women.only')}</div>
      </Modal>
    </Box>
  );
};

export default PreordersList;
