import { makeStyles } from '@material-ui/core/styles';
import {
  grayBG,
} from '../../constants';

export default makeStyles((theme) => ({
  titleName: {
    margin: '0 20px',
  },
  optionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 10px 10px',
    justifyContent: 'space-between',
    height: 'calc(100% - 56px)',
    alignContent: 'space-between',
    gap: '10px',

    '@media (max-height: 740px)': {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '0 10px',
      gap: 0,
      justifyContent: 'space-between',
      height: 'auto',
      alignContent: 'space-between',
      minHeight: '450px',
    },

    [theme.breakpoints.up('sm')]: {
      gap: '12px',
    },
    [theme.breakpoints.up('md')]: {
      gap: '15px',
    },
  },
  optionLinkContainer: {
    padding: '5px',
    boxSizing: 'border-box',
    width: 'calc(50% - 5px)',
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'calc((100% - 20px) / 3)',
    minHeight: '100px',
    '@media (max-height: 740px)': {
      height: 'auto',
    },
    '@media (min-height: 600px)': {
      minHeight: '120px',
    },
    '&:nth-last-child(-n+2)': {
      marginBottom: '0',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'calc(33.33% - 8px)',
      padding: '8px',
      height: 'calc((100% - 24px) / 3)',
      '&:nth-last-child(-n+3)': {
        marginBottom: '0',
      },
    },
    [theme.breakpoints.up('md')]: {
      width: 'calc(25% - 12px)',
      padding: '10px',
      height: 'calc((100% - 30px) / 3)',
      '&:nth-last-child(-n+4)': {
        marginBottom: '0',
      },
    },
    [theme.breakpoints.up('lg')]: {
      width: 'calc(16.66% - 13px)',
      padding: '12px',
    },
  },
  optionLink: {
    backgroundColor: grayBG,
    textAlign: 'center',
    padding: '5px 10px',
    fontWeight: 'bold',
    textDecoration: 'none',
    marginTop: '10px',
    height: 'auto',
    minHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1rem',
      minHeight: '40px',
      padding: '8px 15px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.2rem',
      minHeight: '45px',
      padding: '10px 15px',
    },
  },
  infoToggleContainer: {
    padding: '10px 15px 0',
  },
  badge: {
    position: 'absolute',
    top: '-4px',
    right: '-14px',
  },
}), { index: 1 });
