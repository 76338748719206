import i18next from 'i18next';
import { getMonths, getOrderImageLink, getUniqueItemsObjects } from 'helpers';
import { IMessage, IOrder, Preorder } from 'interfaces';

export const isInt = (n: number): boolean => !(n % 1);

const TIME = [3600, 60, 1];
export const ZERO_VALUE = '00:00';

export const formatMsToSeconds = (timeInSecond: number): string => {
  const isTimeLeftModalShown = timeInSecond > 0;

  if (!isTimeLeftModalShown) {
    return ZERO_VALUE;
  }

  let timeLeft = timeInSecond;

  const resultArr = TIME.map((value) => {
    const timeItem = Math.floor(timeLeft / value);
    const isLessThanTen = timeItem < 10 && timeItem >= 0;

    const resultSeconds = isLessThanTen
      ? `0${timeItem}`
      : timeItem;

    timeLeft -= Number(resultSeconds) * value;
    return resultSeconds;
  });

  if (!Number(resultArr[0])) resultArr.shift();
  return resultArr.join(':');
};

export const timeLeft = (startTime: number, finishTime: number): number => {
  const different = finishTime - startTime;
  return Math.max(0, Math.floor(different));
};

export const dateRegisterFormatter = (date: Date): string => {
  const year = date.getFullYear() - 18;
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const month = m < 10 ? `0${m}` : m;
  const day = d < 10 ? `0${d}` : d;

  return `${year}-${month}-${day}`;
};

export const nowDateFormatter = (date: Date): string => {
  const year = date.getFullYear();
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const month = m < 10 ? `0${m}` : m;
  const day = d < 10 ? `0${d}` : d;

  return `${year}-${month}-${day}`;
};

export const dateFormatter = (time: number | string | Date): string => {
  const dateNow = new Date();
  const nowNumberDate = dateNow.getDate();
  const months = getMonths(i18next.language);
  const orderTime = typeof time === 'object' ? time : new Date(time);
  const monthIndex = orderTime.getMonth();
  const yearIndex = orderTime.getFullYear();
  const day = orderTime.getDate();
  const normalDay = day < 10 ? `0${day}` : day;
  const hours = orderTime.getHours();
  const minutes = orderTime.getMinutes();
  const normalMinutes = minutes < 10 ? `0${minutes}` : minutes;

  const isThisMonth = monthIndex === dateNow.getMonth();
  const isThisYear = yearIndex === dateNow.getFullYear();
  const isThisDay = nowNumberDate === day && isThisMonth && isThisYear;

  return isThisDay ? `${hours}:${normalMinutes}` : `${normalDay} ${months[monthIndex]}/ ${hours}:${normalMinutes}`;
};

export const formatPrice = (price: number | string): string => {
  return Number(price).toFixed(2);
};

export const currencyFormatter = (amount: number, currency: string): string => {
  const formattedAmount = isInt(amount) ? amount : amount.toFixed(2);

  const stringifyAmount: string = formattedAmount.toString();

  const formatter = (arg0: string, arg1: string) => {
    return `${arg0} ${arg1}`;
  };

  return formatter(stringifyAmount, currency);
};

interface ImageSrc {
  src: string;
  title: string;
}

export const imagesSrcFormatter = (photos:
number | number[] | string | string[] | undefined): ImageSrc[] => {
  if (!photos) return [];
  const resultArr = Array.isArray(photos) ? photos : [photos];

  return (
    resultArr.map((link: string | number) => {
      const src = typeof link === 'number' ? getOrderImageLink(link) : link;
      return ({
        src,
        title: '',
      });
    }));
};

export const camelCaseToHyphens = (str: string): string => (
  str.replace(/([A-Z])/g, (l) => (`-${l.toLowerCase()}`))
);

export const hyphensToCamelCase = (str: string): string => (
  str.replace(/-([a-z])/g, (g) => g[1].toUpperCase())
);

export const formatOrderClass = (orderType: string, classType: string): string => {
  const orderTypeHyphens = camelCaseToHyphens(orderType);
  const classFiltered = classType.replace(`${orderTypeHyphens}-`, '');
  const classToCamelCase = hyphensToCamelCase(classFiltered);

  return classToCamelCase;
};

export const parseQueryString = (queryString: string): {
  [key: string]: string
} => queryString.replace('?', '')
  .split('&')
  .map((val) => val.split('='))
  .reduce((acc, [key, value]) => {
    if (!key) return acc;
    return {
      ...acc,
      [key]: value,
    };
  }, {});

export const stringifyQueryObject = (data: {
  [key: string]: string | number | boolean
}): string => (
  Object.entries(data)
    .filter(([key]) => key)
    .reduce((acc, [key, value], index, arr) => {
      const isLast = index === arr.length - 1;
      const lastSymbol = isLast ? '' : '&';
      const newValue = `${key}=${value}${lastSymbol}`;

      return `${acc}${newValue}`;
    }, '?')
);

export const formatMessage = (template: string, data: Record<string, string | number>): string => {
  if (!data) return template;
  return Object.entries(data)
    .filter(([key, value]) => key && value != null)
    .reduce((agg, [key, value]) => (
      agg.replaceAll(`$\{${key}}`, value.toString())
    ), template);
};

const unusedProp = [
  'status',
  'serviceId',
  'createdAt',
  'updatedAt',
  'preorderServiceId',
  'preorderId',
  'tripType',
  'id',
  'key',
];

export const formatOrderToPreorder = (order: IOrder): Preorder => {
  const formattedOrder = Object.entries(order).reduce((acc, [key, value]) => {
    const isUnusedProperty = unusedProp.includes(key);

    if (!value || isUnusedProperty) return acc;
    return {
      ...acc,
      [key]: value,
    };
  }, { type: order.tripType }) as Preorder;

  return formattedOrder;
};

export const isJson = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const filterDataWithoutNull = (data: Record<string, unknown>)
: Record<string, unknown> => {
  const result = Object.entries(data).reduce((acc, [key, val]) => {
    const isNullable = val === null || val === undefined;
    if (isNullable) return acc;

    return {
      ...acc,
      [key]: val,
    };
  }, {});

  return result;
};

export const formatMessagesToChat = (messages: IMessage[]): [string, IMessage[]][] => {
  const sortedByDaysMessages = getUniqueItemsObjects<IMessage>(messages, '_id')
    .reduce(
      (acc: { [key: string]: IMessage[] }, item: IMessage) => {
        const [messageDate] = item.createdAt.split('T');

        const accMessages = acc[messageDate];
        const newMessages = accMessages && accMessages.length ? [...accMessages, item] : [item];

        return {
          ...acc,
          [messageDate]: newMessages,
        };
      },
      {},
    );

  const sortedMessages = Object.entries(sortedByDaysMessages)
    .sort(([a], [b]) => {
      const timeA = new Date(a).getTime();
      const timeB = new Date(b).getTime();

      return timeA-timeB ? -1 : 1;
    });

  return sortedMessages;
};
