import { makeStyles } from '@material-ui/core';
import { red, cyan } from '../../constants';

export default makeStyles({
  timer: {
    border: `2px ${cyan} solid`,
    color: cyan,
    padding: '6px 8px',

    fontFamily: 'monospace',
    textAlign: 'center',
    display: 'inline-block',

    '&.ended': {
      animation: '$timerLeft 2000ms infinite',
    },
  },
  '@keyframes timerLeft': {
    '50%': {
      border: `2px ${red} solid`,
      color: red,
    },
  },
}, {
  index: 1,
});
