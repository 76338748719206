import { sortArrayBySortIndex } from 'helpers';
import { ListItem } from 'interfaces';

export const generateNavigationItems = (isVendor: boolean): ListItem[] => {
  const projectsLink = isVendor ? '/projects-subscribe' : '/projects';

  return sortArrayBySortIndex([
    {
      label: 'navigation.profile',
      info: { type: 'navigate' },
      icon: 'profileIcon',
      to: '/profile',
      sortIndex: 1,
    },
    {
      label: 'navigation.active-orders',
      info: { type: 'navigate' },
      icon: 'ordersIcon',
      to: '/check-active-orders',
      sortIndex: 4,
    },
    {
      label: 'navigation.promotions-and-gifts',
      info: { type: 'navigate' },
      icon: 'giftsIcon',
      to: '/promotions-and-gifts',
      sortIndex: 5,
    },
    {
      label: 'navigation.how-project-work',
      info: { type: 'navigate' },
      icon: 'infoIcon',
      to: '/how-project-work',
      sortIndex: 6,
    },
    {
      label: 'navigation.projects',
      info: { type: 'navigate' },
      icon: 'projectsIcon',
      to: projectsLink,
      sortIndex: 2,
    },
    {
      label: 'navigation.vendors-list',
      info: { type: 'navigate' },
      icon: 'YourTransport',
      to: '/vendors-list',
      sortIndex: 3,
    },
    {
      label: 'navigation.settings',
      info: { type: 'navigate' },
      icon: 'settingsIcon',
      to: '/settings',
      sortIndex: 7,
    },
    {
      label: 'navigation.share',
      info: { type: 'navigate' },
      icon: 'shareIcon',
      to: '/share',
      sortIndex: 8,
    },
    {
      label: 'navigation.security',
      info: { type: 'navigate' },
      icon: 'securityIcon',
      colorType: 'red',
      to: '/security',
      sortIndex: 9,
    },
    {
      label: 'navigation.support',
      info: { type: 'navigate' },
      icon: 'supportIcon',
      to: '/chat/support',
      sortIndex: 10,
    },
    {
      label: 'navigation.lang',
      info: { type: 'langBtn' },
      icon: 'languageIcon',
      sortIndex: 11,
    },
  ]);
};
