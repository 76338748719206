import React, { useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppInfoTextOnly } from 'hooks/appInfo';
import { IOnChange, OrderType } from 'interfaces';
import { useAuth } from 'services/auth';
import {
  filteredButtons,
  filteredOptions,
  HomepageButton,
} from 'utils/optionsConfig';
import { useLayout } from 'context/layout';
import { getTranslateFunction } from 'helpers';
import { updateVendor } from 'services/commonService';
import DropdownOptionsComponent, { ButtonItem } from '../components/DropdownOptionsComponent';
import { configUrlByTypes } from '../helper';

const POSITIONS = [
  'menuExecutorProjectsTop',
];

const DropdownOptions: React.FC = () => {
  const { vendor, setVendors } = useAuth();
  const history = useHistory();
  const params = useParams<{ [prop: string]: string }>();
  const { setTitle } = useLayout();
  const t = getTranslateFunction();
  const innerType = params.id as OrderType;
  const appInfo = useAppInfoTextOnly(POSITIONS);

  const handleRoute = (to: string) => history.push(to);

  const updateVendorCategories = useCallback(async (type: string, queryParams: string) => {
    if (!vendor) return;

    try {
      const categoryData: { [key: string]: string[] } = {};

      const parsedParams = queryParams.split('&');
      parsedParams.forEach((param) => {
        const [key, valueWithBrackets] = param.split('=');
        if (key && valueWithBrackets) {
          const valueString = valueWithBrackets.substring(1, valueWithBrackets.length - 1);
          const values = valueString.split(',');
          if (values.length > 0) {
            categoryData[key] = values;
          }
        }
      });

      const updateData = {
        [type]: categoryData,
      };

      const [updatedVendor] = await updateVendor(vendor.id, {
        ...updateData,
        userId: vendor.userId,
      });

      setVendors((prev) => prev.map((v) => (v.id === updatedVendor.id ? updatedVendor : v)));

      toast.success(t('common.saved'));
    } catch (error) {
      toast.error(t('common.error'));
    }
  }, [vendor, setVendors]);

  const handleCheckItem = ({ checked, currentType }: {
    checked: HomepageButton[];
    currentType: string;
  }) => {
    if (!checked) return;

    if (vendor) {
      const [type, result] = configUrlByTypes(history.location.pathname, checked);

      updateVendorCategories(type, result);
      return;
    }

    const items = checked;
    const [currentItem] = items;
    const query = items.map((innerItem: ButtonItem) => `${innerItem.service}=[${innerItem.contentKey}]`);

    const to = filteredButtons.includes(currentItem.type)
    || filteredOptions.includes(currentItem.type)
      ? `/preorder/${currentType}?${query}`
      : '/';

    handleRoute(to);
  };

  const handleChange = ({ data, action }: IOnChange) => {
    switch (action) {
      case 'checkItem': {
        handleCheckItem(data);
        break;
      }
      case 'historyPush': {
        history.push(data);
        break;
      }
      default: break;
    }
  };

  useEffect(() => {
    setTitle(t(innerType));

    return () => {
      setTitle('');
    };
  }, [innerType]);

  return (
    <DropdownOptionsComponent
      currentType={innerType}
      menuExecutorProjectsTop={appInfo.menuExecutorProjectsTop}
      onChange={handleChange}
      vendor={vendor}
    />
  );
};

export default DropdownOptions;
