import { Box, Button, FormHelperText } from '@material-ui/core';
import classNames from 'classnames';
import { Close } from '@material-ui/icons';
import { ReactElement, useCallback, useState } from 'react';
import { getTranslateFunction } from 'helpers';
import useStyles from './styles';

interface Props {
  btnLabel: string;
  description: ReactElement|string;
  isButtonColorError?: boolean;
  isCollapsedTextColorBase?: boolean;
}

const InfoToggleModal = ({
  btnLabel,
  description,
  isButtonColorError,
  isCollapsedTextColorBase,
}: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  const [isShowingDescription, setIsShowingDescription] = useState(false);

  const toggleShowingDescription = useCallback(() => {
    setIsShowingDescription((prev: boolean) => !prev);
  }, []);

  const colorClass = isCollapsedTextColorBase ? 'Mui-success' : 'Mui-error';
  const buttonClolrClass = isButtonColorError ? 'secondary' : 'primary';

  return (
    <Box
      className={classNames(classes.infoToggleWrapper, {
        [classes.infoToggleWrapperShowing]: isShowingDescription,
      })}
    >
      <FormHelperText
        className={classNames(classes.description, 'MuiFormHelperText-root', colorClass, {
          [classes.textNoWrap]: !isShowingDescription,
          [classes.descriptionShowing]: isShowingDescription,
        })}
        dangerouslySetInnerHTML={{ __html: description as string }}
      />

      <Button
        id="infoToggleBtn"
        onClick={toggleShowingDescription}
        color={isShowingDescription ? 'secondary' : buttonClolrClass}
        variant={isShowingDescription ? 'text' : 'contained'}
        className={classNames(classes.infoToggleBtn, {[classes.closeButtonOpen]: isShowingDescription})}
      >
        {isShowingDescription
          ? <Close />
          : <span className={classes.textNoWrap}>{t(btnLabel)}</span>}
      </Button>
    </Box>
  );
};

export default InfoToggleModal;
