import {
  useContext, useState, useEffect, createContext, Dispatch, SetStateAction,
} from 'react';
import { unsubscribeNotification } from 'helpers';
import { IUsePreordersResult, usePreorders } from 'hooks/preorders';
import i18n from 'i18n';
import {
  UserToCreate, User, Preorder, Vendor, ICurrentIp, TLocation,
} from 'interfaces';
import { useMyPosition } from 'hooks/geolocation';
import socket from '../socket';
import { getMyVendors } from './commonService';
import countriesList from './countriesList';
import RequestService from './requestApi';

export type AuthProvider = IUsePreordersResult & {
  user: User;
  vendor: Vendor | null;
  vendors: Vendor[];
  vendorId: number | null;
  vendorUuid: string | null;
  preorders: Preorder[];
  isLoading: boolean;
  currentIp: ICurrentIp | null;
  geolocation: TLocation;
  setCurrentIp: Dispatch<SetStateAction<ICurrentIp | null>>;
  signup: (data: UserToCreate) => Promise<User[]>;
  signin: (data: Data) => Promise<User>;
  signout: () => Promise<void>;
  setUser: Dispatch<SetStateAction<User | null>>;
  setVendor: Dispatch<SetStateAction<Vendor | null>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setVendors: Dispatch<SetStateAction<Vendor[]>>;
};

type Data = {
  login: string;
  password: string;
};

interface Props extends Pick<AuthProvider, 'vendorId' | 'user'> {
  isLoading: boolean;
}

export const authContext = createContext<AuthProvider>({} as AuthProvider);

export const useAuth = (): AuthProvider => useContext<AuthProvider>(authContext);

export const useIsLoggedIn = (): Props => {
  const {
    user, setUser, vendorId, isLoading, setCurrentIp,
  } = useAuth();

  const [requestIsLoading, setIsLoading] = useState(true);

  const handleGetInfo = async () => {
    const { docs, ip } = await new RequestService('/users/userme').getAll();
    const [userme] = docs;

    const localStorageLang = localStorage.getItem('i18nextLng');
    const isCorrectLang = countriesList.some(({ lang, showLang }) => (
      showLang && localStorageLang === lang
    ));

    if (!localStorageLang || !isCorrectLang) {
      const findedLanguage = countriesList.find(({ code }) => ip?.country === code);
      localStorage.setItem('i18nextLng', findedLanguage ? findedLanguage.lang : 'eng');
      i18n.changeLanguage(findedLanguage ? findedLanguage.lang : 'eng');
    }

    if (ip) setCurrentIp(ip);

    if (!userme) {
      setIsLoading(false);
      return;
    }

    setUser(userme);
    setIsLoading(false);
  };

  useEffect(() => {
    if (user || isLoading) return;

    handleGetInfo();
  }, [user, isLoading]);

  return {
    user,
    vendorId,
    isLoading: requestIsLoading,
  };
};

const setSessionCookie = (token: string) => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  const expires = date.toUTCString();
  // console.log(document.cookie, 'setSessionCookie')
  document.cookie = `session=${token}; expires=${expires}; path=/; domain=preprowebway.eu; SameSite=None; Secure`;
};

function getTokenFromCookie() {
  // console.log(document.cookie.split('=')[1], 'getTokenFromCookie')
  return document.cookie.split('=')[1];
}

// User entry point
export function useProvideAuth(): AuthProvider {
  const [user, setUser] = useState<User | null>(null);
  const [vendor, setVendor] = useState<Vendor | null>(null);
  const preordersResult = usePreorders(user);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [currentIp, setCurrentIp] = useState<ICurrentIp | null>(null);
  const [myLocation] = useMyPosition();

  const signup = async (data: UserToCreate) => {
    setIsLoading(true);
    const client = await new RequestService('/users').put<User>(data)
      .then((res) => res.data.docs);

    setIsLoading(false);
    return client;
  };

  const signin = async (data: Data) => {
    setIsLoading(true);
    try {
      const response = await new RequestService('/users/login').post(data);

      setUser(response);

      // console.log(response.token, 'response.token')
      if (response.token) {
        setSessionCookie(response.token);
      }

      const tokenFromCookie = getTokenFromCookie();

      if (tokenFromCookie) {
        // console.log('we have a token')
        setSessionCookie(tokenFromCookie);
      }

      return response;
    } finally {
      setIsLoading(false);
    }
  };

  const signout = async () => {
    setIsLoading(true);

    try {
      await unsubscribeNotification();
      await new RequestService('/users/logout').post();
      setUser(null);
      setVendors([]);
      setVendor(null);

      localStorage.removeItem('uuid');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!user) {
      return () => {};
    }

    socket.init();

    const verifiedCallback = async () => {
      const response = await new RequestService('/users/userme').getAll();
      const [userDataFromRes] = response.docs;
      setUser(userDataFromRes);
    };

    socket.emit('joinToRoom', user.uuid);
    socket.on('settingsChange', verifiedCallback);

    return () => {
      socket.off('settingsChange', verifiedCallback);
    };
  }, [user]);

  useEffect(() => {
    const handleGetVendors = async () => {
      const vendorsResponse = await getMyVendors();
      setVendors(vendorsResponse);
    };

    if (!user) return;

    handleGetVendors();
  }, [user]);

  return {
    user,
    vendor,
    vendors,
    vendorId: vendor?.id || null,
    vendorUuid: vendor?.uuid || null,
    isLoading,
    currentIp,
    geolocation: myLocation,
    setCurrentIp,
    signin,
    signout,
    signup,
    setUser,
    setVendor,
    setIsLoading,
    setVendors,
    ...preordersResult,
  };
}
