import { makeStyles } from '@material-ui/core/styles';
import { green } from '../../constants';

export default makeStyles({
  message: {
    width: '250px',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: green,
    fontSize: '14px',
    textTransform: 'uppercase',
  },
});
