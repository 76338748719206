import { makeStyles } from '@material-ui/core';
import {
  backgroundColor,
  chatBackgroundColor,
  darkIcon,
  grayBG,
  green,
  orange,
  textColor,
} from '../../constants';

export default makeStyles({
  menuModal: {
    width: '100%',
    backgroundColor: darkIcon,
    color: backgroundColor,
  },
  label: {
    fontSize: 16,
    marginLeft: 5,
    color: backgroundColor,
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  itemWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  indicator: {
    width: 20,
    height: 20,
    backgroundColor: textColor,
  },
  indicatorActive: {
    backgroundColor: orange,
  },
  submitBtn: {
    backgroundColor: orange,
    color: backgroundColor,
  },
  input: {
    backgroundColor,
    color: textColor,
    marginBottom: 5,
    padding: 5,
  },
  menuOpenBtn: {
    position: 'absolute',
    right: 0,
    width: 6,
    height: '100%',
    padding: '0 5px',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    backgroundColor: darkIcon,

    '& span': {
      backgroundColor,
      width: '100%',
      height: '20%',
    },
  },
  menuBtnWrapper: {
    width: 16,
  },
  chat: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'hidden',
  },
  arrowIcon: {
    paddingLeft: '20px',
    color: green,
  },
  chatUserInfoWrapper: {
    position: 'relative',
    display: 'flex',
    padding: '5px',
    borderBottom: `1px ${textColor} solid`,
    alignItems: 'center',
    backgroundColor: grayBG,
  },
  container: {
    maxHeight: '100%',
    overflow: 'auto',
    paddingTop: '20px',
    height: '100%',
    backgroundColor: chatBackgroundColor,
  },
  confirmModal: {
    padding: 0,
  },
  userIdLabel: {
    fontSize: 16,
  },
}, { index: 2 });
