import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Country } from 'react-phone-number-input';
import RequestService from 'services/requestApi';
import {
  AppInfoTextOnlyItem,
  HowProjectWork,
  IOnChange,
  IndexedDBData,
  Lang,
  LangHeader,
  LangText,
} from 'interfaces';
import { useBrands } from 'components/ConstructorForm/items/CarCombobox/useBrands';
import { useAuth } from 'services/auth';
import i18n from 'i18n';
import { getAppInfo } from 'services/commonService';
import { formatAppInfo, getTranslateFunction } from 'helpers';
import IndexedDB from 'indexedDB';
import SignUpComponent from '../components/SignUpComponent';
import {
  INDEXEDDB_SIGNUP_TABLE_NAME,
  INDEXED_DATA_LIFETIME,
  SIGNUP_FORM_STEPS,
  SIGNUP_STEPS,
} from '../../../constants';
import InfoStep from '../steps/InfoStep';

const POSITIONS = [
  'registerHomeTop',
  'registerHomeBottom',
  'registerExecutorTop',
  'termsOfUse',
  'privacyPolicy',
  'uploadVendorFiles',
];

export type SignUpStepsKeys = keyof typeof SIGNUP_STEPS;
export type SignUpStepsValues = typeof SIGNUP_STEPS[SignUpStepsKeys];

const SignUpApp: React.FC = () => {
  const [step, setStep] = useState<SignUpStepsValues>(SIGNUP_STEPS.INFO);
  const { setIsLoading } = useAuth();
  const t = getTranslateFunction();
  const [country, setCountry] = useState<Country>();
  const [appInfo, setAppInfo] = useState<Record<string, string>>({});

  useEffect(() => {
    const handleGetAppInfo = async () => {
      const response = await getAppInfo();

      const result = formatAppInfo(
        response as AppInfoTextOnlyItem[],
        i18n.language as Lang,
        POSITIONS,
      );

      const registerTranslated = t('signInPage.link.register');
      const langKeyHeader = `${i18n.language}Header` as LangHeader;
      const langKeyText = `${i18n.language}Text` as LangText;

      const registerItem = (response as HowProjectWork[]).find((item) => (
        item[langKeyHeader] === registerTranslated));

      if (registerItem) {
        result.register = registerItem[langKeyText];
      }

      setAppInfo(result);
    };

    handleGetAppInfo();
  }, [i18n.language]);

  const {
    brands,
    getModels,
    models,
  } = useBrands();

  const history = useHistory();
  const invitedBy = new URLSearchParams(history.location.search).get('invitedBy');

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const response = await new RequestService('/users/userme').get();
        if (!response.ip) return;

        setCountry(response.ip.country);
      } finally {
        setIsLoading(false);
      }
    };

    const checkIsDataExist = async () => {
      const lowestDate = Date.now() - INDEXED_DATA_LIFETIME;
      const dbClientData = await new IndexedDB().getData<IndexedDBData>(INDEXEDDB_SIGNUP_TABLE_NAME, `formSteps-${SIGNUP_FORM_STEPS.client}`);
      const dbVendorData = await new IndexedDB().getData<IndexedDBData>(INDEXEDDB_SIGNUP_TABLE_NAME, `formSteps-${SIGNUP_FORM_STEPS.vendor}`);

      if (!dbClientData) {
        await new IndexedDB().deleteData(INDEXEDDB_SIGNUP_TABLE_NAME, `formSteps-${SIGNUP_FORM_STEPS.vendor}`);
        return;
      }

      if (dbClientData.updatedAt < lowestDate) {
        await new IndexedDB().deleteData(INDEXEDDB_SIGNUP_TABLE_NAME, `formSteps-${SIGNUP_FORM_STEPS.client}`);

        if (dbVendorData && dbVendorData.updatedAt < lowestDate) {
          await new IndexedDB().deleteData(INDEXEDDB_SIGNUP_TABLE_NAME, `formSteps-${SIGNUP_FORM_STEPS.vendor}`);
        }

        return;
      }

      setStep(SIGNUP_STEPS.FORM);
    };

    checkIsDataExist();
    getData();
  }, []);

  const handleChange = ({ action, data }: IOnChange) => {
    switch (action) {
      case 'getModels': {
        getModels(data);
        break;
      }
      case 'setStep': {
        setStep(data);
        break;
      }
      default: break;
    }
  };

  return (
    <>
      {step === SIGNUP_STEPS.INFO && (
        <InfoStep
          appInfo={appInfo}
          onChange={handleChange}
        />
      )}

      {step === SIGNUP_STEPS.FORM && (
        <SignUpComponent
          phoneDescription={appInfo.registerHomeTop}
          userPhotoDescription={appInfo.registerHomeBottom}
          vendorFilesDescription={appInfo.uploadVendorFiles}
          invitedBy={invitedBy ?? ''}
          country={country}
          onChange={handleChange}
          brands={brands}
          models={models}
        />
      )}
    </>
  );
};

export default SignUpApp;
