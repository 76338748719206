import { Box, FormControl } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Controller, useWatch, UseFormMethods, type ValidationValueMessage } from 'react-hook-form';
import TextField from 'sharedComponents/TextField';
import VerificationInputStatus from 'pages/ProfilePage/components/VerificationInputStatus';
import { IOnChange } from 'interfaces';
import { ModelItem } from '../../components/ConstructorForm/items/CarCombobox/useBrands';
import useConfigItems, { OptionItem } from '../../components/ConstructorForm/items/CarCombobox/useConfigItems';
import type { VendorVerificationFieldKeys, VendorVerificationFields } from './containers/UpdateVendorPageContainer';

const filter = createFilterOptions<OptionItem>();

const EMPTY_OBJECT = {
  id: '',
  label: '',
};

export type ComboboxItem = {
  name: VendorVerificationFieldKeys;
  label?: string;
  rules?: {
    required?: ValidationValueMessage<boolean>;
    pattern?: ValidationValueMessage<RegExp>;
  };
  creatable?: boolean;
  options: OptionItem[];
};

interface ComboboxItemsProps
  extends Pick<UseFormMethods, 'errors' | 'control'>,
  Partial<Pick<UseFormMethods, 'clearErrors'>> {
  items: ComboboxItem[];
  classes: Record<string, string>;
  verification: VendorVerificationFields;
}

const ComboboxItems = ({ items, classes, control, errors, clearErrors, verification }: ComboboxItemsProps) => {
  useEffect(() => {
    const { defaultValuesRef, setValue } = control;
    const defaultValues = defaultValuesRef.current;

    if (defaultValues) {
      Object.entries(defaultValues).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [control]);

  return (
    <Box className={classes.comboboxContainer}>
      {items.map(({ label, name, rules, options, creatable }: ComboboxItem) => {
        const isDisabled = !!verification[name] && name !== 'vehicleSeatsCount' && name !== 'vehicleColor';
        return (
          <VerificationInputStatus isVerified={!!verification[name]}>
            <Controller
              key={name}
              control={control}
              name={name}
              rules={rules}
              render={({ onChange, value }) => {
                const valueIsObject = typeof value === 'object' && value !== null;
                return (
                  <Box className={classes.comboBoxItem}>
                    <FormControl
                      error={Boolean(errors[name])}
                      className={classNames(classes.inputFormControl, { required: rules?.required })}
                    >
                      <Autocomplete
                        options={options}
                        value={valueIsObject ? value : EMPTY_OBJECT}
                        className={classNames(classes.autoComplete, {
                          [classes.carComboboxAutoCompleteError]: errors[name],
                        })}
                        onFocus={() => clearErrors?.(name)}
                        onChange={(_, data) => {
                          clearErrors?.(name);
                          onChange(data);
                        }}
                        disabled={isDisabled}
                        renderOption={(option: OptionItem) => option.label}
                        getOptionLabel={(option) => option.label?.toString() || ''}
                        getOptionSelected={(option, innerValue) => {
                          return option.id === innerValue.id || innerValue === EMPTY_OBJECT;
                        }}
                        filterOptions={(currOptions, params) => {
                          const filtered = filter(currOptions, params);
                          const isExist = filtered.some(
                            ({ label: innerLabel }) => innerLabel.toString() === params.inputValue,
                          );

                          const isAllowedPattern = rules?.pattern ? params.inputValue.match(rules.pattern.value) : true;

                          if (params.inputValue && creatable && !isExist && isAllowedPattern) {
                            filtered.push({
                              id: 0,
                              label: params.inputValue,
                            });
                          }

                          return filtered;
                        }}
                        renderInput={(params) => (
                          <TextField
                            errorMessage={errors[name]?.message}
                            errorMessageClassName={classes.errorMessageCarCombobox}
                            className={classNames(classes.textField, { error: errors[name] })}
                            placeholder={label}
                            disabled={params.disabled}
                            fullWidth={params.fullWidth}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                              inputProps: params.inputProps,
                            }}
                            variant="filled"
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                );
              }}
            />
          </VerificationInputStatus>
        );
      })}
    </Box>
  );
};

type FormMethods = 'control' | 'setValue' | 'errors';

interface CarComboboxProps extends Pick<UseFormMethods, FormMethods>, Partial<Pick<UseFormMethods, 'clearErrors'>> {
  classes: { [key: string]: string };
  mode: string;
  models: ModelItem[];
  brands: string[];
  onChange: (a: IOnChange) => void;
  verification: VendorVerificationFields;
}

const REGISTER_AUTOCOMPLETE_PROPERTIES = [
  'vehicleBrand',
  'vehicleModel',
  'vehicleSeatsCount',
  'vehicleColor',
  'vehicleYear',
  'vehicleDoorsCount',
];

const UpdateVendorCombobox: React.FC<CarComboboxProps> = ({
  control,
  classes,
  errors,
  mode,
  setValue,
  brands,
  models,
  onChange,
  clearErrors,
  verification,
}) => {
  const isRegisterMode = mode === 'register';
  const brandItemName = isRegisterMode ? 'vehicleBrand' : 'vehicleBrandOfBuy';
  const colorItemName = isRegisterMode ? 'vehicleColor' : 'vehicleColorOfBuy';

  const brandSelected = useWatch<{ label: string }>({
    control,
    name: brandItemName,
  });

  const formValues = useWatch({
    control,
  });

  const { selectsConfigPreorder, selectsConfigRegister, selectsPreorderServices } = useConfigItems({
    brandItemName,
    colorItemName,
    brands,
    models,
    type: formValues.vehicleType,
  });

  useEffect(() => {
    if (brandSelected) {
      const label = brandSelected.label || brandSelected;

      onChange({
        action: 'getModels',
        data: label,
      });
    }
  }, [brandSelected]);

  useEffect(() => {
    const isAllOptionsFilled = selectsConfigRegister.every(({ options }) => options.length);
    if (mode !== 'register' || !isAllOptionsFilled || !setValue) return;

    const autoCompleteProperties = REGISTER_AUTOCOMPLETE_PROPERTIES.reduce(
      (acc, prop) => ({
        ...acc,
        [prop]: formValues[prop],
      }),
      {},
    );

    Object.entries(autoCompleteProperties).forEach(([prop, initValue]) => {
      const item = selectsConfigRegister.find(({ name }) => name === prop);
      if (!(initValue && typeof initValue === 'string' && item?.options.length)) return;

      const formItem = item.options.find(({ label }) => label.toString() === initValue.toString());
      setValue(prop, formItem);
    });
  }, [selectsConfigRegister]);

  const getFormItems = (currentMode: string) => {
    if (currentMode === 'register') {
      return selectsConfigRegister;
    }
    if (currentMode === 'preorderService') {
      return selectsPreorderServices;
    }
    return selectsConfigPreorder;
  };

  const items = getFormItems(mode) as ComboboxItem[];

  if (!brands?.length) return null;

  return (
    <ComboboxItems
      items={items}
      control={control}
      errors={errors}
      classes={classes}
      clearErrors={clearErrors}
      verification={verification}
    />
  );
};

export default UpdateVendorCombobox;
