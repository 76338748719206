import { Box, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import Gallery from 'sharedComponents/Gallery/Gallery';
import { IOrder } from 'interfaces';
import Icons from 'components/Icons';
import { getTranslateFunction } from 'helpers';
import { dateFormatter, imagesSrcFormatter, formatPrice } from 'utils/formatters';
import { cyan } from '../../constants';
import useStyles from './style';

const {
  CommentTsx,
  nowSvg,
  toTimeSvg,
  fromSvg,
} = Icons;

const VEHICLE_KEYS = [
  'vehicleBrandOfBuy',
  'vehicleModelOfBuy',
  'vehicleColorOfBuy',
  'releaseDateFromOfBuy',
  'releaseDateToOfBuy',
  'transmissionOfBuy',
] as const;

type VehicleKeys = typeof VEHICLE_KEYS[number];

interface Props {
  order: IOrder;
}

const PreorderSingleTypeComponent: React.FC<Props> = ({
  order,
}: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();
  const isNowOrder = order.tripTime === 'now';
  const tripTimeIcon = isNowOrder ? nowSvg : toTimeSvg;
  const tripTimeText = isNowOrder ? t('preorder.modal.nowLabel') : dateFormatter(Number(new Date(order.tripTime)));

  const photoArray = useMemo(() => {
    const { preorderPhotos } = order;

    return imagesSrcFormatter(preorderPhotos);
  }, [order]);

  const isInternalTransfer = useMemo(() => order.paymentType === 'internalTransfer', []);

  return (
    <>
      <Box className={classes.infoAboutCarPreorder}>
        {VEHICLE_KEYS
          .filter((key) => order.hasOwnProperty(key))
          .map((key: VehicleKeys) => {
            const { label } = order[key];

            return (
              <Box className={classes.preorderOption}>
                <Typography key={key}>{label}</Typography>
              </Box>
            );
          })}
      </Box>
      {order.preorderType && (
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <Box className={classes.svg} />
          <Typography>{`preorder.modal.${order.preorderType}`}</Typography>
        </Box>
      </Box>
      )}
      {order.preorderName && (
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <Box
            className={classes.svg}
          >
            <CommentTsx className={classes.svg} color={cyan} />
          </Box>
          <Typography>{order.preorderName}</Typography>
        </Box>
      </Box>
      )}
      {order.comment && (
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <Box
            className={classes.svg}
          >
            <CommentTsx color={cyan} />
          </Box>
          <Typography>{order.comment}</Typography>
        </Box>
      </Box>
      )}
      {Boolean(photoArray.length) && (
        <Box className={classes.preorderGalleryBox}>
          <Gallery
            images={photoArray}
          />
        </Box>
      )}
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <img
            className={classes.svg}
            src={tripTimeIcon}
            alt={tripTimeText}
          />
          <Typography>{tripTimeText}</Typography>
        </Box>
      </Box>
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <img
            className={classes.svg}
            src={fromSvg}
            alt="fromIcon"
          />
          <Typography>{`${t('preorder.modal.from')} ${order.buyerCity}`}</Typography>
        </Box>
      </Box>
      <Box className={classes.preorderOption}>
        <Box className={classes.listItem}>
          <img
            className={classes.svg}
            src={Icons[`${order.paymentType}Svg`]}
            alt={t(`preorder.modal.${order.paymentType}`)}
          />
          <Typography>{t(`preorder.modal.${order.paymentType}`)}</Typography>
          <Typography variant="caption" className={classes.endAdornment}>{formatPrice(order.price)}</Typography>
        </Box>
      </Box>
      {isInternalTransfer && (
        <Box className={classes.preorderOption}>
          <Typography>{t('order.internalTransfer.description')}</Typography>
        </Box>
      )}
    </>
  );
};

export default PreorderSingleTypeComponent;
