import { useEffect, useMemo, useState } from 'react';
import { getCurrencyByCountry } from 'helpers';
import {
  useBalance,
  useBonuses,
  useTransactions,
  useValues,
} from 'hooks/payment';
import { useUserRating } from 'hooks/rating';
import {
  IRating,
  PendingTransactionStats,
  TBalance,
  User,
} from 'interfaces';
import { useAuth } from 'services/auth';
import { getMessages, getMessagesCount, getMessagesFromSupportChat, getMyChats } from 'services/commonService';
import { useOrders } from 'services/orders';
import socket from 'socket';
import { USDcurrency } from '../../constants';

export const useHomePage = (): {
  pendingTransactions: PendingTransactionStats;
  incomeSumm: Record<string, number>;
  rating: IRating | null;
  user: User;
  ordersNumber: number;
  balance: TBalance;
  isVendor: boolean;
  bonuses: TBalance;
  unreadChats: number;
  unreadMessages: number;
} => {
  const [unreadChats, setUnreadChats] = useState(0);
  const [unreadMessages, setUnreadMessages] = useState(0);

  const { user, vendor, setIsLoading } = useAuth();
  const { orders } = useOrders();
  const { allBonuses } = useBonuses();
  const { rating } = useUserRating(user?.uuid);
  const balance = useBalance();
  const { transactions } = useTransactions();

  const userCurrency = useMemo(() => (
    user ? getCurrencyByCountry(user.country) : USDcurrency
  ), [user]);

  const {
    incomeSumm,
    pendingTransactions,
    formattedBonuses,
  } = useValues({
    transactions,
    orders: [],
    userCurrency,
    bonuses: allBonuses,
  });

  const fetchUnreadMessages = async () => {
    try {
      const response = await getMessagesCount();
      setUnreadMessages(response.data.count);
    } catch (error) {
      throw new Error('failed.to.fetch.unread.messages.count');
    }
  };

  const fetchUnreadChats = async () => {
    try {
      const chats = await getMyChats();

      const promises = chats.map((roomId) => getMessages(roomId, { limit: 1 }));
      const responsesMessages = (await Promise.all(promises)).flat();

      const result = responsesMessages.filter(({ toUser, isRead }) => (
        toUser === user?.uuid && !isRead
      ));
      const [supportChatResponse] = await getMessagesFromSupportChat({ limit: 1 });
      const supportChatNumber = supportChatResponse && !supportChatResponse.isRead ? 1 : 0;

      setUnreadChats(result.length + supportChatNumber);
      fetchUnreadMessages();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = socket.off('updateUnreadCount', fetchUnreadMessages);
    if (user) {
      fetchUnreadMessages();
      socket.on('newMessage', fetchUnreadChats);
    }

    socket.on('updateUnreadCount', fetchUnreadMessages);

    return unsubscribe;
  }, [user]);

  useEffect(() => {
    const unsubscribe = () => {
      socket.off('newMessage', fetchUnreadChats);
    };

    if (user) {
      fetchUnreadChats();
      socket.on('newMessage', fetchUnreadChats);
    }

    return unsubscribe;
  }, [user]);

  return {
    incomeSumm,
    pendingTransactions,
    rating,
    user,
    ordersNumber: orders.length,
    balance,
    bonuses: formattedBonuses,
    isVendor: Boolean(vendor),
    unreadChats,
    unreadMessages,
  };
};
