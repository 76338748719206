import { useMemo } from 'react';
import { ValidationValueMessage } from 'react-hook-form';
import { getTranslateFunction } from 'helpers';
import { ModelItem } from './useBrands';

type VehicleType = 'moto' | 'sedan' | 'minibus' | 'truck';

type Props = {
  colorItemName: string;
  brandItemName: string;
  models: ModelItem[];
  brands: string[];
  type: VehicleType;
};

export type OptionItem = {
  id: string | number;
  label: string | number;
};

export type Item = {
  name: string;
  label?: string;
  rules?: {
    required?: ValidationValueMessage<boolean>;
    pattern?: ValidationValueMessage<RegExp>;
  };
  creatable?: boolean;
  options: OptionItem[];
};

type ReturnType = {
  selectsPreorderServices: Item[];
  selectsConfigPreorder: Item[];
  selectsConfigRegister: Item[];
};

const useConfigItems = ({ colorItemName, brandItemName, models, brands, type }: Props): ReturnType => {
  const t = getTranslateFunction();

  const brandsItem = useMemo(() => ({
    label: t('signInPage.vehicleBrand'),
    name: brandItemName,
    rules: {
      required: {
        value: true,
        message: t('error.validation.required'),
      },
    },
    options: brands.map((item: string) => ({
      id: item, label: item,
    })),
  }), [brands]);

  const modelsItem = {
    label: t('signInPage.vehicleModel'),
    name: 'vehicleModel',
    rules: {
      required: {
        value: true,
        message: t('error.validation.required'),
      },
    },
    options: models.map(({ model }: ModelItem) => ({
      id: model, label: model,
    })),
  };

  const yearsItem = {
    label: t('signInPage.vehicleYear'),
    name: 'vehicleYear',
    rules: {
      required: {
        value: true,
        message: t('error.validation.required'),
      },
    },
    options: Array(new Date().getUTCFullYear() - 1900)
      .fill(0)
      .map((value, index) => ({
        id: 1900 + index + 1,
        label: 1900 + index + 1,
      }))
      .reverse(),
  };

  const colorsItem = {
    label: t('signInPage.vehicleColor'),
    name: colorItemName,
    rules: {
      required: {
        value: true,
        message: t('error.validation.required'),
      },
    },
    options: [
      { id: 'white', label: t('carCombobox.color.white') },
      { id: 'green', label: t('carCombobox.color.green') },
      { id: 'red', label: t('carCombobox.color.red') },
      { id: 'blue', label: t('carCombobox.color.blue') },
      { id: 'navyBlue', label: t('carCombobox.color.navyBlue') },
      { id: 'grey', label: t('carCombobox.color.grey') },
      { id: 'black', label: t('carCombobox.color.black') },
      { id: 'yellow', label: t('carCombobox.color.yellow') },
      { id: 'orange', label: t('carCombobox.color.orange') },
      { id: 'beige', label: t('carCombobox.color.beige') },
      { id: 'brown', label: t('carCombobox.color.brown') },
      { id: 'violet', label: t('carCombobox.color.violet') },
      { id: 'gold', label: t('carCombobox.color.gold') },
      { id: 'silver', label: t('carCombobox.color.silver') },
    ],
  };

  const transmissionItem = {
    label: t('signInPage.transmission'),
    name: 'transmissionOfBuy',
    rules: {
      required: {
        value: true,
        message: t('error.validation.required'),
      },
    },
    options: [
      { id: 'automatic', label: t('carCombobox.transmission.automatic') },
      { id: 'mechanicall', label: t('carCombobox.transmission.mechanicall') },
    ],
  };

  const selectsConfigRegister: Item[] = [
    brandsItem,
    {
      ...modelsItem,
      rules: {
        ...modelsItem.rules,
        pattern: {
          value: /^[a-zA-Z0-9 -]*$/,
          message: t('error.validation.required'),
        },
      },
      creatable: true,
      name: 'vehicleModel',
    },
    {
      ...yearsItem,
      creatable: true,
      name: 'vehicleYear',
      label: t('signInPage.vehicleYear'),
    },
    {
      label: t('signInPage.vehicleDoorsCount'),
      name: 'vehicleDoorsCount',
      creatable: true,
      rules: {
        required: {
          value: true,
          message: t('error.validation.required'),
        },
      },
      options: [
        { id: '3', label: t('carCombobox.vehicleDoorsCount.3') },
        { id: '4', label: t('carCombobox.vehicleDoorsCount.4') },
        { id: '5', label: t('carCombobox.vehicleDoorsCount.5') },
      ],
    },
    {
      label: t('signInPage.vehicleSeatsCount'),
      name: 'vehicleSeatsCount',
      creatable: true,
      rules: {
        required: {
          value: true,
          message: t('error.validation.required'),
        },
      },
      options: [
        { id: '2', label: t('carCombobox.vehicleSeatsCount.2') },
        { id: '4', label: t('carCombobox.vehicleSeatsCount.4') },
        { id: '6', label: t('carCombobox.vehicleSeatsCount.6') },
        { id: '8', label: t('carCombobox.vehicleSeatsCount.8') },
      ],
    },
    colorsItem,
  ];

  const selectsConfigPreorder: Item[] = [
    brandsItem,
    {
      ...modelsItem,
      name: 'vehicleModelOfBuy',
    },
    {
      ...yearsItem,
      name: 'releaseDateFromOfBuy',
      label: t('signInPage.releaseDateFrom'),
    },
    {
      ...yearsItem,
      name: 'releaseDateToOfBuy',
      label: t('signInPage.releaseDateTo'),
    },
    colorsItem,
    transmissionItem,
  ];

  const selectsPreorderServices: Item[] = [
    brandsItem,
    {
      ...modelsItem,
      name: 'vehicleModelOfBuy',
    },
    {
      ...yearsItem,
      name: 'releaseDateFromOfBuy',
      label: t('signInPage.releaseDateFrom'),
    },
    transmissionItem,
  ];

  return {
    selectsPreorderServices,
    selectsConfigPreorder,
    selectsConfigRegister:
      type !== 'moto'
        ? selectsConfigRegister
        : selectsConfigRegister.filter(
          (item) => item.name !== 'vehicleDoorsCount' && item.name !== 'vehicleSeatsCount',
        )
    ,
  };
};

export default useConfigItems;
