import { makeStyles } from '@material-ui/core/styles';
import { backgroundColor, green, red } from '../../constants';

export default makeStyles((theme) => ({
  message: {
    width: '250px',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: green,
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  buttonsContainer: {
    textAlign: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px',
    alignItems: 'stretch',
    [theme.breakpoints.down(380)]: {
      gridTemplateColumns: '1fr',
    },
  },
  button: {
    padding: '10px 5px',
    backgroundColor: red,
    color: backgroundColor,
    lineHeight: '1',
    fontSize: '16px',
    display: 'block',
    width: '100%',
    outline: 'none',
    borderRadius: '0',
    '&:hover': {
      backgroundColor: red,
    },
    '&.green': {
      backgroundColor: green,
      '&:hover': {
        backgroundColor: green,
      },
    },
    [theme.breakpoints.down(380)]: {
      '&.active': {
        order: -1,
      },
    },
  },

}));
