import classNames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ArrowDownward } from '@material-ui/icons';
import useStyles from './styles';

interface Props {
  Icon?: React.FC<{ color: string }>;
  title: string;
  children: React.ReactNode;
  withoutMargins?: boolean;
  isScrollable?: boolean;
  onClick?: () => void;
}

const CollapseBox: React.FC<Props> = ({
  Icon,
  title,
  children,
  withoutMargins = false,
  isScrollable = false,
  onClick = () => {},
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const toggleCollapse = useCallback(() => {
    setIsOpen((prev) => !prev);
    onClick();
  }, [onClick]);

  useEffect(() => {
    if (isScrollable && isOpen && boxRef.current) {
      boxRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [isOpen]);

  return (
    <div ref={boxRef} className={classNames(classes.wrapper, { [classes.withoutMargins]: withoutMargins })}>
      <Box className={classNames(classes.listTitle, { isOpen })} onClick={toggleCollapse}>
        <Box className={classes.svg}>{Icon && <Icon color="#FFF" />}</Box>
        <Typography>{title}</Typography>
        <ArrowDownward className={classNames(classes.titleArrow, { isOpen })} />
      </Box>
      <Box className={classNames(classes.listContent, { isOpen })}>{children}</Box>
    </div>
  );
};

export default CollapseBox;
