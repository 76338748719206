import { makeStyles } from '@material-ui/core/styles';
import {
  grayBG,
  red,
  textNoWrap,
} from '../../constants';

export default makeStyles({
  textNoWrap,
  infoToggleBtn: {
    minWidth: 'unset',
  },
  infoToggleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  infoToggleWrapperShowing: {
    backgroundColor: grayBG,
    alignItems: 'flex-start',
    position: 'relative',
    inset: 0,
    zIndex: 1,
    '&::after': {
      content: '\'\'',
      position: 'absolute',
      inset: '17px 19px',
      border: `${red} 1px solid`,
      pointerEvents: 'none',
    },
  },
  description: {
    margin: '0',
    width: '100%',
  },
  descriptionShowing: {
    padding: '25px',
  },
});
