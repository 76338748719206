import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { TCurrency } from 'interfaces';
import countriesList from 'services/countriesList';

export const colors = [
  '#006855',
  '#da04dd',
  '#0c5e7a',
  '#058940',
  '#d3750a',
  '#d3900a',
  '#ad250d',
  '#a00dc1',
  '#b101c1',
  '#0b557a',
  '#04a055',
  '#8c0113',
  '#0a8778',
  '#1a0875',
  '#f215d4',
  '#0e3f93',
  '#93051f',
  '#3e0282',
  '#d80a86',
  '#00517a',
  '#c4970f',
  '#c14f07',
  '#067584',
  '#d600dd',
  '#02847b',
  '#027e84',
  '#db0fc3',
  '#0a1c66',
  '#0a346b',
  '#081191',
  '#ffe716',
  '#ce8510',
  '#035c7c',
  '#06066d',
  '#d8bc06',
  '#03964f',
  '#c47911',
  '#05911f',
  '#9201c6',
  '#025d8e',
  '#439e03',
  '#099b8f',
  '#3b0ca0',
  '#04477a',
  '#d16500',
  '#024866',
  '#a02e0b',
  '#8c0b00',
  '#6f7c0b',
  '#0baa3e',
  '#078e3f',
  '#e004d9',
  '#d60084',
  '#0d3d89',
  '#039928',
  '#170c8e',
  '#d80fad',
  '#d6157c',
  '#b40fc6',
  '#567c02',
  '#003f5e',
  '#368406',
  '#027989',
  '#157200',
  '#08938f',
  '#064f66',
  '#15a00e',
  '#1f7500',
  '#e915ed',
  '#048764',
  '#550696',
  '#077c4f',
  '#c90ca9',
  '#20890d',
  '#044172',
  '#4c0a7f',
  '#5a0a7f',
  '#3a0799',
  '#079609',
  '#000b59',
  '#019b5d',
  '#aa0322',
  '#157c03',
  '#cc0bea',
  '#007575',
  '#022b89',
  '#06995e',
  '#ba0161',
  '#5d10a5',
  '#e9ed09',
  '#a50316',
  '#a30b1d',
  '#5f8201',
  '#081a75',
  '#0e0863',
  '#07998c',
  '#0a2266',
  '#9e0444',
  '#0e2f8c',
  '#ac0dc1',
];

export const servicesColors = [
  '#83d499',
  '#85abed',
  '#f0b4cb',
  '#e5e5e5',
  '#945a92',
  '#5e805f',
];

export const oneWordOnlyLettersRegExp = /^[a-zA-Za-яА-ЯА-ЩЬЮЯҐЄІЇа-щьюяґєії_]+$/;
export const onlyLettersRegExp = /^[a-zA-Za-яА-Я'-]+(\s+)?([a-zA-Za-яА-Я'-]+)?(\s+)?(\s+)?([a-zA-Za-яА-Я'-]+)?(\s+)?$/;
export const alphanumericWithCharactersRegExp = /^[a-zA-Za-яА-ЯА-ЩЬЮЯҐЄІЇа-щьюяґєії_ʼ -—ʼ'`]+$/;
export const alphanumericWithSpaceAndDashRegExp = /^[a-zA-Zа-яА-ЯА-ЩЬЮЯҐЄІЇа-щьюяґєії0-9 -]*$/;

export const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i;
export const phoneRegExp = /^[+]*[0-9]{10,20}$/;
export const uuidRegExp = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
export const onlyNumbersRegExp = /^[0-9]*$/;
export const passwordRegExp = /^(?=.*[0-9])(?=.*[a-zA-Z])[0-9a-zA-Z]{8,12}$/;
export const latinOnlyRegExp = /^[a-zA-Z]*$/;
export const numbersAndLettersRegExp = /^[a-zA-Zа-яА-ЯА-ЩЬЮЯҐЄІЇа-щьюяґєії0-9]*$/;
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 12;

export const green = '#509e2f';
export const red = '#ef3340';
export const cyan = '#00b5e2';
export const orange = '#ffb400';
export const backgroundColor = '#fff';
export const grayBG = '#efeded';
export const checkboxUnchecked = '#c2bebe';
export const darkIcon = '#343333';
export const textColor = '#1a1a1a';
export const purple = '#592bbd';
export const grayPrimary = '#fcfafb';
export const graySecondary = '#f6fcf7';
export const greenSecondary = '#0ec82c';
export const senderMessageColor = '#fcfafb';
export const recieverMessageColor = grayBG;
export const chatBackgroundColor = '#f6fcf7';

export const IMAGE_USER_SRC = '/api/files/users/';
export const IMAGE_VENDOR_SRC = '/api/files/vendors/';
export const IMAGE_GIFTS_SRC = '/api/files/gifts/';
export const IMAGE_APP_INFO_SRC = '/api/files/appInfo/';
export const IMAGE_ORDER_SRC = '/api/files/order/';

export const UAHcurrency: TCurrency = 'UAH';
export const USDcurrency: TCurrency = 'USD';
export const EURcurrency: TCurrency = 'EUR';

export const UAHCurrencySymbol = '₴';
export const USDCurrencySymbol = '$';
export const EURCurrencySymbol = '€';

export const minutes = 'min';

export const kilometres = 'KM';

export const clientFirstStep = [
  'orderIsNotValid',
  'performerIsNoSuitable',
  'orderChanges',
];
export const clientSecondStep = [
  'performerIsLate',
  'performerDoesNotMatch',
  'performerIsInadequate',
];
export const vendorFirstStep = [
  'gotInAnAccident',
  'transportBrokeDown',
  'notOnTheWay',
];
export const vendorSecondStep = [
  'clientDidNotCome',
  'clientIsNotAdequate',
  'orderDoesNotMatch',
];

export const MAP_CENTER_FALLBACK: [number, number] = [50.4547, 30.5238];
export const ORDER_STATUSES = {
  CANCELED_BY_CLIENT: 2,
  CANCELED_BY_VENDOR: 4,
  DEFAULT: 1,
  EXITING: 3,
  WAITING: 5,
  FINISHED: 6,
};

export const RESET_PASSWORD_STATUSES = {
  FILL_FORM: 1,
  ENTER_CODE: 2,
  ENTERING_NEW_PASSWORD: 3,
};

export const SIGNUP_STEPS = {
  INFO: 1,
  FORM: 2,
} as const;

export const EMAIL_TIME_REQUEST = 3 * 60;
export const EMAIL_EDIT_ATTEMPTS = 3;

export const VENDOR_POSITIONS_INTERVAL = 10000;

export const INDEXED_DATA_LIFETIME = 2592e5;
export const LANGUAGES = countriesList
  .filter(({ showLang }) => showLang)
  .map(({ lang }) => ({
    label: `navigation.lang${lang.toUpperCase()}`,
    info: { type: 'lang', lang },
  }));

export const SINGLE_TYPES: string[] = ['buyThings', 'rent', 'services'];
export const TYPES_WITH_POINTS: string[] = [
  'alongTheWay',
  'courier',
  'helpOnRoad',
];
export const VEHICLE_ORDER_TYPES: string[] = ['alongTheWay', 'helpOnRoad'];

export const ERROR_MSG_INSUFFICIENT_FUNDS = 'Insufficient funds on account.';
export const ERROR_MSG_TRANSFER_DISABLED = 'Internal payment has been disabled.';
export const ERROR_MSG_CHAT_BANNED = 'You\'re are banned by this user.';
export const ERROR_FOR_CODE_ATTEMPTS = 'Verification for code attempts exceeded';
export const ERROR_CODE_ATTEMPTS = 'Verification code attempts exceeded';

export const REFERRAL_ID_LENGTH = 10;
export const DEFAULT_COMMISSION = 10;
export const PLACE_PICKER_FALLBACK = 'PreorderPage.placePicker.fallback';

export const textNoWrap: CSSProperties = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

export const CHAT_LIST_SORT_INDEX = {
  AUTO_REPLY_CHAT: 4,
  SUPPORT_CHAT: 3,
  ORDER_CHAT: 2,
  UNREAD_MESSAGE: 1,
  DEFAULT: 0,
};

export const ORDER_HISTORY_MAX_HOURS_SHOW_CHAT_BUTTON = 72;

export const CONFIRM_CODE_MAX_TRY_COUNT = 5;
export const CONFIRM_FOR_CODE_MAX_TRY_COUNT = 3;
export const CONFIRM_CODE_LIFETIME_MS = 5 * 60 * 1000; // 5 minutes
export const PAYMENT_ERROR_CODE_LIFETIME = 5000;
export const PAYMENT_CLEAR_STATE_TIME = 10000;

export const KEYS = {
  order: {
    hiddenOnHistoryNameCustomer: 'hiddenOnHistoryCustomer',
    hiddenOnHistoryNameService: 'hiddenOnHistoryService',
  },
};

export const SIGNUP_FORM_STEPS = {
  client: 1,
  vendor: 2,
  veriff: 3,
} as const;

export const INDEXEDDB_DATABASE_NAME = 'webway';
export const INDEXEDDB_SIGNUP_TABLE_NAME = 'signup';

export const INDEXED_DB_TABLES = [{
  tableName: INDEXEDDB_SIGNUP_TABLE_NAME,
  options: { keyPath: 'id' },
}];

// REACT NATIVE CONSTANTS

export const PAGE_LOADED = 'pageLoaded';
export const SET_IS_LOADING = 'setIsLoading';

export const INPUT_ACCEPTABLE_EXTENSIONS = ['jpeg', 'png', 'jpg'];
export const INPUT_FILE_ACCEPTABLE_EXTENSIONS = INPUT_ACCEPTABLE_EXTENSIONS.map((ext: string) => `.${ext}`).join(',');
