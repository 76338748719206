import { Box, Typography } from '@material-ui/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { ArrowDownward, Done } from '@material-ui/icons';
import Modal from 'sharedComponents/Modal';
import { useLocation } from 'react-router-dom';
import BackButton from 'sharedComponents/BackButton';
import { getTranslateFunction } from 'helpers';
import Icons from 'components/Icons';
import {
  options,
  groupByType,
  buttons,
  HomepageButton,
} from 'utils/optionsConfig';
import { IOnChange, OrderType, Vendor } from 'interfaces';
import { useModal } from 'services/customHooks';
import { useAuth } from 'services/auth';
import modalStyles from 'sharedComponents/Modal/modalStyles';
import useStyles from '../DropdownOptionsStyle';
import { VEHICLE_ORDER_TYPES } from '../../../constants';

export type ButtonItem = {
  type: string;
  service: string;
  contentKey: string;
  icon: string;
};

interface CheckboxProps {
  item: ButtonItem,
  checked: boolean;
  onChange: (a: ButtonItem) => void;
  gender: 'male' | 'female';
  showModal: () => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  item,
  checked,
  onChange,
  gender,
  showModal,
}) => {
  const t = getTranslateFunction();
  const classes = useStyles();

  const isGenderMale = gender === 'male' && item.contentKey.includes('women');

  const handleChange = useCallback(() => {
    onChange(item);
  }, [item, onChange]);

  return (
    <Box
      data-opts-btn={item.contentKey}
      className={classNames(classes.optionsBtn, {
        [classes.optionsBtnChecked]: checked,
        [classes.transparent]: isGenderMale,
      })}
      onClick={isGenderMale ? () => showModal() : handleChange}
    >
      <img
        src={Icons[item.icon]}
        alt={item.contentKey}
        className={classes.contentIcon}
      />
      <Typography className={classes.text}>
        {t(item.contentKey)}
      </Typography>
      {checked && (
        <Done className={classes.optionsBtnCheckedIcon} />
      )}
    </Box>
  );
};

interface ButtonGroupProps {
  checked: ButtonItem[];
  id: string;
  name: string;
  isOpen: boolean;
  option: HomepageButton[];
  onChange: (a: ButtonItem) => void;
  onToggleOpen: (a: string) => void;
  isShowParent: boolean;
  showIsSelected: boolean;
  gender: 'male' | 'female';
  showModal: () => void;
  isToggleable: boolean;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  checked,
  id,
  isOpen,
  name,
  onChange,
  onToggleOpen,
  option = [],
  isShowParent,
  showIsSelected,
  gender,
  showModal,
  isToggleable,
}) => {
  const onToggleOpenRef = useRef(onToggleOpen);
  const classes = useStyles();
  const t = getTranslateFunction();

  const handleToggleOpen = useCallback(() => {
    onToggleOpenRef.current(id);
  }, [id, onToggleOpenRef]);

  const isInclude = useCallback((key) => {
    return checked.some((item) => item.contentKey === key);
  }, [checked]);

  return (
    <Box className={classes.mainBtnContainer}>
      {isShowParent && (
        <Box
          className={classNames(classes.mainBtn, { [classes.disabledToggle]: !isToggleable })}
          onClick={handleToggleOpen}
        >
          <Box className={classes.title}>
            <img src={Icons[name]} alt={name} className={classes.svgIcon} />
            <Typography variant="body2">{t(name)}</Typography>
          </Box>

          {isToggleable && (
            <Box className={classNames(classes.arrow, { open: isOpen })}>
              <ArrowDownward />
            </Box>
          )}
        </Box>
      )}

      <Box className={classNames(classes.optionsBox, { open: isOpen })}>
        {option.map((item: HomepageButton) => (
          <Checkbox
            key={item.contentKey}
            item={item}
            checked={showIsSelected && isInclude(item.contentKey)}
            onChange={onChange}
            gender={gender}
            showModal={showModal}
          />
        ))}
      </Box>
    </Box>
  );
};

interface DropdownOptionsComponentProps {
  vendor: Vendor | null;
  onChange: (a: IOnChange) => void;
  menuExecutorProjectsTop: string;
  currentType: OrderType;
}

const DropdownOptionsComponent: React.FC<DropdownOptionsComponentProps> = ({
  vendor,
  onChange,
  menuExecutorProjectsTop,
  currentType,
}: DropdownOptionsComponentProps) => {
  const [openedGroups, setOpenedGroups] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const [initiallySelected, setInitiallySelected] = useState<Set<string>>(new Set());
  const classes = useStyles();
  const modalClasses = modalStyles();
  const t = getTranslateFunction();
  const { user } = useAuth();
  const { isModalVisible, showModal, hideModal } = useModal();
  const defaultGender = user?.gender ?? 'male';
  const location = useLocation();

  useEffect(() => {
    if (vendor && vendor[currentType] && Object.keys(vendor[currentType]).length > 0) {
      const vendorSelections = vendor[currentType];
      const initialSelections = new Set<string>();
      const initialButtons: HomepageButton[] = [];

      Object.entries(vendorSelections).forEach(([service, contentKeys]) => {
        if (Array.isArray(contentKeys)) {
          contentKeys.forEach((contentKey) => {
            initialSelections.add(contentKey);

            const matchingButton = buttons.find((button) => button.type === currentType
              && button.service === service
              && button.contentKey === contentKey);

            if (matchingButton) {
              initialButtons.push(matchingButton);
            }
          });
        }
      });

      if (initialSelections.size > 0 && initiallySelected.size === 0) {
        setInitiallySelected(initialSelections);
        setSelectedItems(initialSelections);
      }
    }
  }, [vendor, currentType]);

  const isToggleable = useMemo(() => {
    const path = location.pathname;
    return path.includes('projects/alongTheWay') || path.includes('projects/courier');
  }, [location.pathname]);

  const handleToggleOpen = useCallback((id) => {
    if (isToggleable) {
      setOpenedGroups((prev: string[]) => {
        if (prev.includes(id)) {
          return prev.filter((innerId: string) => id !== innerId);
        }
        return prev.concat([id]);
      });
    }
  }, [isToggleable]);

  useEffect(() => {
    if (!isToggleable) {
      const allGroups = Object.keys(groupByType(buttons.filter((item) => item.type === currentType), 'service'));
      setOpenedGroups(allGroups);
    }
  }, [isToggleable, currentType]);

  const handleChange = useCallback((item) => {
    if (!vendor) {
      onChange({
        action: 'checkItem',
        data: { checked: [item], currentType },
      });
      return;
    }

    setSelectedItems((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(item.contentKey)) {
        newSet.delete(item.contentKey);
      } else {
        newSet.add(item.contentKey);
      }
      return newSet;
    });
  }, [vendor, currentType, onChange]);

  const handleSubmit = useCallback(() => {
    if (!vendor) return;

    const finalChecked = buttons
      .filter((button) => button.type === currentType
        && selectedItems.has(button.contentKey));

    onChange({
      action: 'checkItem',
      data: { checked: finalChecked, currentType },
    });
  }, [selectedItems, vendor, currentType, buttons]);

  useEffect(() => {
    if (!vendor) return;
    const isVehicleType = VEHICLE_ORDER_TYPES.includes(currentType);
    const withVehicle = Boolean(vendor[currentType]);

    if (isVehicleType && !withVehicle) {
      onChange({
        action: 'historyPush',
        data: '/create-vendor',
      });
    }
  }, [vendor, currentType, onChange]);

  const groups = useMemo(() => {
    const filteredButtons = buttons.filter((item) => item.type === currentType);
    return groupByType(filteredButtons, 'service');
  }, [user, currentType]);

  const selectedButtonItems = useMemo(() => {
    return Array.from(selectedItems).map((contentKey) => {
      const button = buttons.find((btn) => btn.contentKey === contentKey);
      return button || { contentKey, type: '', service: '', icon: '' };
    });
  }, [selectedItems]);

  return (
    <Box className={classes.dropdownBox}>
      {Boolean(vendor && menuExecutorProjectsTop) && (
        <Typography className={classes.pageTitle}>
          {menuExecutorProjectsTop}
        </Typography>
      )}

      <Box className={classes.backButtonWrapper}>
        <BackButton />
      </Box>

      {Object.entries(groups).map(([key, option]) => {
        const buttonOptions = options.find(({ service }) => key === service) || { contentKey: '' };
        const isDropdownOpen = !isToggleable || openedGroups.includes(key);

        return (
          <ButtonGroup
            key={key}
            showIsSelected={Boolean(vendor)}
            name={buttonOptions.contentKey}
            id={key}
            isOpen={isDropdownOpen}
            onChange={handleChange}
            onToggleOpen={handleToggleOpen}
            checked={selectedButtonItems}
            option={option}
            isShowParent={true}
            gender={defaultGender}
            showModal={showModal}
            isToggleable={isToggleable}
          />
        );
      })}

      {Boolean(vendor) && (
        <Typography className={classes.confirmBtn} onClick={handleSubmit}>
          {t('Submit')}
        </Typography>
      )}

      <Modal withoutBorder withoutPadding isOpen={isModalVisible} onClose={hideModal}>
        <div className={modalClasses.styledModal}>{t('for.women.only')}</div>
      </Modal>
    </Box>
  );
};

export default DropdownOptionsComponent;
