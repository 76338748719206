import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import { IMaskInput } from 'react-imask';
import { useForm } from 'react-hook-form';
import { getTranslateFunction } from 'helpers';
import { IUser, SosRoute } from 'interfaces';
import useStyles from '../styles';
import { ACCOUNT_ID_MASK } from '../constants';
import { getUserByReferralId } from 'services/commonService';
import Preloader from 'components/Preloader/Preloader';
import Plus from 'images/plus';
import buttonDown from 'images/icons/buttonDown.svg';
import { Check, CheckBox, Remove, TramSharp } from '@material-ui/icons';
import InfoToggle from 'components/InfoToggle/InfoToggle';

type Props = {
  onSave: (data: {
    sosRoute: SosRoute,
  }) => void;
  sosRoute: SosRoute;
};

interface ISosField {
  isConfirmed: boolean;
  newValue: string;
}

const SosFields: React.FC<Props> = ({
  onSave,
  sosRoute,
}) => {
  const t = getTranslateFunction();
  const classes = useStyles();
  // create useSosFields hook
  const [emergencyAccounts, setEmergencyAccounts] = useState<IUser[]>([]);
  const [fields, setFields] = useState<ISosField[]>([]);
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);

  const defaultValues = {
    sosRoute: { ...sosRoute },
  };

  const inputRefs = useRef<{ current: HTMLInputElement }[]>([]);

  const { handleSubmit, register } = useForm({ defaultValues });

  const handleAddField = (newValue = '', isConfirmed = false) => {
    setFields([...fields, { isConfirmed, newValue }]);
  };

  const handleRemove = (index: Number) => {
    const filteredFields = fields.filter((_, i) => i === index);
    setFields(filteredFields);
  }

  useEffect(() => {
    const request = async () => {
      const values = (sosRoute || []).map(value => value.replace(/\D/g, ''));
      const emergencyAccountsResponses = await Promise.all(
        values.map(getUserByReferralId)
      );

      const retrievedEmergencyValues = emergencyAccountsResponses.map((itemsArray) => itemsArray[0]);
      const newFields = values.map((value) => {
        const account = retrievedEmergencyValues.find((innerItem) => {
          return value === innerItem?.referralId;
        });

        return { isConfirmed: !!account, newValue: value || '' };
      });

      setFields(newFields);
      setEmergencyAccounts(retrievedEmergencyValues);
      setIsLoading(false);
    }

    request();
  }, []);

  const onSubmit = (data: {
    sosRoute: SosRoute,
  }) => {
    const checkIsValid = async () => {
      const newValue = Object.values(data.sosRoute).map(value => value?.replace(/\D/g, '')).filter(Boolean);

      const isValid = newValue.every((value) => value.length === 10);

      if (!isValid) {
        // return;
      }

      onSave({
        sosRoute: newValue,
      });
    }

    checkIsValid();
  };

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <Button className={classes.addIdBtn} >
        {t('securityPage.addIdBtn')}
        <img src={buttonDown} className={classes.addIdBtnIcon} />
      </Button>

      <div className={classes.becameVolenteerGroup}>
        <Button className={classes.becameVolenteerButton}>
          {t('securityPage.becameVolenteer')}
        </Button>
        <CheckBox className={classes.becameVolenteerCheckbox} />
      </div>

      <InfoToggle
        isCollapsedTextColorBase
        description={t('securityPage.volenteerConditions')}
        btnLabel='profilePage.infoToggle.btn'
      />

      {/*fields.map((data, index) => {
        const { isConfirmed } = data;

        return (
          <Box key={`sosRoute[${Number(index)}]`} className={isConfirmed ? classes.sosRouteConfirmed : classes.sosRouteItem}>
            <Typography className={classes.itemNumber}>
              {index + 1}
            </Typography>

            <IMaskInput
              className={classes.textField}
              inputRef={register}
              mask={ACCOUNT_ID_MASK}
              name={`sosRoute.${index}`}
              placeholder={t('securityPage.addUserIdPlaceholder')}
              ref={inputRefs.current[index]}
              unmask={true}
              disabled={data.isConfirmed}
            />

            {isConfirmed &&
              <Button className={classes.removeBtn} onClick={() => handleRemove(index)}>
                <Remove />
              </Button>
            }
          </Box>
        );
      })*/}

      {/*
      <Button onClick={() => handleAddField()} className={classes.addBtn}>
        <Plus color={backgroundColor} width={25} height={25} />
      </Button>

      <Button type="submit" className={classes.submitBtn}>
        {t('securityPage.saveSosRoute')}
      </Button>
*/}

    </form>
  );
};

export default SosFields;
