import React from 'react';
import { Button as MUIButton, ButtonProps } from '@material-ui/core';
import useStyles from './styles';

const Button: React.FC<ButtonProps<'button', { loading?: boolean }>> = ({
  children,
  loading = false,
  disabled,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <MUIButton {...otherProps} disabled={loading || disabled}>
      {
        loading
          ? <span className={classes.loader} />
          : children
      }
    </MUIButton>
  );
};

export default Button;
