import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { filterPreorders, getErrorMessage } from 'helpers';
import { useMyPosition } from 'hooks/geolocation';
import {
  IDistance, IDistanceLoading, Preorder, PreorderFilterData,
} from 'interfaces';
import { timeLeft } from 'utils/formatters';
import { ERROR_FOR_CODE_ATTEMPTS } from '../constants';
import { useAuth } from './auth';
import {
  getFilteredPreorders,
  getDistance,
} from './commonService';
import { reverseGeocode } from './geo';
import RequestService from './requestApi';

export const useCreateProject = (query: string, currentType: string): {
  preorders: Record<string, Preorder[]>;
  distances: IDistance[];
  addPreorder: (preorder: Preorder) => void;
} => {
  const [preorders, setPreorders] = useState<Record<string, Preorder[]>>({});

  const [distancesLoading, setDistancesLoading] = useState<IDistanceLoading[]>([]);
  const [distances, setDistances] = useState<IDistance[]>([]);
  const { setIsLoading } = useAuth();
  const [location] = useMyPosition();
  const [filterData, setFilterData] = useState<PreorderFilterData | null>(null);

  const getPreorders = useCallback(() => {
    const data = query
      .slice(1)
      .split('&')
      .reduce((acc, item) => {
        const [key, values] = item.split('=');
        return {
          ...acc,
          [key]: values.substring(1, values.length - 1).split(','),
        };
      }, {});
    return getFilteredPreorders({
      findColumnValue: data,
      op: 'or',
      type: currentType,
    });
  }, [query]);

  const handleAddPreorder = useCallback((data: Preorder) => {
    if (!filterData) return;

    const isShow = filterPreorders(data, filterData);
    if (!isShow) return;

    setPreorders((prev) => {
      const currentKey = Object.keys(prev).find((key) => key in data) as string;
      const prevValue = prev[currentKey];
      const dataIndex = prevValue.findIndex(({ preorderId }) => preorderId === data.preorderId);

      if (dataIndex >= 0) {
        prevValue.splice(dataIndex, 1, data);
      } else {
        prevValue.push(data);
      }

      return prev;
    });

    setDistancesLoading((prev: IDistanceLoading[]) => {
      const coords = [data.dots[0].lat, data.dots[0].lon];

      return prev.filter((item) => (
        item.id === data.preorderId
      )).length ? prev : [...prev, { id: data.preorderId, coords }];
    });
  }, [filterData]);

  const handleSetPreorders = (
    newPreoders: SetStateAction<Record<string, Preorder[]>>,
    newFilterData: PreorderFilterData,
  ) => {
    if (newPreoders instanceof Function) {
      setPreorders(newPreoders);
      return;
    }

    const entriesData = Object.entries(newPreoders);
    const filteredPreorders = entriesData.map(([key, values]) => ([
      key,
      values.filter((item) => filterPreorders(item, newFilterData)),
    ]));

    const newData: Record<string, Preorder[]> = Object.fromEntries(filteredPreorders);
    setPreorders(newData);
  };

  useEffect(() => {
    const allPreorders = Object.values(preorders).flat();

    if (!allPreorders.length) {
      return;
    }

    const dataIds = allPreorders.map(({ preorderId, dots }) => {
      const coords = dots.length ? [dots[0].lat, dots[0].lon] : [];

      return ({
        id: preorderId,
        coords,
      });
    });

    setDistancesLoading((prev) => [...prev, ...dataIds]);
  }, [preorders]);

  useEffect(() => {
    if (!(query && location)) return;

    const handleGetPreorders = async () => {
      try {
        setIsLoading(true);
        const engData = await reverseGeocode(location, { lang: 'eng' });
        const newFilterData = {
          city: engData.address.city,
          country: engData.address.country,
        };

        setFilterData(newFilterData);
        const data = await getPreorders();

        handleSetPreorders(data, newFilterData);
      } finally {
        setIsLoading(false);
      }
    };

    handleGetPreorders();
  }, [location]);

  useEffect(() => {
    if (!location) return;

    distancesLoading.forEach(({ id, coords }) => {
      const filteredCoords = coords.filter((coord: number | undefined) => coord);

      if (location.length && filteredCoords.length) {
        getDistance(location, coords)
          .then(([res]: IDistance[]) => {
            setDistances((prev): IDistance[] => (
              [...prev, { id, distance: res.distance }]
            ));
          });
      }
    });
  }, [distancesLoading, location]);

  return {
    preorders,
    distances,
    addPreorder: handleAddPreorder,
  };
};

interface ITimer {
  onFinish?: () => void;
  finishTime: number;
}

export const useTimer = ({
  onFinish,
  finishTime,
}: ITimer): number => {
  const [timer, setTimer] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const timeOfStart = Math.floor(new Date().getTime() / 1000);
      const diff = timeLeft(timeOfStart, finishTime);

      if (!diff) {
        clearInterval(interval);
        if (onFinish) onFinish();
      }
      setTimer(diff);
    }, 1000);

    return () => clearInterval(interval);
  }, [finishTime, timer]);

  return timer;
};

export const useCountdown = (): {
  count: number;
  setCount: (value: number) => void;
} => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count > 0) {
      const timeoutId = setTimeout(() => {
        setCount((c) => c - 1);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }

    return () => {};
  }, [count]);

  return {
    count,
    setCount,
  };
};

export const useLocalStorage = <S>(key: string, defaultValue: S):
[S, Dispatch<SetStateAction<S>>] => {
  const [value, setValue] = useState<S>(() => {
    const storageValue = localStorage.getItem(key);

    return storageValue
      ? JSON.parse(storageValue)
      : defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value]);

  return [value, setValue];
};

export interface UseRequestResponse {
  attempts: number;
  isAttemptsForCodeLeft: boolean;
  confirmed: boolean;
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendRequest: (url: string, data: any) => Promise<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendCode: (url: string, data: any) => Promise<any>;
}

export const useRequests = (): UseRequestResponse => {
  const [attempts, setAttempts] = useState(0);
  const [isAttemptsForCodeLeft, setIsAttemptsForCodeLeft] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const sendRequest = async (
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
  ) => {
    if (isLoading) return null;

    try {
      setIsLoading(true);
      const response = await new RequestService(url).post(data);

      const { attempts: responseAttempts } = response;

      setAttempts(responseAttempts);
      setIsAttemptsForCodeLeft(false);
      return response;
    } catch (error) {
      const message = getErrorMessage(error as Error);

      throw new Error(message);
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sendCode = async (url: string, data: any) => {
    if (isLoading) return null;

    try {
      setIsLoading(true);
      const response = await new RequestService(url).post(data);

      setConfirmed(true);
      return response;
    } catch (error) {
      const message = getErrorMessage(error as Error);
      if (message.includes(ERROR_FOR_CODE_ATTEMPTS)) setIsAttemptsForCodeLeft(true);

      throw new Error(message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    attempts,
    isAttemptsForCodeLeft,
    confirmed,
    isLoading,
    sendRequest,
    sendCode,
  };
};

interface UseModalOptions {
  isVisibleByDefault?: boolean;
  autoHideAfter?: number;
}

export const useModal = (options?: UseModalOptions) => {
  const {
    isVisibleByDefault = false,
    autoHideAfter = 7000,
  } = options || {};

  const [isModalVisible, setIsModalVisible] = useState(isVisibleByDefault);

  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);

  useEffect(() => {
    if (isModalVisible) {
      const timerId = setTimeout(() => {
        setIsModalVisible(false);
      }, autoHideAfter);

      return () => clearTimeout(timerId);
    }

    return () => {};
  }, [isModalVisible, autoHideAfter]);

  return { isModalVisible, showModal, hideModal };
};
