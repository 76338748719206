import React from 'react';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import {
  Box, Typography, TextField, FormControl, Button,
} from '@material-ui/core';
import PreorderSingleTypeComponent from 'components/PreorderSingleTypeComponent';
import { Add } from '@material-ui/icons';
import CustomFileInput from 'components/CustomFileInput/CustomFileInput';
import { Controller } from 'react-hook-form';
import { formatPrice, nowDateFormatter } from 'utils/formatters';
import { getTranslateFunction } from 'helpers';
import Icons from 'components/Icons';
import { cyan } from '../../../constants';
import useStyles from '../VendorOfferStyle';

const dateNow = `${nowDateFormatter(new Date())}T00:00`;

interface Photo {
  id: string;
}

const {
  CommentTsx,
  toTimeSvg,
} = Icons;

const OfferBuyForm: React.FC<any> = ({
  preorder,
  control,
  register,
  errors,
  photos,
  setPhotos,
  isMyOrder,
}: any) => {
  const t = getTranslateFunction();
  const classes = useStyles();

  const addInput = () => setPhotos((prev: Photo[]) => [...prev, { id: uuid() }]);

  return (
    <Box className={classes.preorderSingleType}>
      <PreorderSingleTypeComponent
        order={preorder}
      />
      {!isMyOrder && (
        <Box className={classes.formPreorderService}>
          <Box className={classes.formPreorderService}>
            <Box className={classes.preorderOption}>
              <FormControl className={classes.formControl}>
                <TextField
                  className={classNames(classes.textFieldBuyForm, { [classes.errorInput]: errors.vendorComment })}
                  name="vendorComment"
                  placeholder={t('PreorderForm.detailedDescription')}
                  inputRef={register({
                    required: t('OfferBuyForm.vendorComment.required'),
                  })}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <Box className={classes.svg}>
                        <CommentTsx color={cyan} />
                      </Box>
                    ),
                    endAdornment: (
                      <Box className={classNames(classes.required, classes.requiredPrimary)}>
                        *
                      </Box>
                    ),
                  }}
                  error={Boolean(errors.vendorComment)}
                  helperText={errors.vendorComment && errors.vendorComment.message}
                />
              </FormControl>
            </Box>
          </Box>
          <Box className={classes.inputFilesBox}>
            {photos.map((photo: { id: number }, index: number) => (
              <Box key={photo.id}>
                <Box className={classes.loadPhotoBoxTitle}>
                  <Typography>{t('OfferBuyForm.loadPhoto')}</Typography>
                </Box>
                <Controller
                  control={control}
                  name={`files.${index}`}
                  render={({ onChange }) => (
                    <CustomFileInput
                      onChange={onChange}
                    />
                  )}
                />
              </Box>
            ))}
            <Box className={classes.loadPhotoBoxTitle}>
              <Typography>{t('OfferBuyForm.loadPhoto')}</Typography>
              <Button onClick={addInput}>
                <Add />
              </Button>
            </Box>
          </Box>
          <Controller
            name="price"
            control={control}
            render={({ value, onChange }) => (
              <Box className={classes.priceBox}>
                <Box>
                  <Typography>{t('offer.ConfirmFor')}</Typography>
                  <Button
                    className={classNames(classes.button, 'green', { active: value === preorder.price })}
                    onClick={() => onChange(preorder.price)}
                  >
                    {formatPrice(preorder.price)}
                  </Button>
                </Box>
                <Box>
                  <Typography>{t('offer.propouseYourPrice')}</Typography>
                  <Button
                    onClick={() => onChange(value + 5)}
                    className={classNames(classes.button, 'green', { active: value !== preorder.price })}
                  >
                    {formatPrice(value + 5)}
                    &nbsp;
                    +
                  </Button>
                </Box>
              </Box>
            )}
          />
          <Box className={classes.preorderOption}>
            <FormControl>
              <TextField
                type="datetime-local"
                name="arrivalDateTime"
                className={classNames({ [classes.errorInput]: errors.arrivalDateTime })}
                InputProps={{
                  disableUnderline: true,
                  inputProps: { min: dateNow },
                  startAdornment: (
                    <img
                      className={classes.svg}
                      src={toTimeSvg}
                      alt="paymentType"
                    />
                  ),
                  endAdornment: (
                    <Box
                      className={classNames(classes.required, classes.requiredSecondary)}
                    >
                      *
                    </Box>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={register({
                  required: t('OfferBuyForm.arrivalDateTime.required'),
                })}
                error={Boolean(errors.arrivalDateTime)}
                helperText={errors.arrivalDateTime && errors.arrivalDateTime.message}
              />
            </FormControl>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default OfferBuyForm;
