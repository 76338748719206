import React, { useEffect, useMemo, useState } from 'react';
import i18n from 'i18n';
import { formatLangItems } from 'helpers';
import {
  Lang, PromotionAndGift,
} from 'interfaces';
import { useAuth } from 'services/auth';
import { getPromotionsAndGifts } from 'services/commonService';
import countriesList from 'services/countriesList';
import PromotionAndGiftsComponent, { Item } from '../components/PromotionAndGiftsComponent';

const PromotionAndGiftsApp: React.FC = () => {
  const { user } = useAuth();
  const [promotionsAndGifts, setPromotionsAndGifts] = useState<PromotionAndGift[]>([]);

  useEffect(() => {
    if (!user) return;

    const handleGetPromotionsAndGifts = async () => {
    };

    handleGetPromotionsAndGifts();
  }, [user]);

  const userLanguage = useMemo(() => {
    const countryData = countriesList.find(
      ({ label, showLang }) => showLang && label === user?.country,
    );
    if (!countryData) return '';

    const { lang } = countryData;

    return lang;
  }, [user]) as Lang;

  return (
    <PromotionAndGiftsComponent
      userLanguage={userLanguage}
    />
  );
};

export default PromotionAndGiftsApp;
