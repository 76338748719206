import React from 'react';
import { Box } from '@material-ui/core';
import Modal from 'sharedComponents/Modal';
import { VendorHomeServices, type Vendor } from 'interfaces';
import { useModal } from 'services/customHooks';
import { getTranslateFunction, hasDefaultProperties } from 'helpers';
import { indexStyles } from '../VendorHomePageStyle';
import ServiceListItem from './ServiceListItem';

interface Props {
  onChange: (a: string) => void;
  services: VendorHomeServices;
  vendor: Vendor | null;
}
const VendorHomePage: React.FC<Props> = ({ services, onChange, vendor }: Props) => {
  const classes = indexStyles();
  const { isModalVisible, showModal, hideModal } = useModal();
  const t = getTranslateFunction();

  return (
    <Box className={classes.serviceContainer}>
      {Boolean(services)
        && Object.entries(services).map(([key, value]: [string, number], index: number) => {
          if (key === 'alongTheWay' && vendor && hasDefaultProperties(vendor)) {
            return <ServiceListItem key={key} item={{ key, value }} index={index} onClick={showModal} />;
          }

          return <ServiceListItem key={key} item={{ key, value }} index={index} onClick={onChange} />;
        })}

      <Modal withoutBorder withoutPadding isOpen={isModalVisible} onClose={hideModal}>
        <div className={classes.vendorModal}>{t('vendorHomePage.alongTheWay.modal')}</div>
      </Modal>
    </Box>
  );
};

export default VendorHomePage;
