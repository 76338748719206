import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import PreorderPage from 'pages/PreorderPage/containers/PreorderPage';
import ProjectsPage from 'pages/ProjectsPage/ProjectsPage';
import DropdownOptions from 'components/DropdownOptions/containers/DropdownOptions';
import VendorOfferApp from 'pages/VendorOffers/containers/VendorOfferApp';
import VendorOrderApp from 'pages/OrderPage/containers/VendorOrderApp';
import CreateProjects from 'pages/VendorPreordersList/containers/PreordersListApp';
import CustomPage from 'components/CustomPage/CustomPage';
import HomePage from 'pages/HomePage';
import SignInPage from 'pages/SignInPage/SignInPage';
import ClientOrderApp from 'pages/ClientOrderPage/containers/ClientOrderApp';
import VendorsListApp from 'pages/VendorsListPage/containers/VendorsListApp';
import UpdateVendorPageContainer from 'pages/UpdateVendorPage/containers/UpdateVendorPageContainer';
import ChatContainer from 'pages/Chat/containers/ChatPageContainer';
import PaymentsPageApp from 'pages/PaymentsPage/containers/PaymentsPageApp';
import GlobalModal from 'components/GlobalModal';
import ProfilePage from 'pages/ProfilePage/containers/ProfilePage';
import ActiveOrdersPage from 'pages/ActiveOrdersPage/containers/ActiveOrdersApp';
import OrdersHistoryApp from 'pages/OrdersHistoryPage/containers/OrderHistoryApp';
import ResetPasswordApp from 'pages/ResetPasswordPage/containers/ResetPasswordApp';
import SignUpApp from 'pages/SignUpPage/containers/SignUpApp';
import ShareApp from 'pages/SharePage/containers/ShareApp';
import CreateVendorPageContainer from 'pages/CreateVendorPage/containers/CreateVendorPageContainer';
import SettingsPageContainer from 'pages/SettingsPage/containers/SettingsPageContainer';
import SecurityPage from 'pages/SecurityPage/containers/SecurityApp';
import PromotionAndGiftsApp from 'pages/PromotionsAndGifts/containers/PromotionAndGiftsApp';
import HowProjectWorkApp from 'pages/HowProjectWorkPage/containers/HowProjectWorkApp';
import PrivacyPage from 'pages/PrivacyPage';
import ActiveChat from 'pages/ActiveChat';
import VendorHomePage from 'pages/VendorHomePage/containers/VendorHomePage';
import UnverifiedPage from 'pages/Unverified/UnverifiedPage';
import ProcessingPage from 'pages/Processing/ProcessingPage';
import PageLayout from 'components/PageLayout';
import Route from 'components/Route';
import Preloader from 'components/Preloader/Preloader';
import SupportChat from 'pages/SupportChat';
import ReplyChat from 'pages/ReplyChat';
import ProjectSubscribePage from 'pages/ProjectSubscribePage/ProjectSubscribePage';
import { useIsLoggedIn } from 'services/auth';
import { getEditorPages } from 'services/commonService';
import { IPage } from 'interfaces';
import { useLocation } from 'hooks/geolocation';

const AppRouter: React.FC = () => {
  const [pages, setPages] = useState<IPage[]>([]);
  const { user, isLoading } = useIsLoggedIn();

  useLocation();

  useEffect(() => {
    if (!user) return;

    getEditorPages().then((res) => setPages(res));
  }, [user]);

  if (isLoading) return <Preloader />;

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/signin" roles={['guest']}>
          <SignInPage />
        </Route>

        <Route path="/signup" roles={['guest']}>
          <SignUpApp />
        </Route>

        <Route path="/reset-password" roles={['guest']}>
          <ResetPasswordApp />
        </Route>

        <Route path="/privacy">
          <PrivacyPage />
        </Route>

        <Route path="/unverified" roles={['guest', 'client', 'vendor']}>
          <UnverifiedPage />
        </Route>

        <Route path="/processing" roles={['guest', 'client', 'vendor']}>
          <ProcessingPage />
        </Route>

        <PageLayout>
          <Switch>
            <Route exact path="/chat/reply" roles={['client', 'vendor']}>
              <ReplyChat />
            </Route>

            <Route exact path="/chat/support" roles={['client', 'vendor']}>
              <SupportChat />
            </Route>

            <Route path="/chat/:uuid" roles={['client', 'vendor']} forbiddenRoles={['unverified']}>
              <ActiveChat />
            </Route>

            <Route exact path="/chat" roles={['client', 'vendor']}>
              <ChatContainer />
            </Route>

            <Route path="/vendor-order-room" roles={['vendor']}>
              <VendorOrderApp />
            </Route>

            <Route path="/check-active-orders" roles={['client', 'vendor']}>
              <ActiveOrdersPage />
            </Route>

            <Route path="/orders-history" roles={['client', 'vendor']}>
              <OrdersHistoryApp />
            </Route>

            <Route path="/vendor-home" roles={['vendor']}>
              <VendorHomePage />
            </Route>

            <Route path="/vendor/:id" roles={['vendor']}>
              <CreateProjects />
            </Route>

            <Route path="/vendor-offers" roles={['vendor']}>
              <VendorOfferApp />
            </Route>

            <Route exact path="/projects" roles={['client', 'vendor']}>
              <ProjectsPage />
            </Route>

            <Route path="/projects/:id" roles={['client', 'vendor']} forbiddenRoles={['unverified']}>
              <DropdownOptions />
            </Route>

            <Route path="/preorder/:id" roles={['client']} forbiddenRoles={['unverified']}>
              <PreorderPage />
            </Route>

            <Route path="/projects-subscribe" roles={['vendor']}>
              <ProjectSubscribePage />
            </Route>

            <Route path="/client-order" roles={['client']} forbiddenRoles={['unverified']}>
              <ClientOrderApp />
            </Route>

            <Route path="/settings" roles={['client', 'vendor']}>
              <SettingsPageContainer />
            </Route>

            <Route path="/payments" roles={['client', 'vendor']} forbiddenRoles={['unverified']}>
              <PaymentsPageApp />
            </Route>

            <Route path="/vendors-list" roles={['client', 'vendor']} forbiddenRoles={['unverified']}>
              <VendorsListApp />
            </Route>

            <Route path="/create-vendor" roles={['client', 'vendor']}>
              <CreateVendorPageContainer />
            </Route>

            <Route path="/update-vendor" roles={['client', 'vendor']}>
              <UpdateVendorPageContainer />
            </Route>

            <Route path="/profile" roles={['client', 'vendor']}>
              <ProfilePage />
            </Route>

            <Route path="/security" roles={['client', 'vendor']} forbiddenRoles={['unverified']}>
              <SecurityPage />
            </Route>

            <Route path="/share" roles={['client', 'vendor']} forbiddenRoles={['unverified']}>
              <ShareApp />
            </Route>

            <Route path="/promotions-and-gifts" roles={['client', 'vendor']}>
              <PromotionAndGiftsApp />
            </Route>

            <Route path="/how-project-work" roles={['client', 'vendor']}>
              <HowProjectWorkApp />
            </Route>

            {Boolean(pages && pages.length) && pages.map(({ pageName, pageContent, id }) => {
              const param = id;
              return (
                <Route
                  roles={['client', 'vendor']}
                  key={param}
                  path={`/p/${param}`}
                >
                  <CustomPage content={pageContent} header={pageName} />
                </Route>
              );
            })}

            <Route path="/" roles={['client', 'vendor']}>
              <HomePage />
            </Route>
          </Switch>

        </PageLayout>

      </Switch>
      <GlobalModal />
    </BrowserRouter>
  );
};

export default AppRouter;
