import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  loader: {
    width: '30px',
    height: '30px',
    border: '3px transparent solid',
    borderRadius: '50%',
    borderTopColor: 'inherit',
    animation: '$spin 1s infinite linear',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}, { index: 1 });
