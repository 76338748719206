import { useEffect, useMemo, useState } from 'react';
import {
  RatingType,
  User,
  Vendor,
  Preorder,
  PreorderService,
} from 'interfaces';
import {
  getPreorderServices,
  getUsers,
  getVendor,
  getVendorComments,
  getVendorRating,
} from 'services/commonService';
import socket from 'socket';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePreorderOffersPage = (preorder: Preorder | null): any => {
  const { key, preorderId } = preorder || {};
  const [offers, setOffers] = useState<PreorderService[]>([]);
  const [declinedOffers, setDeclinedOffers] = useState<number[]>([]);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [vendorsRating, setVendorsRating] = useState<{ id: string, data: RatingType }[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [vendorsComments, setVendorsComments] = useState<any>([]);
  const [vendorsLoading, setVendorsLoading] = useState<string[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [usersLoading, setUsersLoading] = useState<string[]>([]);

  const handleGetOffers = async (id: number) => {
    const response = await getPreorderServices(id);

    if (response.Error) {
      return;
    }

    setOffers(response.data);
  };

  useEffect(() => {
    if (!preorderId) return;
    handleGetOffers(preorderId);
  }, [preorderId]);

  useEffect(() => {
    offers.forEach(({ vendorUserUuid, vendorUuid }) => {
      if (vendorUuid && vendorUserUuid) {
        setUsersLoading((prev) => [...prev, vendorUserUuid]);
        setVendorsLoading((prev) => [...prev, vendorUuid]);
      }
    });
  }, [offers]);

  useEffect(() => {
    usersLoading.forEach((id) => getUsers(id).then((res) => {
      setUsers((prev) => (
        prev.filter((item) => item?.id === res[0]?.id).length ? prev : [...prev, res[0]]
      ));
    }));
  }, [usersLoading]);

  useEffect(() => {
    if (!key) return;

    socket.on('connect', () => { });
    socket.emit('joinToRoom', key.toString());
    socket.on('preorderServiceCreated', (data: PreorderService) => {
      setUsersLoading((prev: string[]) => (
        data.vendorUserUuid ? [...prev, data.vendorUserUuid] : prev
      ));
      setOffers((prev: PreorderService[]) => {
        const isExist = prev.some((item) => item.preorderServiceId === data.preorderServiceId);
        return isExist ? prev : [...prev, data];
      });
    });
  }, [preorder]);

  useEffect(() => {
    const getVendorInfo = async (id: string) => {
      const promises = [
        getVendor(id),
        getVendorRating(id),
        getVendorComments(id),
      ];
      const [
        vendorRes,
        vendorRatingRes,
        vendorCommentsRes,
      ] = await Promise.all(promises);

      const [vendor] = vendorRes;
      setVendors((prev) => {
        const isVendorExists = prev.some((item) => item?.id === vendor.id);
        return isVendorExists ? prev : [...prev, vendor];
      });

      setVendorsRating((prev) => {
        const isExist = prev.some((i) => i.id === id);
        return isExist ? prev : [...prev, { id, data: vendorRatingRes }];
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setVendorsComments((prev: any) => {
        const isExist = prev.some((i: { id: string }) => i.id === id);
        return isExist ? prev : [...prev, { id, data: vendorCommentsRes }];
      });
    };

    vendorsLoading.forEach(getVendorInfo);
  }, [vendorsLoading]);

  const visibleOffers = useMemo(() => (
    offers.filter(({ preorderServiceId }) => (
      preorderServiceId && !declinedOffers.includes(preorderServiceId)
    ))
  ), [offers, declinedOffers]);

  return {
    users,
    offers: visibleOffers,
    vendors,
    vendorsRating,
    vendorsComments,
    setDeclinedOffers,
    setUsersLoading,
    setOffers,
  };
};
