import axios, { AxiosResponse } from 'axios';
import { LatLngTuple } from 'leaflet';
import { configureOSRMLink, generateStringQuery, getEnv } from 'helpers';
import {
  UserSettings,
  UserSettingsResponse,
  IOrder,
  HowProjectWork,
  PromotionAndGift,
  AppInfoTextOnlyItem,
  IRating,
  Vendor,
  User,
  UUIDs,
  CommisionsReponse,
  BonusResponseItem,
  Preorder,
  CurrenciesReq,
  IDistance,
  VapidKeyResponse,
  SubscribeNotificationResponse,
  BannedUser,
  IMessage,
  IUser,
  IPoint,
  TCurrency,
  IMessageSend,
  OrderType,
  LastMessageRequestOptions,
  ILocation,
  OwnExchanceRates,
  UserVerification,
} from 'interfaces';
import { ORDER_STATUSES } from '../constants';
import RequestService from './requestApi';

const processRatingRequest = (rating: IRating[]) => {
  if (!rating.length) {
    return {
      ratingAvg: 0,
      drivingSkillRatingAvg: 0,
      politenessRatingAvg: 0,
      vehicleConditionRatingAvg: 0,
      drivingSkillRatingCount: 0,
      politenessRatingCount: 0,
      vehicleConditionRatingCount: 0,
      total: 0,
    };
  }
  return rating[0];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getMyVendors(): Promise<any> {
  return new RequestService('vendors/all-vendors?my=true').get();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deleteVendor(id: number): Promise<any> {
  return new RequestService(`/vendors/${id}`).delete();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function unsetActiveVendor(): Promise<any> {
  return new RequestService('users-settings/unset-active-vendor').put();
}

export function getAllPreorders(): Promise<AxiosResponse<Preorder[]>> {
  return new RequestService('/preorder').get();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getFilteredPreorders(data: any): Promise<Record<string, Preorder[]>> {
  return new RequestService('/preorder/search').post(data);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createPreorder(data: any): Promise<any> {
  return new RequestService('/preorder').post(data);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createOrder(data: any): Promise<any> {
  return new RequestService('/order').post(data);
}

export function getAllOrders(): Promise<TemplateStringsArray> {
  return new RequestService('/order').get();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getVendorRating(id: string): Promise<any> {
  return new RequestService(`/vendors-rating/${id}`).get().then(processRatingRequest);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getClientRating(id: number | string | undefined): Promise<any> {
  return new RequestService(`/users-rating/${id}`).get().then(processRatingRequest);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getEditorPages(): Promise<any> {
  return new RequestService('/pages').get();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getPreorderServices(id: number): Promise<any> {
  return new RequestService(`/preorder-service/preorderId/${id}`).get();
}

export function getClientOrder(id: number): Promise<IOrder[]> {
  return new RequestService('/order/get-my-orders').post({
    customerId: id,
    statuses: [ORDER_STATUSES.WAITING, ORDER_STATUSES.EXITING, ORDER_STATUSES.DEFAULT],
  }, true);
}

export function getVendorOrder(id: number): Promise<IOrder[]> {
  return new RequestService('/order/get-my-orders').post({
    serviceId: id,
    statuses: [ORDER_STATUSES.WAITING, ORDER_STATUSES.EXITING, ORDER_STATUSES.DEFAULT],
  }, true);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getVendorFinishedOrders(id: number): Promise<any> {
  return new RequestService('/order/get-my-orders')
    .post({
      serviceId: id,
      statuses: [
        ORDER_STATUSES.FINISHED,
        ORDER_STATUSES.CANCELED_BY_VENDOR,
        ORDER_STATUSES.CANCELED_BY_CLIENT,
      ],
    }, true);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getClientFinishedOrders(id: number): Promise<any> {
  return new RequestService('/order/get-my-orders')
    .post({
      customerId: id,
      statuses: [
        ORDER_STATUSES.FINISHED,
        ORDER_STATUSES.CANCELED_BY_VENDOR,
        ORDER_STATUSES.CANCELED_BY_CLIENT,
      ],
    }, true);
}

export const hideOrder = async (orderId: number): Promise<IOrder[]> => {
  const response = await new RequestService('/order/hide-on-history').put<IOrder>({ orderId });
  return response.data.docs;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deletePreorder(id: number): Promise<TemplateStringsArray| any> {
  return new RequestService(`/preorder/${id}`).delete();
}

export function getAllUserVendors(userId: number): Promise<Vendor[]> {
  return new RequestService(
    `/vendors?findColumn=userId&findColumnValue=${userId}`,
  ).get();
}

export async function getDistance(locate: number[], coords: number[]): Promise<IDistance[]> {
  const { REACT_APP_OSRM_HOST } = getEnv();
  // eslint-disable-next-line max-len
  const res = await axios.get(`${REACT_APP_OSRM_HOST}/route/v1/driving/${locate};${coords}?geometries=geojson&overview=full&continue_straight=false`);
  return res.data.routes;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createVendor(data: any): Promise<Vendor> {
  return new RequestService('/vendors').post(data);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateVendor(id: number, data: any): Promise<Vendor[]> {
  return new RequestService(`/vendors/${id}`).put<Vendor>(data)
    .then((res) => (res.data.docs));
}

export function getVendor(id: number | string): Promise<Vendor[]> {
  const query = typeof id === 'string' ? 'public=true' : '';
  return new RequestService(`/vendors/${id}?${query}`).get();
}

export function getVendorsVerification(id: number): Promise<[any]> {
  return new RequestService(`/vendors/verification/${id}`).get().then((res) => res.data);
}

export function updateVendorVerificationFields(id: number, data: []): Promise<any> {
  return new RequestService(`/vendors/verification-fields/${id}`).put(data).then((res) => res.data);
}

export function updateVendorVerification(id: number, data: any): Promise<any> {
  return new RequestService(`/vendors/verification/${id}`).put(data).then((res) => res.data);
}

export const checkVendorAvailability = async (fieldKey: 'vehicleNumber', fieldValue: string):
Promise<{ result: boolean }> => {
  const response = await new RequestService('/vendors/check-available').post({ fieldKey, fieldValue });
  return response;
};

export type TimestampTypes = 'email' | 'phone' | 'password';
export function getTimestamp(data: { id: number | undefined, type: TimestampTypes }): Promise<any> {
  return new RequestService('/users/get-timestamp').post(data).then((res) => res);
}

export function setTimestamp(data: { id: number | undefined, type: TimestampTypes }): Promise<any> {
  return new RequestService('/users/set-timestamp').post(data).then((res) => res);
}

export async function saveUserSettings(
  data: Partial<UserSettings>,
): Promise<UserSettingsResponse[]> {
  return new RequestService('/users-settings').put<UserSettingsResponse>(data).then((res) => res.data.docs);
}

export function getUserSettings(): Promise<[UserSettings]> {
  return new RequestService('/users-settings').get();
}

export function getUserVerification(): Promise<[UserVerification]> {
  return new RequestService('/users-verification').get();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getVendorComments(vendorUuid: string): Promise<any> {
  return new RequestService(`vendors-rating/get-all-comments/${vendorUuid}`).get();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getUserComments(userUuid: string): Promise<any> {
  return new RequestService(`users-rating/get-all-comments/${userUuid}`).get();
}

export function getUsers(userId: string): Promise<IUser[]> {
  return new RequestService(`/users/${userId}?public=true`).get();
}

export function getUuidByOrder(vendorId: number, orderId: number): Promise<UUIDs[]> {
  return new RequestService(`/order/get-uuid/?serviceId=${vendorId}&orderId=${orderId}`).get();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createChatRoom(id: string): Promise<any> {
  return new RequestService(`/chat/get-room-id/?toUser=${id}`).get();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getLastMessage(roomId: string, from?: number, to?: number): Promise<any> {
  return new RequestService(`/chat/last-messages/${roomId}/?fromDate=${from}&toDate=${to}`).get();
}

export function getMessages(
  roomId: string,
  options?: LastMessageRequestOptions,
): Promise<IMessage[]> {
  const query = options ? generateStringQuery(options) : '';
  return new RequestService(`/chat/last-messages/${roomId}/${query}`).get();
}

export function getMessagesFromSupportChat(
  options?: LastMessageRequestOptions,
): Promise<IMessage[]> {
  const query = options ? generateStringQuery(options) : '';
  return new RequestService(`/chat-support/last-messages/${query}`).get();
}

export function sendMessageToUser(data: IMessageSend): Promise<IMessage> {
  return new RequestService('/chat').post({
    ...data,
  });
}

export function sendMessageToSupport(data: Pick<IMessage, 'messageData' | 'messageFiles'>): Promise<IMessage> {
  return new RequestService('/chat-support').post(data);
}

export function getSupportBotIdentificator(): Promise<[string]> {
  return new RequestService('/chat-support/identificator').get();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getIp(): Promise<any> {
  return new RequestService('/users/userme').get();
}

export function getServices(query: string): Promise<AxiosResponse<Record<OrderType, number>>> {
  return new RequestService(`/preorder/?services=true${query}`).get();
}

export function getMyBalance(): Promise<Record<TCurrency, number>[]> {
  return new RequestService('/transactions/my-balance').get();
}

export function getMyBonuses(): Promise<BonusResponseItem[]> {
  return new RequestService('/user-bonuses/all-active-user-bonuses').get();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getMyTransactions(): Promise<any> {
  return new RequestService('/transactions/my-transactions').get();
}

export function getMyChats(): Promise<string[]> {
  return new RequestService('/chat/my-chats').get();
}

export function getOrder(id: number): Promise<IOrder[]> {
  return new RequestService(`/order/${id}`).get();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setReadMessages(data: { fromUser: string }): Promise<any> {
  return new RequestService('/chat/message-read/').put(data);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setReadSupportMessages(): Promise<any> {
  return new RequestService('/chat-support/message-read/').put();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sendSaveOurSouls(data: { orderId: number }): Promise<any> {
  return new RequestService('/save-our-souls').post(data);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createPreorderService(data: any): Promise<any> {
  return new RequestService('preorder-service').post(data);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sendFile(data: any, endpoint: string, id: number): Promise<any> {
  return new RequestService(`/upload/${endpoint}/${id}`).post(data, false, {
    'Content-Type': 'multipart/form-data',
  });
}

export function sendAbuse(data: {
  preorderId: number;
  abuseReason: string;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Promise<any> {
  return new RequestService('/preorder/abuse').put(data).then((res) => res.data.docs);
}

export function updateUser(updatedUser: User | {
  userPhoto: {
    removeThisFile: boolean;
  }
}): Promise<User[]> {
  return new RequestService('/users').put<User>(updatedUser).then((res) => res.data.docs);
}

export function deactiveteMe(id: number): Promise<unknown> {
  return new RequestService('/users/deactivate-me').put({ id }).then((res) => res.data.docs);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getReferrals(userId: number): Promise<any> {
  return new RequestService('users/get-user-referrals').post({ id: userId }, true);
}

export function getPromotionsAndGifts(): Promise<PromotionAndGift[]> {
  return new RequestService('/gifts').get();
}

export function getAppInfo(): Promise<(HowProjectWork | AppInfoTextOnlyItem)[]> {
  return new RequestService('/app-info').get();
}

export const getCommisions = (params?: { [key: string]: string }): Promise<CommisionsReponse[]> => (
  new RequestService(`/order-commission?${new URLSearchParams(params)}`).get()
);

export const getSupportUuid = async (): Promise<IUser[]> => (
  new RequestService('/chat/support-uuid').get()
);

export const getChatReplyUuid = async (): Promise<IUser[]> => (
  new RequestService('/chat/reply-uuid').get()
);

export const convertCurrencies = async (data: CurrenciesReq): Promise<number> => {
  const response = await new RequestService('/currencies/convert').post(data);
  return Math.floor(Number(response));
};

export const getVapidKey = async (): Promise<VapidKeyResponse> => {
  const response = await new RequestService('/notifications/vapid-pub-key').get();
  return response;
};

export const sendSubscribeNotification = async (data: PushSubscription)
: Promise<SubscribeNotificationResponse> => {
  const response = await new RequestService('/notifications/subscribe').post(data);
  return response;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteSubscribeNotification = async (endpoint: string): Promise<any> => {
  const response = await new RequestService('/notifications/unsubscribe').post({ endpoint });
  return response;
};

export const banUserInChat = async (data: {
  user: string,
  bannedUser: string,
}): Promise<BannedUser> => {
  const response = await new RequestService('/chat-banned').post(data);
  return response;
};

export const getBannedUsers = async (): Promise<BannedUser[]> => {
  const response = await new RequestService('/chat-banned/my-banned').get();
  return response;
};

export const isMeBannedByUser = async (
  meUuid: string,
  secondUserUuid: string,
): Promise<[boolean]> => {
  const query = generateStringQuery({
    user: meUuid,
    bannedUser: secondUserUuid,
  });
  const response = await new RequestService(`/chat-banned/is-banned${query}`).get();

  return response;
};

export const deleteChat = async (fromUser: string): Promise<boolean> => {
  const response = await new RequestService('/chat/delete-chat').post({ fromUser });
  return response;
};

export const deleteSupportChat = async (): Promise<boolean> => {
  const response = await new RequestService('/chat-support/delete-chat').delete();
  return response;
};

export const getVendorPosition = async (id: number): Promise<ILocation[]> => (
  new RequestService(`users-positions/${id}`).get()
);

export const getDataByPoints = async (points: IPoint[]): Promise<{
  distance: number,
  route: LatLngTuple[],
}> => {
  const query = points.map((point) => `${point.lon},${point.lat}`).join(';');
  const { data } = await axios.get(configureOSRMLink(query));
  const [routes] = data.routes;
  const { geometry: { coordinates } } = routes;

  const { distance } = routes;
  return {
    route: coordinates,
    distance,
  };
};

export const getCurrencyDataByCountry = async (key: TCurrency): Promise<OwnExchanceRates[]> => {
  const query = generateStringQuery({ findColumn: 'currency', findColumnValue: key });

  const response = await new RequestService(`/own-exchange-rate${query}`).get();
  return response;
};

export const getUserByReferralId = async (referralId: string): Promise<IUser[]> => {
  const response = await new RequestService(`/users/referralId/${referralId}`).get();
  return response;
};

export const recoverPassword = async (data: {
  login: string;
  password: string;
  code: number;
  link: string;
}): Promise<IUser> => {
  const response = await new RequestService('/users/recover-password').post(data);
  return response;
};

type FieldKey = 'email' | 'referralId';
export const checkAvailability = async (fieldKey: FieldKey, fieldValue: string):
Promise<{ result: boolean }> => {
  const response = await new RequestService('/users/check-available').post({ fieldKey, fieldValue });
  return response;
};

export const updateUserPassword = async ({ id, password }: { id?: number, password: string }):
Promise<{ result: boolean }> => {
  const response = await new RequestService('/users/update-password').post({ id, password });
  return response;
};

export const requestWebsitePage = async (data: {
  url: string;
  lang: string;
}): Promise<string> => {
  const response = await new RequestService('/website').post(data);
  return response;
};
