import { useCallback } from 'react';
import { toast } from 'react-toastify';
import ModalMessage from 'sharedComponents/ModalMessage';
import { useAppInfoTextOnly } from 'hooks/appInfo';
import { useClient } from 'hooks/client';
import { HashedFile, IUser, User } from 'interfaces';
import { getTranslateFunction } from 'helpers';
import { useAuth } from 'services/auth';
import { sendFile, updateUser } from 'services/commonService';
import RequestService from 'services/requestApi';
import { useModal } from 'services/customHooks';
import UserInfo from './UserInfo';

const POSITIONS = ['menuProfileTop', 'menuProfileBottom'];

const ProfilePage = () => {
  const { user, setUser, setIsLoading } = useAuth();
  const appInfo = useAppInfoTextOnly(POSITIONS);
  const { clientRating } = useClient(user?.uuid);
  const { isModalVisible, showModal, hideModal } = useModal();
  const t = getTranslateFunction();

  const handleUpdateUser = useCallback(
    async (newDataUser: User) => {
      if (!newDataUser) return;

      const isDiff = user && Object.entries(newDataUser).some(([key, value]) => user[key as keyof IUser] !== value);
      if (!isDiff) return;

      setIsLoading(true);
      try {
        const newUserInfo = { ...user, ...newDataUser };
        const [updatedUser] = await updateUser(newUserInfo);
        setUser(updatedUser);
      } finally {
        setIsLoading(false);
      }
    },
    [user],
  );

  const handleUpdateUserFile = async (
    newData: {
      hashedPhoto: HashedFile;
      file: File;
    },
    property: keyof IUser,
  ) => {
    const { hashedPhoto, file } = newData;
    setIsLoading(true);

    try {
      const [updateItem] = await new RequestService('/users')
        .put<NonNullable<User>>({
        ...user,
        [property]: hashedPhoto,
      })
        .then((res) => res.data.docs);

      const formData = new FormData();
      formData.append(hashedPhoto.fileProperties.md5Hash, file);

      await sendFile(formData, 'users', Number(updateItem[property]));
      setUser(updateItem);
      showModal();
    } catch (e) {
      const error = e as Error;
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const removeAvatar = useCallback(async () => {
    setIsLoading(true);
    try {
      const [updatedUser] = await updateUser({
        ...user,
        userPhoto: { removeThisFile: true },
      });
      toast.success(t('profilePage.updatedSuccessfully'));

      setUser(updatedUser);
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  const handleChange = ({ action, data }: { action?: string; data?: any }) => {
    switch (action) {
      case 'updateUser': {
        handleUpdateUser(data);
        toast.success(t('profilePage.updatedSuccessfully'));
        break;
      }
      case 'setIsLoading': {
        setIsLoading(data);
        break;
      }
      case 'removeAvatar': {
        removeAvatar();
        break;
      }
      case 'updateUserPhoto': {
        handleUpdateUserFile(data, 'userPhotoUpdate');
        break;
      }
      case 'updatePassportFirstPagePhoto': {
        handleUpdateUserFile(data, 'passportFirstPagePhoto');
        break;
      }
      case 'updatePassportAndFacePhoto': {
        handleUpdateUserFile(data, 'passportAndFacePhoto');
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <UserInfo
        menuProfileTop={appInfo.menuProfileTop}
        menuProfileBottom={appInfo.menuProfileBottom}
        userRating={clientRating}
        onChange={handleChange}
        user={user}
      />
      <ModalMessage isVisible={isModalVisible} onClose={hideModal} message={t('profilePage.updatedSuccessfully')} />
    </>
  );
};

export default ProfilePage;
