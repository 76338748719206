import React from 'react';
import { useForm } from 'react-hook-form';
import { Container, Typography, Button } from '@material-ui/core';
import { IOnChange, Vendor } from 'interfaces';
import { ModelItem } from 'components/ConstructorForm/items/CarCombobox/useBrands';
import { formatVendorServicesToFormValues, getTranslateFunction } from 'helpers';
import { useAppInfoTextOnly } from 'hooks/appInfo';
import useStyles from '../CreateVendorPage/CreateVendorStyle';
import type { VendorVerificationFields } from './containers/UpdateVendorPageContainer';
import UpdateVendorForm from './UpdateVendorForm';

interface Props {
  onChange: (data: IOnChange) => void;
  vendor: Vendor;
  brands: any;
  models: ModelItem[];
  verification: VendorVerificationFields;
}

const POSITIONS = ['uploadVendorFiles'];

const UpdateVendorPage: React.FC<Props> = ({ vendor, onChange, brands, models, verification }) => {
  const t = getTranslateFunction();
  const classes = useStyles();

  const formattedVendor = formatVendorServicesToFormValues(vendor);
  const filesDescription = useAppInfoTextOnly(POSITIONS);

  const defaultValues = {
    ...formattedVendor,
    vehicleColor: { id: vendor.vehicleColor, label: t(`carCombobox.color.${vendor.vehicleColor}`) },
  };

  const {
    control,
    errors,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { dirtyFields },
  } = useForm({
    defaultValues,
  });
  const submitter = (data: Vendor) => {
    const verificationFields = Object.keys(dirtyFields).reduce((acc, key: string) => {
      return { ...acc, [key]: null };
    }, {});

    onChange({ action: 'updateVendorValidation', data: [verificationFields] });
    onChange({ action: 'updateVendorSubmit', data });
  };

  return (
    <Container className={classes.mainContainer}>
      <Typography variant="h4">{t('updateVendorPage.pageName')}</Typography>
      <form onSubmit={handleSubmit(submitter)}>
        <UpdateVendorForm
          setValue={setValue}
          control={control}
          errors={errors}
          brands={brands}
          models={models}
          onChangeProp={onChange}
          clearErrors={clearErrors}
          verification={verification}
          filesDescription={filesDescription.uploadVendorFiles}
        />
        <Button type="submit" className={classes.submitBtn} variant="contained" color="primary">
          {t('updateVendorPage.button.updateLabel')}
        </Button>
      </form>
    </Container>
  );
};

export default UpdateVendorPage;
