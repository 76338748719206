import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const modalRoot = document.querySelector('#modal-root') as HTMLElement;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PortalModal: React.FC<any> = ({ children }) => {
  const el = useRef(document.createElement('div'));

  useEffect(() => {
    const { current } = el;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    modalRoot!.appendChild(current);

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      modalRoot!.removeChild(current);
    };
  }, []);

  return createPortal(children, el.current);
};
