import {
  Box,
  Button,
  Input,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Lock from 'images/lock';
import Message from 'images/message';
import classNames from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { useCountdown } from 'services/customHooks';
import { formatMessage, formatMsToSeconds } from 'utils/formatters';
import { getTranslateFunction } from 'helpers';
import useStyles from './styles';
import {
  CONFIRM_CODE_LIFETIME_MS,
  CONFIRM_CODE_MAX_TRY_COUNT,
  cyan,
  onlyNumbersRegExp,
} from '../../constants';

interface Fields {
  code: string;
}

interface Props {
  onSendCode: (code: string) => Promise<any>;
  createdCodeTime: number;
  resendRequest: () => void;
  attempts: number;
  isAttemptsForCodeLeft: boolean;
  errorMessage?: string;
}

const ConfirmCode: React.FC<Props> = ({
  attempts,
  createdCodeTime,
  isAttemptsForCodeLeft,
  onSendCode,
  resendRequest,
  children,
  errorMessage,
}) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  const [timeEnd, setTimeEnd] = useState(false);
  const { count, setCount } = useCountdown();

  const {
    handleSubmit,
    control,
    errors,
    reset,
    setError,
  } = useForm({ defaultValues: { code: '' } });

  useEffect(() => {
    if (count <= 0) {
      setTimeEnd(true);
    }
  }, [count]);

  useEffect(() => {
    setTimeEnd(false);
  }, [createdCodeTime]);

  useEffect(() => {
    const difference = (createdCodeTime + CONFIRM_CODE_LIFETIME_MS) - new Date().getTime();
    const seconds = Math.floor(difference / 1000);

    setCount(attempts < CONFIRM_CODE_MAX_TRY_COUNT ? seconds : 0);
  }, [attempts, createdCodeTime]);

  const onSubmit = async (data: Fields) => {
    const { code } = data;
    try {
      await onSendCode(code);
      reset();
    } catch (error) {
      const e = error as Error;
      const message = `confirmCode.${e.message}`;
      setError('code', { message });
    }
  };
  const shouldShowRequestButton = timeEnd && attempts < CONFIRM_CODE_MAX_TRY_COUNT;

  return (
    <>
      <Box className={classes.formControl}>
        <Box className={classes.formRowWrapper}>
          <Box className={classes.svg}>
            <Message width={30} height={30} color={cyan} />
          </Box>

          <Typography className={classes.label}>
            {t('confirmCode.codeSended')}
          </Typography>
        </Box>
      </Box>

      {children && (
        <Box className={classes.formControl}>
          {children}
        </Box>
      )}

      <Box className={classes.formControl}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className={classes.formRowWrapper}>
            <Box className={classes.svg}>
              <Lock width={30} height={30} color={cyan} />
            </Box>

            <Controller
              control={control}
              name="code"
              rules={{
                pattern: {
                  value: onlyNumbersRegExp,
                  message: 'confirmCode.invalidCode',
                },
                required: {
                  value: true,
                  message: 'confirmCode.fillInput',
                },
              }}
              render={(({ value, onChange: onInputChange }) => (
                <Input
                  placeholder={t('confirmCode.code')}
                  value={value}
                  onChange={onInputChange}
                  className={classNames(
                    classes.input,
                    { [classes.inputError]: Boolean(errors.code) },
                  )}
                />
              ))}
            />

            <Button
              variant="contained"
              disabled={isAttemptsForCodeLeft}
              className={classes.submit}
              type="submit"
            >
              {t('confirmCode.submit')}
            </Button>
          </Box>

          {errors.code?.message && (
            <FormHelperText className={classes.codeErrorMessage}>
              {t(errors.code.message)}
            </FormHelperText>
          )}
        </form>
      </Box>

      <Box className={classes.formControl}>
        <Box className={classes.formRowWrapper}>
          {!errorMessage && (
            <>
              <Box className={classes.svg}>
                <Typography variant="caption" className={classNames(classes.textIcon, classes.attempt)}>
                  {formatMessage('${attemptAll}/${attempts}', { attempts, attemptAll: CONFIRM_CODE_MAX_TRY_COUNT })}
                </Typography>
              </Box>

              {count > 0 && <Typography>{formatMsToSeconds(count)}</Typography>}

              {shouldShowRequestButton && (
                <Button onClick={resendRequest} className={classes.resendCodeButton}>
                  {t('confirmCode.resendCode')}
                </Button>
              )}
            </>
          )}

          {(errorMessage || (!shouldShowRequestButton && attempts > CONFIRM_CODE_MAX_TRY_COUNT)) && (
            <FormHelperText error>{t('confirmCode.attemptsRecoveryCodeLeft')}</FormHelperText>
          )}
        </Box>
      </Box>

    </>
  );
};

export default ConfirmCode;
